import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';
import PoliciesAndFormsContent from '../Policies-and-forms-components/Policies-and-forms-content';
import PoliciesAndFormsBanner from '../Policies-and-forms-components/Policies-and-forms-banner';

class PoliciesAndFormsPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="policies-and-forms-wrapper">
                <SideButtons />
                <PoliciesAndFormsBanner />
                <PoliciesAndFormsContent />
                <Footer />
            </div>
        );
    }
}

export default PoliciesAndFormsPage;