import React, { Component } from "react";
// import CertIvCourseEnquiry from "../Cert-iv-componenet/Cert-iv-course-enquiry";
// import SimpleSlider from "../slider";
import Footer from "../Footer";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";
import CertthreeOverview from "../Certificate-three-components/Certificate-three-overview";
import CertthreeDetail from "../Certificate-three-components/Certificate-three-details";
import CertthreeHelp from "../Certificate-three-components/Certificate-three-help";
import CertthreeBanner from "../Certificate-three-components/Certificate-three-banner";

class CertthreePage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="cert-three-page-wrapper">
                <SideButtons />
                <CertthreeBanner />
                <CertthreeOverview />
                <CertthreeDetail />
                {/* <CertIvCourseEnquiry /> */}
                {/* <SimpleSlider /> */}
                <CertthreeHelp />
                <Footer />
            </div>
        );
    }
}

export default CertthreePage;
