import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import abimg from "../../assets/images/icons/about-img.png";
// import backimg from "../../assets/images/icons/gray-back_03.jpg";
import SimpleSlider from "../slider";
import { NavLink } from "react-router-dom";
// import AOS from "aos";
import "aos/dist/aos.css";

class AboutUs extends Component {
    // componentDidMount(){
    //     AOS.init({
    //         duration : 800,
    //         once: true,
    //         // disable: function() {
    //         //     var maxWidth = 800;
    //         //     return window.innerWidth < maxWidth;
    //         // }
    //     })
    // }


    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    render() {
        const { postList } = this.props;
        return (
            <div className="about-wrapper">
                <div
                    className="image-section"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                >
                    <Row data-aos="fade-left" data-aos-duration="1500">
                        <Col md="12" lg="6" className="section1">
                            <div
                                className="story-section"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <div className="heading">About Us</div>
                                <div className="Para">
                                Wattle College is a registered training provider. We offer Vocational and ELICOS programs. We strive to provide the best possible learning experience to our students. We manage to do so with our state-of-the-art facilities, highly educated staff, industry engagement and tailored curriculum to ensure that you get a qualification that is highly regarded by industry.
                                </div>
                                <div className="bu">
                                    <NavLink
                                        to="/college-profile"
                                        onClick={() => {
                                            this.scrollToTop();
                                        }}
                                    >
                                        <button>Learn More</button>
                                    </NavLink>
                                </div>
                            </div>
                        </Col>
                        <Col md="12" lg="6" className="section2">
                            <img
                                className="frontimg"
                                src={abimg}
                                alt="aboutimg"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            />
                        </Col>
                    </Row>
                </div>
                {/* <div className="heading-one">Hear From our Students</div> */}
                {/* <SimpleSlider postList={postList} /> */}
                <div className="back-color"></div>
            </div>
        );
    }
}

export default AboutUs;
