import React, { Component } from "react";
// import CertIvCourseEnquiry from "../Cert-iv-componenet/Cert-iv-course-enquiry";
// import SimpleSlider from "../slider";
import Footer from "../Footer";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";
import AdvancedDiplomaBanner from "../Advanced-Diploma-components/Advanced-Diploma-banner";
import AdvancedDiplomaOverview from "../Advanced-Diploma-components/Advanced-Diploma-overview";
import AdvancedDiplomaDetail from "../Advanced-Diploma-components/Advanced-Diploma-details";
import AdvancedDiplomaHelp from "../Advanced-Diploma-components/Advanced-Diploma-help";

class AdvancedDiploma extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    // Advanced-Diploma-
    render() {
        return (
            <div className="Advanced-Diploma-page-wrapper">
                <SideButtons />
                <AdvancedDiplomaBanner />
                <AdvancedDiplomaOverview />
                <AdvancedDiplomaDetail />
                {/* <CertIvCourseEnquiry /> */}
                <AdvancedDiplomaHelp />
                <Footer />
            </div>
        );
    }
}

export default AdvancedDiploma;
