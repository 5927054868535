import React, { Component } from "react";
// import { Row, Col } from "react-bootstrap";
// import logo from "../../../assets/images/SVG/Logo-header.svg";
import handbooks from '../../../assets/Policies/Student-Handbook-VET.pdf';
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import pdf22 from "../../../assets/Policies/Access-and-Equity-Policy.pdf";

class StudentSupportContent extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="student-support-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        {/* <div
                            className="help-heading"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Recognition of Prior Learning (RPL) is the assessment of skills or
                            knowledge required for advanced standing where there are no
                            current or formal qualifications as proof.
                        </div> */}
                        <div
                            className="para-full"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College is committed to providing continuous support to its students so that they can adjust to study and life in Australia, to achieve their learning goals and to achieve satisfactory academic progress towards meeting their learning outcomes.
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Student Handbook
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            The student handbook provides all the relevant information about our courses, support facilities available at the Wattle College including student support services. The handbook can be downloaded <a href={handbooks} target="_blank">here</a>. Printed copies of the handbook are also available on campus. Please contact student services team.
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Student Assistance
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College understands that there are many issues that may affect students’ social or personal lives including personal issues, accommodation issues, or family / friend issues.Students have access to Support officers and counsellors within standard College hours.  In case a student requires support outside the student support officer’s capacity or if the counsellor is not available for student counselling, a referral to an appropriate service will be organised at no charge.

                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Orientation Program
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Wattle College conducts student orientation program before the commencement of the course where it provides information on:
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <span id="dots">•	</span>support services available to assist international students to help them adjust to study and life in Australia
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                        >
                            <span id="dots">•	</span>English language and study assistance programs
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="200"
                        >
                            <span id="dots">•	</span>any relevant legal, emergency and health services
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="250"
                        >
                            <span id="dots">•	</span>facilities and resources
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="250"
                        >
                            <span id="dots">•	</span>requirements for course attendance and progress, as appropriate
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="250"
                        >
                            <span id="dots">•	</span>the support services available to assist students with general or personal circumstances that are adversely affecting their education in Australia
                        </div>
                        <div
                            className="para para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="300"
                        >
                            <span id="dots">•	</span>services students can access for information on their employment rights and conditions, and how to resolve workplace issues, such as through the Fair Work Ombudsman.
                        </div>


                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Access and Equity
                        </div>
                        <div
                            className="para-first para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College is committed to providing and promoting non-discriminatory, inclusive practices and processes to provide equal opportunities for all students to achieve their learning outcomes and strives to ensure that its decision-making processes reflect a commitment to access and equity and treats all students or prospective students fairly. Access and Equity means ensuring that people with differing needs and abilities have the same opportunities to successfully gain skills, knowledge and experience through education and training irrespective of their age, disability, colour, race, gender, religion, sexuality, family responsibilities, or location. The complete policy can be viewed <a href={pdf22} target="_blank">here</a>.
                        </div>

                        {/* <div className="logo-section">
                            <Row>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-first" />
                                    </div>
                                </Col>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-second" />
                                    </div>
                                </Col>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-third" />
                                    </div>
                                </Col>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-fourth" />
                                    </div>
                                </Col>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-fifth" />
                                    </div>
                                </Col>
                                <Col className="cols" md="2" xs="6">
                                    <div
                                        className="main-div-logo"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                    >
                                        <img src={logo} alt="logo-sixth" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                     */}
                    </div>
                </div>
            </div>
        );
    }
}

export default StudentSupportContent;
