import React, { Component } from "react";
import CertIvBanner from "../Cert-iv-componenet/Cert-iv-banner/index";
// import CertIvCourseEnquiry from "../Cert-iv-componenet/Cert-iv-course-enquiry";
import CertIvDetail from "../Cert-iv-componenet/Cert-iv-details";
import CertIvHelp from "../Cert-iv-componenet/Cert-iv-help";
import CertIvOverview from "../Cert-iv-componenet/Cert-iv-overview";
// import SimpleSlider from "../slider";
import Footer from "../Footer";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";

class CertIvPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="cert-iv-page-wrapper">
                <SideButtons />
                <CertIvBanner />
                <CertIvOverview />
                <CertIvDetail />
                {/* <CertIvCourseEnquiry /> */}
                {/* <SimpleSlider /> */}
                <CertIvHelp />
                <Footer />
            </div>
        );
    }
}

export default CertIvPage;
