import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';
import GovernanceProfileBanner from '../Governance-Profile-components/Governance-Profile-banner';
import GovernanceProfileContent from '../Governance-Profile-components/Governance-Profile-content';

class GovernanceProfilePage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="Governance-profile-wrapper">
                <SideButtons />
                <GovernanceProfileBanner />
                <GovernanceProfileContent />
                <Footer />
            </div>
        );
    }
}

export default GovernanceProfilePage;