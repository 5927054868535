import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/SVG/Logo-header.svg";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class PrivacyPolicyContent extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="privacy-policy-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        {/* <div
                            className="help-heading"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Recognition of Prior Learning (RPL) is the assessment of skills or
                            knowledge required for advanced standing where there are no
                            current or formal qualifications as proof.
                        </div> */}
                        {/* <div
                            className="para-full"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College is committed to providing continuous support to its students so that they can adjust to study and life in Australia, to achieve their learning goals and to achieve satisfactory academic progress towards meeting their learning outcomes. 
                        </div> */}
                       
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Purpose
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College is committed to providing quality training and assessment in accordance with the Standards for Registered Training Organisations (SRTOs 2015).  As such, is required to comply with Federal law regarding Privacy and confidentiality of employees, clients, and contractors.
                        </div>

                        <div
                            className="para-second"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            The purpose of this policy is to outline how Wattle College complies with Privacy Act 1988 and Australian Privacy Principles (APPs).
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Responsibilities 
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            CEO ensures that all employees are made aware of this policy and its underpinning legislative requirements and comply with this policy at all times. 

                        </div>

                        <div
                            className="para-second"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            CEO ensures that staff and students have access to and awareness of this policy. 
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Legislation 
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College, although not required to, chooses to abide by the Privacy Act 1988 and associated 13 x Australian Privacy Principles (APPs). 
                        </div>

                        <div
                            className="para-second"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            The APPs came into force on 12 March 2014 and set out standards, rights, and obligations for the handling, holding, accessing and correction of personal information (including sensitive information). 
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Policy
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Wattle College will ensure:
                        </div>
                        <ul>
                            <li>It maintains and provides a current Privacy Policy;</li>
                            <li>Information gathered for the express purpose of training and assessment matters will not be disclosed to a third party unless prior written consent is provided by the individual concerned, except that required by law;</li>
                            <li>The secure storage of all records;</li>
                        </ul>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                        >
                            The confidentiality of all information is maintained on records.
                        </div>

                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                        >
                            Personal Information is defined in the Privacy Act 1988 to mean “information or an opinion about an identified individual, or an individual who is reasonably identifiable:
                        </div>
                        
                        <ul>
                            <li>whether the information or opinion is true or not; and</li>
                            <li>whether the information or opinion is recorded in a material form or not.</li>
                        </ul>

                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                        >
                            Sensitive Personal Information is defined in the Privacy Act 1988 to mean “information or an opinion about an individual’s” that is also personal information, such as:
                        </div>

                        <ul>
                            <li>racial or ethnic origin; or</li>
                            <li>political opinions; or</li>
                            <li>membership of a political association; or</li>
                            <li>religious beliefs or affiliations; or</li>
                            <li>philosophical beliefs; or</li>
                            <li>membership of a professional or trade association; or</li>
                            <li>membership of a trade union; or</li>
                            <li>sexual orientation or practices; or</li>
                            <li>criminal record.</li>
                        </ul>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            PART 1 – Consideration of Personal Information Privacy 
                        </div>
                        <div
                            className="para-first para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <ul className="number-list-ul">
                                <li>Open and Transparent Management of Personal Information.Wattle College will ensure that personal information is managed in an open and transparent way.</li>
                                <li>Take reasonable steps to implement practices and procedures that will facilitate dealing with enquiries or complaints from individuals regarding compliance with the Australian Privacy Principles (APPs).</li>
                                <li>Ensure that it maintains an up-to-date policy about the management of personal information.</li>
                                <li>Ensure that Privacy Policy contains the following information:
                                    <ul className="dot-list">
                                        <li>The kind of information that is collected and held;</li>
                                        <li>How the information is collected and held;</li>
                                        <li>The purposes for which information is collected, held, used and disclosed;</li>
                                        <li>How an individual may access their personal information that is held by and seek correction of such information as necessary;</li>
                                        <li>How the individual may make a complaint about a breach of the APPs and how will deal with such a complaint;</li>
                                        <li>Whether it is likely to disclose personal information to overseas recipients, and if so the countries in which such are likely to be located.</li>
                                    </ul>
                                </li>
                                <li>Provide the Privacy Policy free of charge and in such form as appropriate, and as is reasonable.</li>
                            </ul> 
                            <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                            >
                                Anonymity and pseudonymity 
                            </div>
                            <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                            >
                                Wattle College will respect that individuals may not wish to identify themselves when making enquiries on  products and services; 
                            </div>
                            <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="150"
                            >
                                Wattle College will respect that individuals may not wish to identify themselves when making enquiries on  products and services;   
                            </div>
                        </div>
                    
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            PART 2 – Collection of Personal Information   
                        </div>
                        <div
                            className="para-first para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <ul className="number-list-ul">
                                <li>Wattle College will not collect personal information form you unless that information is necessary for one or more of its functions or activities or is required by law.</li>
                                <li>Wattle College advises that it is required by law to collect, hold, use and supply personal information, in accordance with the National VET Provider Collection Data Provision Requirements.</li>
                                <li>Wattle College will take reasonable steps at or before the time of collection to ensure that you are aware of:
                                    <ul className="dot-list">
                                        <li>Who we are and how to contact us;</li>
                                        <li>How to gain access to your own information;</li>
                                        <li>The purpose for which the information is being collected;</li>
                                        <li>Any organisation to which we would normally disclose information of that kind;</li>
                                        <li>Any law that requires the particular information to be collected;</li>
                                        <li>The main consequences for the individual if all or part of the information is not provided.</li>
                                    </ul>
                                </li>
                                <li>Wattle College collects information from you in the following ways:
                                    <ul className="dot-list">
                                        <li>When you register your interest online, apply for enrolment, request certain services or products, or otherwise contact or do business with us.</li>
                                        <li>Information may be collected from enrolment forms, certified documents, telephone calls, faxes, emails, letters sent by you.</li>
                                        <li>Information may be collected from third parties, such as other training providers, regarding confirmation of training and ongoing professional development that you have attended, as permitted by you.</li>
                                    </ul>
                                </li>
                                <li>Should collect information about you from a third party we will take reasonable steps to ensure that the individual is or has been made aware of the matters listed above except to the extent that making the individual aware of the matters would pose a serious threat to the life or health of any individual.</li>
                            </ul> 
                        </div>
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            PART 3 – Dealing with Personal Information  
                        </div>
                        <div
                            className="para-first para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <ul className="number-list-ul">
                                <li>Wattle College will not use or disclose personal or sensitive information for any purpose other than what it was collected for, unless the relevant person has provided written consent to use or disclose that information in circumstances that are different to those for which it was collected.
                                    <div className="below-heading para-first para-last">
                                        The circumstances where an exception may occur are:
                                    </div>
                                    <ul className="dot-list">
                                        <li>Where the use or disclosure of this information is required or authorised by or under an Australian law or a court/tribunal order;</li>
                                        <li>The individual would reasonable expect to use or disclose the information for the secondary purpose;</li>
                                        <li>A permitted health situation exists in relation to the use or disclosure of the information by Wattle College;</li>
                                        <li>A permitted general situation exists in relation to the use or disclosure of the information by Wattle College;</li>
                                        <li>Wattle College reasonably believes that the use or disclosure of the information is reasonably necessary for one or more enforcement related activities conducted by, or on behalf of, an enforcement body.</li>
                                    </ul>
                                </li>
                                <li>Wattle College collects your personal information so as to:
                                    <ul className="dot-list">
                                        <li>Process applications;</li>
                                        <li>Manage your enrolment;</li>
                                        <li>Record and maintain your details;</li>
                                        <li>Administering training programs;</li>
                                        <li>Record and maintain details of your ongoing training and assessment;</li>
                                        <li>Provide you with details regarding client services, benefits, and training opportunities;</li>
                                        <li>Notify you about upcoming events and opportunities;</li>
                                        <li>Gain feedback from you;</li>
                                        <li>Communicate with you;</li>
                                        <li>Report to relevant authorities as required by law.</li>
                                    </ul>
                                </li>
                                <li className="last-list">Should collect information about you from a third party we will take reasonable steps to ensure that the individual is or has been made aware of the matters listed above except to the extent that making the individual aware of the matters would pose a serious threat to the life or health of any individual.</li>
                            </ul> 
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Direct Marketing  
                        </div>
                        <div className="para-first para-second" data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100">

                            <ul className="number-list-ul">
                                <li>Wattle College may use personal information (specifically your name and relevant address details) and information about your preferences for direct marketing (ie the communication channels which you prefer for receiving direct marketing from us and the types of products and services in which you are interested in) so as to let you know about our services and benefits, where we have your consent.</li>
                                <li>Provides an opt-out and/or unsubscribe method that is easily accessible for individuals to request not to receive direct marketing communications.</li>
                            </ul>
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Cross Border Disclosure  
                        </div>
                        <div className="para-first para-second" data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100">
                            Wattle College does not disclose personal information to overseas recipients unless prior written approval is received by the individual who the personal information relates.
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Adoption, use or disclosure of Government Related identifiers
                        </div>
                        <div className="para-first para-second" data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100">
                            <div className="para">
                                Wattle College Is required by law (Student Identifier Act) to collect, maintain and report to relevant Government agencies the individual’s Unique Student Identifier (USI) number in accordance with the National VET Provider Collection Data Provision Requirements.
                            </div>
                            <div className="para">
                                Wattle College will not disclose the Unique Student Identifier (USI) number for any other purpose, including on any Certification documents you receive.
                            </div>
                            <div className="para">
                                Wattle College must not adopt the Unique Student Identifier (USI) number as its own identifier of the individual.
                            </div>
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            PART 4 – Integrity of Personal Information    
                        </div>

                        <div className="para-first para-second" data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="100">
                            <div
                            className="heading-inner"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            >
                                Quality of personal information   
                            </div>
                            <div className="para">
                                Wattle College will take steps, as are reasonable, to ensure that the personal information it:
                            </div>
                            <ul className="dot-list">
                                <li>Collects is accurate, up to date and complete;</li>
                                <li>Uses or discloses, is, having regard to the purpose of the use or disclosure, accurate, up to date, complete and relevant.</li>
                            </ul>
                            <div
                            className="heading-inner"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            >
                                Security of personal information 
                            </div>
                            <ul className="dot-list">
                                <li>Wattle College will take steps, as are reasonable in the circumstances to:</li>
                                <li>Protect the information from misuse, interference and loss as well as unauthorised access, modification or disclosure.</li>
                                <li>Destroy the information or to ensure that the information is de-identified.</li>
                            </ul>
                        </div>
                    
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            PART 5 – Access to, and correction of, Personal Information   
                        </div>
                        <div
                            className="para-first para-last"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <div className="para">
                                Wattle College provides all clients with electronic access to their own personal records, where the individual can update and maintain their own personal information. 
                            </div>
                            <div className="para">
                                In some circumstances, may not permit access to individuals for their personal information.  If this is ever the case, will provide full details for the legal reasons for this decision. These may include that believes :
                            </div>
                                
                            <ul className="dot-list">
                                <li>That giving access to the information would pose a serious threat to the life, health or safety of the individual, or to public health or public safety; or</li>
                                <li>Giving access would have an unreasonable impact on the privacy of other individuals; or</li>
                                <li>The request for access is frivolous or vexatious; or</li>
                                <li>The information relates to existing or anticipated legal proceedings between and the individual, and would not be accessible by the process of discovery in those proceedings; or</li>
                                <li>Giving access would reveal the intentions of in relation to negotiations with the individual in such a way as to prejudice those negotiations; or</li>
                                <li>Giving access would be unlawful; or</li>
                                <li>Denying access is required or authorised by or under an Australian law or a court/tribunal order; or</li>
                            </ul>

                            <div className="para">
                                Both of the following apply:
                            </div>
                            <ul className="dot-list">
                                <li>Wattle College has reason to suspect that unlawful activity, or misconduct of a serious nature, that relates to functions or activities has been, is being or may be engaged in;</li>
                                <li>Giving access would be likely to prejudice the taking of appropriate action in relation to the maters; or</li>
                                <li>Giving access would be likely to prejudice one or more enforcement related activities conducted by, or on behalf of, an enforcement body; or</li>
                                <li>Giving access would reveal evaluative information generated within in connection with a commercially sensitive decision-making process.</li>
                            </ul>

                            <div className="para">
                                When dealing with requests for access to personal information, will:
                            </div>
                            <ul className="dot-list">
                                <li>Respond to request for access within 30 days of the request, if from an individual, and within a reasonable time, if the request is from an organisation: and</li>
                                <li>Provide access to the information in the manner requested, if it is reasonable and practicable to do so.</li>
                                <li>does not charge a fee for access to personal information. The exception is re-prints of certification documentation previously supplied. </li>
                            </ul>

                            <div className="para">
                                With regard to the correction of personal information held:
                            </div>
                            <ul className="dot-list">
                                <li>should be satisfied that information is inaccurate, out of date, incomplete, irrelevant or misleading, will take such steps as reasonable to correct the information to ensure that, having regard to the purpose for which it is held, the information is accurate, up-to-date, complete, relevant and not misleading.</li>
                            </ul>

                            <div className="para">
                                Should refuse to correct information, will give written notice to the individual that sets out:
                            </div>
                            <ul className="dot-list">
                               <li>The reason for refusal</li>
                               <li>The mechanisms available to complain about the refusal; and</li>
                               <li>Any other matter prescribed by the regulations.</li>
                            </ul>

                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Records Management 
                        </div>
                        <div className="para-first para-last">
                            All personal information and records are maintained in accordance with Records Management Policy.  (See Records Management Policy) 
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Monitoring and Improvement
                        </div>
                        <div className="para-first para-last">
                            All practices for Privacy are monitored by the CEO and areas for improvement identified and acted upon. (See Continuous Improvement Policy)
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicyContent;
