import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import excelFile from "../../../assets/Policies/EducationAgents.xlsx";

class EducationAgentsContent extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="education-agents-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div
                            className="heading para-less"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Education Agents
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College has an extensive network of registered education agents. These agents will support you and provide you with the relevant information about the college and our courses (including visa information and completion of application form). The list of education agents can be viewed <a href="/AgentsList.xlsx">here</a>.
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            If you wish to join Wattle College as a registered agent, you can reach out by contacting us <NavLink to="/contact-us" onClick={() => { this.scrollToTop(); }}>here</NavLink>.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EducationAgentsContent;
