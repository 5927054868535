import React, { Component } from 'react';
import DiplomaBanner from '../Diploma-components/Diploma-banner';
// import DiplomaCourseEnquiry from '../Diploma-components/Diploma-course-enquiry';
import DiplomaDetail from '../Diploma-components/Diploma-details';
import DiplomaHelp from '../Diploma-components/Diploma-help';
import Overview from '../Diploma-components/Diploma-overview';
// import SimpleSlider from '../slider';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class DiplomaPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="Diploma-page-wrapper">
                <SideButtons />
                <DiplomaBanner />
                <Overview />
                <DiplomaDetail />
                {/* <DiplomaCourseEnquiry /> */}
                {/* <SimpleSlider /> */}
                <DiplomaHelp />
                <Footer />
            </div>
        );
    }
}

export default DiplomaPage;