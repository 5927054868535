import React, { Component } from "react";
// import { Row, Col } from "react-bootstrap";
// import logo from "../../../assets/images/SVG/Logo-header.svg";
import handbooks from '../../../assets/Policies/Student-Handbook-VET.pdf';
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import pdf22 from "../../../assets/Policies/Access-and-Equity-Policy.pdf";

class PoliciesAndFormsContent extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="policies-and-forms-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Policies
                        </div>
                        <div
                            className="para"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            <ul>
                                <li>Admissions Policy</li>
                                <li>Academic Integrity Policy </li>
                                <li>Academic Language and Learning Support Policy</li>
                                <li>Academic Misconduct Policy </li>
                                <li>Anti-discrimination Policy </li>
                                <li>Academic Governance Policy </li>
                                <li>Academic Governance Framework </li>
                                <li>Assessment Policy </li>
                                <li>Benchmarking Policy </li>
                                <li>Cultural Diversity Policy</li>
                                <li>Copyright Policy</li>
                                <li>Degree Issuance and Replacement Policy </li>
                                <li>Disability Support Policy </li>
                                <li>Education Agents Policy</li>
                                <li>Language Learning Support Policy</li>
                                <li>English Language Entry Requirements Policy</li>
                                <li>Examinations Policy </li>
                                <li>External Grievance Resolution Policy</li>
                                <li>Falsification of Documentation Policy</li>
                                <li>Information to Students Policy</li>
                                <li>Library and Information Resources Policy</li>
                                <li>Moderation Policy </li>
                                <li>Orientation Policy</li>
                                <li>Recognition of Prior Learning Policy</li>
                                <li>Student Complaints and Appeals Policy</li>
                                <li>Student Record-Keeping Policy</li>
                                <li>Student Welfare Policy</li>
                                <li>Students at risk and unsatisfactory progress policy</li>
                                <li>Workplace Anti-Bullying Policy</li>
                                <li>Workplace Health and Safety Policy</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PoliciesAndFormsContent;
