import React, { Component } from 'react';
import HowToApplyBanner from '../How-to-applycomponent/How-to-apply-banner';
import HowToApplyHelp from '../How-to-applycomponent/how-to-apply-help';
import HowToOverview from '../How-to-applycomponent/how-to-apply-overview';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class HowToApply extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="how-to-apply-wrapper">
                <SideButtons />
                <HowToApplyBanner />
                <HowToOverview />
                <HowToApplyHelp />
                <Footer />
            </div>
        );
    }
}

export default HowToApply;