import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import homeCert from "../../../assets/images/home-cert.jpg";
import DiplomaOfLeadershipHome from "../../../assets/images/course-images/Diploma-of-Leadership-home.jpg";
import CertificateIVInBusinessHome from "../../../assets/images/course-images/Certificate-IV-in-Business---home.jpg";
import homeDiploma from "../../../assets/images/home-diploma.jpg";
import advancedDiploma from "../../../assets/images/fourth-card.jpg";
import englishHome from "../../../assets/images/course-images/english-home.jpg";
import COMMERCIALCOOKERYHome from "../../../assets/images/course-images/COMMERCIAL-COOKERY-home.jpg";
import kitchenManagementHome from "../../../assets/images/course-images/kitchen-management-home.jpg";
import hospitalityHome from "../../../assets/images/course-images/hospitality-home.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, NavLink } from "react-router-dom";
import AdvancedDiploma from "../../Advanced-Diploma";

class CoursesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
    this.state = { addClass2: false };
    this.state = { addClass3: false };
    this.state = {
      open: true,
    };
    this.state = {
      tOpen: false,
    };
    this.state = {
      setBlue: false,
    };
    this.state = {
      setBlue2: true,
    };
    this.state = {
      setBlue3: false,
    };

    this.state = {
      setOpen: true,
    };
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
    this.setState({ setOpen: !this.state.setOpen });
    this.setState({ setBlue2: !this.state.setBlue2 });
  }
  togglesHandle() {
    this.setState({ addClass2: !this.state.addClass2 });
    this.setState({ open: !this.state.open });
    this.setState({ setBlue3: !this.state.setBlue3 });
  }
  togglesHandle2() {
    this.setState({ addClass3: !this.state.addClass3 });
    this.setState({ tOpen: !this.state.tOpen });
    this.setState({ setBlue: !this.state.setBlue });
  }
  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  render() {
    let boxClass = ["box1"];
    let boxClass3 = ["box"];
    let boxClass2 = ["box"];
    let blueClass = ["blue"];
    let blueClass2 = ["blue"];
    let blueClass3 = ["blue"];

    if (this.state.addClass) {
      boxClass.push("back");
    } else if (this.state.addClass) {
      boxClass.push("up");
    } else {
      blueClass2.push("blues");
    }

    if (this.state.addClass2) {
      boxClass2.push("back");
      blueClass3.push("blues");
    } else if (this.state.addClass2) {
      boxClass2.push("up");
      blueClass3.push("white");
    }

    if (this.state.addClass3) {
      boxClass3.push("back");
      blueClass.push("blues");
    } else if (this.state.addClass3) {
      boxClass3.push("up");
      blueClass.push("white");
    }

    return (
      <div className="courses-overview-wrapper">
        <div className="course-section">
          <div
            className="course-div"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <Row className="main-row">
              <div
                className="main-title"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                ELICOS
              </div>
              <Col
                className="col-div col-lg-4 col-sm-6 pr-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/general-english">
                  <div className="img-div">
                    <img src={englishHome} alt="home-page-cert" />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div-first">
                  <div className="first-section">
                    <div className="heading">
                      <div className="break">General English</div>
                    </div>
                    <div className="para">
                      Our General English program is designed to help you
                      improve your reading, listening, speaking, and writing
                      skills and develop your confidence to communicate
                      effectively.
                    </div>
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/general-english">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="main-row">
              <div
                className="main-title"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Business
              </div>
              <Col
                className="col-div col-lg-4 col-sm-6 pr-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/cert-iv-in-business">
                  <div className="img-div">
                    <img
                      src={CertificateIVInBusinessHome}
                      alt="home-page-cert"
                    />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div-first">
                  <div className="first-section">
                    <div className="heading">
                      <div className="break">
                        Certificate IV in Business (BSB40120)
                      </div>
                    </div>
                    <div className="para">
                      This qualification reflects the role of individuals in a
                      variety of Business Services job roles. These individuals
                      may have supervisory performance accountabilities.
                    </div>
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/cert-iv-in-business">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
              <Col
                className="col-div col-lg-4 col-sm-6 pr-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/diploma-of-leadership-and-management">
                  <div className="img-div">
                    <img
                      src={DiplomaOfLeadershipHome}
                      alt="DiplomaOfLeadershipHome"
                    />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div">
                  <div className="heading">
                    <div className="break">
                      Diploma of Leadership and Management (BSB50420)
                    </div>
                  </div>
                  <div className="para">
                    This qualification reflects the role of individuals who
                    apply knowledge, practical skills and experience in
                    leadership and management across a range of enterprise.
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/diploma-of-leadership-and-management">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
              <Col
                className="col-div col-lg-4 col-sm-6 pl-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/advanced-diploma-of-leadership-and-management">
                  <div className="img-div">
                    <img src={advancedDiploma} alt="advancedDiploma" />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div">
                  <div className="heading">
                    <div className="break">
                      Advanced Diploma of Leadership and Management (BSB60420)
                    </div>
                  </div>
                  <div className="para">
                    This qualification reflects the role of individuals who
                    apply specialised knowledge and skills, together with
                    experience in leadership and management.
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/advanced-diploma-of-leadership-and-management">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="main-row">
              <div
                className="main-title"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Hospitality
              </div>
              <Col
                className="col-div col-lg-4 col-sm-6 pr-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/cert-iii-in-commercial-cookery">
                  <div className="img-div">
                    <img
                      src={COMMERCIALCOOKERYHome}
                      alt="cOMMERCIAL-cOOKERY-home"
                    />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div-first">
                  <div className="first-section">
                    <div className="heading">
                      <div className="break">
                        Certificate III in Commercial Cookery (SIT30821)
                      </div>
                    </div>
                    <div className="para">
                      This qualification reflects the role of cooks who use a
                      wide range of well-developed cookery skills and sound
                      knowledge of kitchen operations to prepare food and menu
                      items.
                    </div>
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/cert-iii-in-commercial-cookery">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
              <Col
                className="col-div col-lg-4 col-sm-6 pr-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/cert-iv-in-kitchen-managment">
                  <div className="img-div">
                    <img
                      src={kitchenManagementHome}
                      alt="kitchen-management-home"
                    />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div">
                  <div className="heading">
                    <div className="break">
                      Certificate IV In Kitchen Management (SIT40521)
                    </div>
                  </div>
                  <div className="para">
                    This qualification reflects the role of chefs and cooks who
                    have a supervisory or team leading role in the kitchen. They
                    operate independently or with limited guidance from others
                    and use discretion to solve non-routine problems.
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/cert-iv-in-kitchen-managment">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
              <Col
                className="col-div col-lg-4 col-sm-6 pl-2"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <Link to="/hospitality-managment">
                  <div className="img-div">
                    <img src={hospitalityHome} alt="hospitality-home" />
                    {/* <i className="bi bi-arrow-right-short"></i> */}
                  </div>
                </Link>
                <div className="text-div">
                  <div className="heading">
                    <div className="break">
                      Diploma of Hospitality Management (SIT50416)
                    </div>
                  </div>
                  <div className="para">
                    This qualification reflects the role of highly skilled
                    senior operators who use a broad range of hospitality skills
                    combined with managerial skills and sound knowledge of
                    industry to coordinate hospitality operations.
                  </div>
                  <div className="bt">
                    <NavLink className="main-links" to="/hospitality-managment">
                      <button>Learn More</button>
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="back-rect"></div>
        </div>
      </div>
    );
  }
}

export default CoursesOverview;
