import React, { Component } from 'react';
import ContactPageEnquiry from '../ContactComponenets/Contact-page-enquiry';
import ContactPageBanner from '../ContactComponenets/ContactPage-banner';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class ContactPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="Contact-page-wrapper">
                <SideButtons />
                <ContactPageBanner />
                <ContactPageEnquiry />
                <Footer />
            </div>
        );
    }
}

export default ContactPage;