import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/admission-path-img/milestones.webp";
import { NavLink } from "react-router-dom";
import "aos/dist/aos.css";
import pdf14 from "../../../assets/Policies/Complaint and Appeal Form.pdf";
function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

class RPLAndCreditTransferContent extends Component {
  render() {
    return (
      <div className="admission-path-content-wrapper">
        <div className="help-section">
          <div className="container">
            <div
              className="heading"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              1. Direct Enrolment
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="dic">
                For students not seeking any admission pathways can apply for a
                course at Wattle College using the link below.
              </div>
              <NavLink
                to="/apply-now-page"
                onClick={() => {
                  scrollToTop();
                }}
              >
                <div
                  className="button_wrapper"
                  data-aos="fade-right"
                  data-aos-duration="2500"
                >
                  Apply Now
                </div>
              </NavLink>
            </div>
            <div
              className="heading"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              2. Recognition of Prior Learning (RPL)
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              Recognition of Prior Learning means an assessment process that
              assesses the competency(s) of an individual that may have been
              acquired through formal, non-formal and informal learning. This
              will allow the student to skip learning outcomes and skills that
              the student has already achieved.
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              Wattle College considers RPL in three areas:
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              <ul>
                <li>
                  <strong>Formal learning</strong> – learning that takes place
                  through a structured program of instruction and is linked to
                  the attainment of an AQF qualification or statement of
                  attainment (for example, a certificate, diploma, or university
                  degree). <strong>See Credit Transfer below.</strong>
                </li>
              </ul>
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <ul>
                <li>
                  <strong>Informal Learning</strong> – refers to learning that
                  results through experience of work-related, social, family,
                  hobby, or leisure activities (for example the acquisition of
                  interpersonal skills developed through several years as a
                  sales representative).
                </li>
              </ul>
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="250"
            >
              <ul>
                <li>
                  <strong>Non – formal learning</strong> - to learning that
                  takes place through a structured program of instruction but
                  does not lead to the attainment of an AQF qualification or
                  statement of attainment (for example, in house professional
                  development programs conducted by a business)
                </li>
              </ul>
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              How to apply
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              RPL must be applied either prior to enrolment of the course or
              within 14 days of course commencement.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              Perspective students seeking RPL can register their intention
              within the enrolment form. Wattle College will invite the student
              for an interview which will include.
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <ul>
                <li>
                  Identifying the relevant documentary evidence for each unit
                  that will contribute to the RPL application such as resumes,
                  position descriptions and references.{" "}
                </li>
              </ul>
            </div>
            <div
              className="para"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <ul>
                <li>
                  In addition, oral questioning to gather further evidence of
                  skills and knowledge for each unit.
                </li>
              </ul>
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              Wattle College will provide the student with most up to date
              information regarding the RPL process.
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              Next steps
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              The student will gather the reliable evidence and submit the
              completed RPL application form.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              A qualified assessor will assess the form and the evidence
              submitted. If the assessor is satisfied with the evidence, the
              assessor will complete an assessment summary sheet and provide an
              outcome. The assessor might ask for further evidence before
              providing an outcome.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              Students will be advised promptly of the decision where RPL is
              ‘granted’ or ‘not granted’ within 10 working days of completion of
              the assessment. The written communication to the participant is to
              include a reason for refusal (where applicable). Complaint and
              appeal procedure would apply.
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              Complaints and Appeals
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              If the student is not satisfied with the outcome. they may appeal
              the outcome through the College grievance process. Refer to{" "}
              <a href={pdf14} target="_blank">
                Student Complaints and Appeals Policy
              </a>
              .
            </div>
            <div
              className="heading"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              3. Credit Transfer
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              Credit transfer allows a student to be awarded a unit of
              competency based on successful completion of the unit which has
              been previously awarded.
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              How to apply
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              Prospective students seeking credit transfer can register their
              intention within the enrolment form. Wattle College will provide
              the student with the credit transfer application form.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              The student must complete the student credit transfer application
              form, attach a copy of a verified Award or Statement of
              Attainment, and submit the application to the Student Admissions.
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              Next steps
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              The student admissions will forward the application to the
              training manager.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              The Training Manager will check the Award or Statement of
              Attainment and grant credit(s) for identical units that have been
              identified as being completed at another Registered Training
              Organisation.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              If Credit(s) are granted by Wattle College, the student will be
              informed using credit transfer feedback form accompanied by a
              formal letter.
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              If the units are not equivalent, the applicant would be referred
              for Recognition of Prior Learning (RPL). See above for more
              information.
            </div>
            <div
              className="help-heading"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
            >
              Complaints and Appeals
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              If the student is not satisfied with the outcome. they may appeal
              the outcome through the College grievance process. Refer to{" "}
              <a href={pdf14} target="_blank">
                Student Complaints and Appeals Policy
              </a>
              .
            </div>
            <div
              className="heading"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              3. English Pathways
            </div>
            <div
              className="para-first"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="100"
            >
              Wattle College has a range of industry partners where you can
              complete the English foundational courses before gaining admission
              at Wattle College. <a href="/contact-us">Contact us</a> for more
              information.
            </div>

            <div className="logo-section">
              <Row>
                <Col className="cols" md="12" xs="6">
                  <div
                    className="main-div-logo"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <img src={logo} alt="logo image" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RPLAndCreditTransferContent;
