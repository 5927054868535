import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import { Accordion, Card, Button } from "react-bootstrap";
import droparrowupb from "../../../assets/images/arrow-down.png";

import aboutback from "../../../assets/images/How-to-apply/apply-img.png";
import pdf13 from "../../../assets/Policies/Application Form 2022.pdf";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class HowToOverview extends Component {
    constructor(props) {
        super(props);
        this.state = { addClass2: false };
        this.state = { addClass3: false };

        this.state = {
            tOpen: false,
        };
        this.state = {
            setBlue: false,
        };

        this.state = {
            setBlue3: true,
        };
        this.state = {
            open: true,
        };
    }
    togglesHandle() {
        this.setState({ addClass2: !this.state.addClass2 });
        this.setState({ open: !this.state.open });
        this.setState({ setBlue3: !this.state.setBlue3 });
    }
    togglesHandle2() {
        this.setState({ addClass3: !this.state.addClass3 });
        this.setState({ tOpen: !this.state.tOpen });
        this.setState({ setBlue: !this.state.setBlue });
    }
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    render() {
        let boxClass3 = ["box"];
        let boxClass2 = ["box1"];
        let blueClass = ["blue"];
        let blueClass3 = ["blue"];

        if (this.state.addClass2) {
            boxClass2.push("back");
        } else if (this.state.addClass2) {
            boxClass2.push("up");
        } else {
            blueClass3.push("blues");
        }
        if (this.state.addClass3) {
            boxClass3.push("back");
            blueClass.push("blues");
        } else if (this.state.addClass3) {
            boxClass3.push("up");
            blueClass.push("white");
        }

        return (
            <div className="how-to-apply-overview-wrapper">
                <div className="course-section">
                    <div
                        className="course-div"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                    >
                        <div className="heading-course">The Application Process</div>
                        <div className="d-para">
                            Thank you for your interest at Wattle College. Based on whether you are an international student or a domestic student, the steps to apply at Wattle College are listed below. If you need any further clarifications you can contact the Admissions team at <a href="mailto: info@wattle.edu.au">info@wattle.edu.au</a> or you can give us a call at <a href="tel: 1300 118 085">1300 118 085</a>.
                        </div>

                    </div>
                    <div className="back-rect"></div>
                </div>
                <div className="container">

                    <div className="program-structure">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header
                                    as={Button}
                                    className={boxClass2.join(" ")}
                                    className={blueClass3.join(" ")}
                                    onClick={this.togglesHandle.bind(this)}
                                    variant="link"
                                    eventKey="2"
                                >
                                    {this.state.addClass2 ? "" : ""}
                                    <div className="card-head">International Students</div>
                                    <Accordion.Toggle
                                        as={Button}
                                        className={boxClass2.join(" ")}
                                        onClick={this.togglesHandle.bind(this)}
                                        variant="link"
                                        eventKey="2"
                                    >
                                        {this.state.addClass2 ? "" : ""}
                                        <img src={droparrowupb} className="dropsb3" alt="arrows" />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse in={this.state.open} eventKey="2">
                                    <Card.Body className="entry-body">
                                        <div className="entry-para-top">
                                            If you are an international student, you need to follow the following steps:
                                        </div>
                                        <div className="entry-para">
                                            1. Check the entry requirements of the course by visiting our <NavLink to="/courses" onClick={() => { this.scrollToTop(); }}>courses</NavLink> including the English requirements.
                                        </div>
                                        <div className="entry-para">
                                            2. Complete the application form through apply now page. Please note, enrolment terms and conditions apply.
                                        </div>
                                        <div className="entry-para">
                                            3. You will need to provide certified copies of the following documents. <br />
                                            <div className="flex-div first-div"><span id="dots">•	</span>Passport</div>
                                            <div className="flex-div"><span id="dots">•	</span>English language proficiency documents (IELTS certificate or equivalent)</div>
                                            <div className="flex-div"><span id="dots">•	</span>High school transcripts </div>
                                            <div className="flex-div"><span id="dots">•	</span>Prior degree completion certificates (If applicable)</div>
                                            <div className="flex-div"><span id="dots">•	</span>Prior work experience details (if applicable)</div>
                                        </div>
                                        <div className="entry-para">
                                            4. After submission of application, we will email you about the outcome of your application. If you have been accepted, you will receive a letter of offer from us. You have an option to either accept the offer or decline the offer.
                                        </div>
                                        <div className="entry-para">
                                            5. If you have accepted the offer, you need to sign and return the letter of offer along with payment of fees specified in the letter of offer.
                                        </div>
                                        <div className="entry-para">
                                            6. After receiving the documents and payment, we will issue an eCoE (Electronic Confirmation of Enrolment) so that you can apply for a visa.
                                        </div>
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header
                                    as={Button}
                                    className={boxClass3.join(" ")}
                                    className={blueClass.join(" ")}
                                    onClick={this.togglesHandle2.bind(this)}
                                    variant="link"
                                >
                                    {this.state.addClass3 ? "" : ""}
                                    <div className="card-head">Domestic Students</div>
                                    <Accordion.Toggle
                                        as={Button}
                                        className={boxClass3.join(" ")}
                                        onClick={this.togglesHandle2.bind(this)}
                                        variant="link"
                                    >
                                        {this.state.addClass3 ? "" : ""}
                                        <img src={droparrowupb} className="dropsb3" alt="arrows second" />
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse in={this.state.tOpen}>
                                    <Card.Body className="entry-body">
                                        <div className="entry-para-top">
                                            If you are a domestic student, you need to follow the following steps:
                                        </div>
                                        <div className="entry-para">
                                            1. Check the entry requirements of the course by visiting our <NavLink to="/courses">courses</NavLink>
                                        </div>
                                        <div className="entry-para">
                                            2. Complete the application form through apply now page. Please note, enrolment terms and conditions apply.
                                        </div>
                                        <div className="entry-para">
                                            3. You will need to provide certified copies of the following documents. <br />
                                            <div className="flex-div first-div"><span id="dots">•	</span>Passport or birth certificate and Photo ID</div>
                                            <div className="flex-div"><span id="dots">•	</span>High school transcripts </div>
                                            <div className="flex-div"><span id="dots">•	</span>Prior degree completion certificates (If applicable)</div>
                                            <div className="flex-div"><span id="dots">•	</span>Prior work experience details (if applicable)</div>
                                        </div>
                                        <div className="entry-para">
                                            4. After submission of application, we will email you about the outcome of your application. If you have been accepted, you will receive a letter of offer from us. You have an option to either accept the offer or decline the offer.
                                        </div>
                                        <div className="entry-para">
                                            5. If you have accepted the offer, you need to sign and return the letter of offer along with payment of fees specified in the letter of offer.
                                        </div>
                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>

                <div className="glance-section">
                    <div className="container">
                        <Row className="wallet-section-f">
                            <Col lg="6" className="col-sections-a">
                                <div
                                    className="heading"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    Discover the benefits
                                </div>
                                <div
                                    className="para"
                                    data-aos="fade-right"
                                    data-aos-duration="1300"
                                    data-aos-delay="200"
                                >
                                    of letting us bring our <br /> courses to you
                                </div>
                            </Col>

                            <Col lg="6" className="col-section-b">
                                <div
                                    className="img"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <img src={aboutback} alt="about background image" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowToOverview;
