import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import pdf21 from "../../../assets/Policies/Trainer Portal Web Guide.pdf";

class StudentSupport extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    
    render() {
        return (
            <div className="student-support-wrapper">
                <div className="container">
                    <Row className="rows">
                        <Col md="12" className="cols">
                            <div
                                className="heading"
                                data-aos="fade-right"
                                data-aos-duration="1350"
                                data-aos-delay="150"
                            >
                                Technical Support
                            </div>
                            <div
                                className="para"
                                data-aos="fade-right"
                                data-aos-duration="1300"
                                data-aos-delay="200"
                            >
                                For any student/staff unable to login or having issues with the portal, please contact the student support team at 1300 118 085 or <NavLink to="/contact-us" onClick={() => { this.scrollToTop(); }}>contact us here</NavLink>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

export default StudentSupport;
