import React, { Component } from 'react';
import LoginSection from '../studentportalscomponent/Login-section';
import StudentLoginBanner from '../studentportalscomponent/student-login-banner';
import StudentLoginHelp from '../studentportalscomponent/student-login-help';
import StudentSupport from '../studentportalscomponent/Student-support';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class StudentLoginPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="student-login-page">
                <SideButtons />
                <StudentLoginBanner />
                <LoginSection />
                <StudentSupport />
                <StudentLoginHelp />
                <Footer />
            </div>
        );
    }
}

export default StudentLoginPage;