import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import img1 from "../../../assets/images/Academic-img/1.jpg";
import img2 from "../../../assets/images/Academic-img/2.jpg";
import img3 from "../../../assets/images/Academic-img/3.jpg";
import img4 from "../../../assets/images/Academic-img/4.jpg";
import img5 from "../../../assets/images/Academic-img/5.jpg";
import img6 from "../../../assets/images/Academic-img/6.jpg";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class AcademicBoardContent extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="academic-profile-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div
                            className="academic-heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Academic Board
                        </div>
                        <Row className="wallet-section-f">
                            <Col lg="4" md="6" xs="12"
                                className="col-sections-e"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img1} alt="person 1" />
                                </div>
                                <div className="heading">Steven Asnicar</div>
                                <div className="para">The chair, Steven Asnicar, has 30 years healthcare industry expertise and has been a Director on the Australian Private Hospitals Association across more than 800 facilities, the Private Hospital Association of Queensland and worked with the Health Insurance Commission in Australia. Steven created the first National and International Telehealth framework in Australia servicing remote ICU's in multiple locations in and outside Australia.</div>
                            </Col>

                            <Col lg="4" md="6" xs="12"
                                className="col-section-b"
                                data-aos="flip-right"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img2} alt="person 2" />
                                </div>
                                <div className="heading"> Alastair Sloan</div>
                                <div className="para">Professor Alastair Sloan is Head of Melbourne Dental School, University of Melbourne and Professor of Tissue Engineering and Dental Biology. He is a bioengineer with interests in mineralised tissue repair, targeted drug delivery systems and development of model systems for understanding bacterial infection/invasion in dental and bone infections. He has lectured globally in his field. He is an active teacher and educator and has developed and refined curricula for underpinning biosciences for undergraduate dental programmes and also Masters level programmes for Oral Biology and Tissue Engineering.</div>
                            </Col>

                            <Col lg="4" md="6" xs="12"
                                className="col-sections-e"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img5} alt="person 3" />
                                </div>
                                <div className="heading">Kevin Yap </div>
                                <div className="para">Dr. Kevin Yap is an interdisciplinary cyberpharmacist and digital health researcher who has knowledge in digital media creation, web databases and data mining. His work has been in the areas of e-databases and data mining, telehealth/telepharmacy and mobile health (mHealth), health data analytics and clinical prediction of adverse drug reactions, virtual reality and serious games, 3D-printed applications, as well as technology integration and quality evaluations of healthcare innovations. He has over 100 peer-reviewed publications, 4 book chapters and 1 book, and presented his work at over 100 international conferences.</div>
                            </Col>

                            <Col lg="4" md="6" xs="12"
                                className="col-section-b"
                                data-aos="fade-up-right"
                                data-aos-delay="500"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img3} alt="person 4" />
                                </div>
                                <div className="heading"> Robab Abdolkhani</div>
                                <div className="para">Robab holds a PhD in health informatics from the University of Melbourne. She has extensive experience in health informatics and digital health research and teaching. Robab is internationally recognised for her research and learning excellence in the field.</div>
                            </Col>

                            <Col lg="4" md="6" xs="12"
                                className="col-section-a"
                                data-aos="zoom-in-up"
                                data-aos-delay="500"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img4} alt="person 5" />
                                </div>
                                <div className="heading">Lee Muddle</div>
                                <div className="para"> Lee is an AHPRA registered osteopath with an accompanying MSc in Sports medicine, exercise and health. She works as a full-time academic at RMIT University in the osteopathy discipline and is currently undertaking a PhD in the learning and teaching space.</div>
                            </Col>

                            <Col lg="4" md="6" xs="12"
                                className="col-sections-e"
                                data-aos="fade-up-left"
                                data-aos-delay="500"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img6} alt="person 6" />
                                </div>
                                <div className="heading">Tafadzwa Nyanhanda</div>
                                <div className="para">Tafadzwa is currently a Senior Lecturer-Public Health within the School of Health, Medical and Applied Sciences, CQUniversity. With over 20 years of experience in program design, implementation, monitoring and evaluation and nutrition and health research, Tafadzwa has led large-scale research and evaluations of complex, multi-sectoral community development programmes in Sri Lanka, Armenia, Ethiopia and Senegal. She has a PhD in Molecular Nutrition with minors in Developmental Biology and Toxicology from Cornell University, Ithaca, USA in 2008 and a Bachelor of Science in Nutritional Sciences from the University of Zimbabwe in 2000.</div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>
        );
    }
}

export default AcademicBoardContent;
