import React, { Component } from 'react';
import arrow from '../../assets/images/li-arrow.png';
// import img1 from '../../assets/images/main-banner.jpg';
// import img2 from '../../assets/images/news.png';
// import {Carousel} from 'react-bootstrap';
import handbooks from '../../assets/Policies/Student-Handbook-VET.pdf';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

class Banner extends Component {
    componentDidMount() {
        AOS.init({
            duration: 800,
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        })
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="banner-wrapper">
                <div className="main container">
                    <div className="head-section">
                        <div className="heading-top" data-aos="fade-right" data-aos-duration="1500">
                            Welcome To
                        </div>
                        <div className="heading-bottom" data-aos="fade-right" data-aos-duration="2000">
                            Wattle College
                        </div>
                        <div className="line"></div>
                        <div className="link-section">
                            <div className="lif" data-aos="zoom-in" data-aos-duration="1500">
                                <a href="/courses">
                                    Our courses <img src={arrow} alt="view-arrow" />
                                </a>
                            </div>
                            <div className="lif" data-aos="zoom-in" data-aos-duration="2000">
                                <a href={handbooks} target="_blank" onClick={() => { this.scrollToTop(); }}>
                                    Student Handbook <img src={arrow} alt="campus-arrow" />
                                </a>
                            </div>
                            <div className="lif" data-aos="zoom-in" data-aos-duration="3000">
                                <NavLink to="/why-wattle-college" onClick={() => { this.scrollToTop(); }}>
                                    Why wattle<img src={arrow} alt="download-arrow" />
                                </NavLink>
                            </div>
                            <div className="lif" data-aos="zoom-in" data-aos-duration="3000">
                                <a href="https://mywattle.app.axcelerate.com/learner/" target="blank" onClick={() => { this.scrollToTop(); }}>
                                    Student login<img src={arrow} alt="student-arrow" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Carousel interval={null}>
                    <Carousel.Item interval={2000}>
                        <div className="overlay"></div>
                        <img
                        className="d-block w-100"
                        src={img1}
                        alt="First slide"
                        />
                        <Carousel.Caption className="first-caption">
                        <div className="main">
                            <div className="head-section">
                                <div className="heading-top">
                                    Welcome To
                                </div>
                                <div className="heading-bottom">
                                    Wattle College
                                </div>
                                <div className="line"></div>
                                <div className="link-section">
                                    <div className="lif">View All Courses <img  src={arrow}/></div>
                                    <div className="lif">Our Campus <img  src={arrow}/></div>
                                    <div className="lif">Support Services <img  src={arrow}/></div>
                                    <div className="lif">Download Brochure <img  src={arrow}/></div>
                                </div>
                            </div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item >
                        <Carousel.Item interval={2000} className="second-item">
                            <div className="overlay"></div>
                            <img
                            className="d-block w-100"
                            src={img1}
                            alt="Third slide"
                            /> 
                            <Carousel.Caption className="first-caption">
                                <div className="main">
                                    <div className="head-section">
                                        <div className="heading-top">
                                            Welcome To
                                        </div>
                                        <div className="heading-bottom">
                                            Wattle College
                                        </div>
                                        <div className="line"></div>
                                        <div className="link-section">
                                            <div className="lif">View All Courses <img  src={arrow}/></div>
                                            <div className="lif">Our Campus <img  src={arrow}/></div>
                                            <div className="lif">Support Services <img  src={arrow}/></div>
                                            <div className="lif">Download Brochure <img  src={arrow}/></div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    <Carousel.Item interval={2000}>
                        <div className="overlay"></div>
                        <img
                        className="d-block w-100"
                        src={img1}
                        alt="Third slide"
                        />

                        <Carousel.Caption className="first-caption">
                            <div className="main">
                                <div className="head-section">
                                    <div className="heading-top">
                                        Welcome To
                                    </div>
                                    <div className="heading-bottom">
                                        Wattle College
                                    </div>
                                    <div className="line"></div>
                                    <div className="link-section">
                                        <div className="lif">View All Courses <img  src={arrow}/></div>
                                        <div className="lif">Our Campus <img  src={arrow}/></div>
                                        <div className="lif">Support Services <img  src={arrow}/></div>
                                        <div className="lif">Download Brochure <img  src={arrow}/></div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
             */}
            </div>
        );
    }
}

export default Banner;