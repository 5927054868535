import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

class LibrarySupport extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }
    render() {
        return (
            <div className="library-support-wrapper">
                <div className="container">
                    <div
                        className="heading-main"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                    >
                        Other Support Platforms
                    </div>
                    <div
                        className="para-main"
                        data-aos="fade-right"
                        data-aos-duration="1400"
                        data-aos-delay="100"
                    >
                        From Moodle, our Learning Management System, you will access the
                        following platforms.
                    </div>
                    <Row className="rows">
                        <Col md="6" className="cols">
                            <div
                                className="heading"
                                data-aos="fade-right"
                                data-aos-duration="1350"
                                data-aos-delay="150"
                            >
                                BlackBoard Collaborate Ultra
                            </div>
                            <div
                                className="para"
                                data-aos="fade-right"
                                data-aos-duration="1300"
                                data-aos-delay="200"
                            >
                                Our online live and interactive webinar lecturer platform where
                                you will join the lecturer and other participants from around
                                the world in an online ‘virtual classroom.
                            </div>
                        </Col>
                        <Col md="6" className="cols second-col">
                            <div
                                className="heading"
                                data-aos="fade-left"
                                data-aos-duration="1350"
                                data-aos-delay="150"
                            >
                                Dedicated Student Email – Higher Education
                            </div>
                            <div
                                className="para"
                                data-aos="fade-left"
                                data-aos-duration="1300"
                                data-aos-delay="200"
                            >
                                All higher education students will have a dedicated
                                @student.www.eit.edu.au email. This ensures efficient
                                communications between our academic and student body.
                            </div>
                        </Col>
                    </Row>
                    <Row className="rows">
                        <Col md="6" className="cols">
                            <div
                                className="heading"
                                data-aos="fade-right"
                                data-aos-duration="1250"
                                data-aos-delay="250"
                            >
                                Electromeet – Remote and Virtual Labs
                            </div>
                            <div
                                className="para"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay="300"
                            >
                                You will participate in practical exercises using a combination
                                of cutting edge remote laboratories and simulation software to
                                ensure you have the requisite hands-on experience. This provides
                                solid practical exposure to the key principles covered in the
                                program and ensures you will be able to put theory into
                                practice.
                            </div>
                        </Col>
                        <Col md="6" className="cols second-col">
                            <div
                                className="heading"
                                data-aos="fade-left"
                                data-aos-duration="1250"
                                data-aos-delay="250"
                            >
                                Learning Resources and eLibrary
                            </div>
                            <div
                                className="para"
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                data-aos-delay="300"
                            >
                                Our learning resources are hosted on our learning management
                                system and provides our students access to a vast array of
                                resources for the duration of their program.
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default LibrarySupport;
