import React, { Component } from "react";
import Footer from "../Footer";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";
import KitchenMngBanner from "../Kitchen-managment-component/Kitchen-mng-banner";
import KitchenMngOverview from "../Kitchen-managment-component/Kitchen-mng-overview";
import KitchenMngDetail from "../Kitchen-managment-component/Kitchen-mng-details";
import KitchenMngHelp from "../Kitchen-managment-component/Kitchen-mng-help";

class KitchenMngPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="kitchen-page-wrapper">
                <SideButtons />
                <KitchenMngBanner />
                <KitchenMngOverview />
                <KitchenMngDetail />
                <KitchenMngHelp />
                <Footer />
            </div>
        );
    }
}

export default KitchenMngPage;
