import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';
import LMSBanner from '../LMS-component/LMS-banner';
import LMSLoginSection from '../LMS-component/LMS-Login-section';
import LMSStudentSupport from '../LMS-component/LMS-Student-support';
import LMSHelp from '../LMS-component/LMS-help';

class LMSPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="student-login-page">
                <SideButtons />
                <LMSBanner/>
                <LMSLoginSection/>
                <LMSStudentSupport/>
                <LMSHelp />
                <Footer />
            </div>
        );
    }
}

export default LMSPage;