import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import CertificateIVInBusinessHome from "../../assets/images/course-images/Certificate-IV-in-Business---home.jpg";
import englishHome from "../../assets/images/course-images/english-home.jpg";
import hospitalityHome from "../../assets/images/course-images/hospitality-home.jpg";
import kitchenManagementHome from "../../assets/images/course-images/kitchen-management-home.jpg";
import { NavLink ,Link } from "react-router-dom";
import "aos/dist/aos.css";

class LearnMore extends Component {
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div className="learn-more-wrapper">
        <div className="anchor" id="courses"></div>
        <div className="container">
          <div className="main-heading">
            <span>Courses</span>
            <Link to="/courses">View all courses</Link>
          </div>
          <Row className="main-row">
            <Col
              className="col-div col-lg-4 col-md-6 pr-2"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="img-div">
                <img src={englishHome} alt="english-home" />
                <div className="overlay-div"></div>
              </div>
              <div className="text-div-first">
                <div className="first-section">
                  <div className="heading">
                    <div className="break">ELICOS</div>
                    {/* <div className="break">in Business</div>
                    <div className="break-last">(BSB40120)</div> */}
                  </div>
                </div>
                <div className="bt">
                  <NavLink
                    to="/courses"
                    onClick={() => {
                      this.scrollToTop();
                    }}
                    className="main-button"
                  >
                    <button>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col
              className="col-div col-lg-4 col-md-6 pr-2"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="img-div">
                <img src={CertificateIVInBusinessHome} alt="certificate-in-businessHome" />
                <div className="overlay-div"></div>
              </div>
              <div className="text-div">
                <div className="heading">
                  <div className="break">Business</div>
                  {/* <div className="break">and Management</div>
                  <div className="break-last">(BSB50420)</div> */}
                </div>
                <div className="bt">
                  <NavLink
                    to="/courses"
                    onClick={() => {
                      this.scrollToTop();
                    }}
                    className="main-button"
                  >
                    <button>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col
              className="col-div col-lg-4 col-md-6 pl-2"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="img-div">
                <img src={kitchenManagementHome} alt="hospitalityHome" />
                <div className="overlay-div"></div>
              </div>
              <div className="text-div">
                <div className="heading">
                  <div className="break">Hospitality</div>
                  {/* <div className="break"> and Management</div>
                  <div className="break-last">(BSB60420)</div> */}
                </div>
                <div className="bt">
                  <NavLink
                    to="/courses"
                    onClick={() => {
                      this.scrollToTop();
                    }}
                    className="main-button"
                  >
                    <button>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default LearnMore;
