import React, { Component } from 'react';
import arrowright from '../../../assets/images/course-deploma/course-icons/arrow-banner.png';
import kitchenManagementHome from "../../../assets/images/course-images/kitchen-management-home.jpg";
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

class KitchenMngBanner extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        })
    }
    scrollToTop() {
        window.scrollTo({
            top: -70,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="kitchen-banner-wrapper">
                <div className="main container">
                    <div className="head-section">
                        <div className="heading-top" data-aos="fade-right" data-aos-duration="1500">
                            Certificate IV In Kitchen Management
                        </div>
                    </div>
                </div>
                <div className="page-list-wrapper">
                    <div className="lists container">
                        <div className="list-items li-home" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="400">
                            <NavLink to="/">
                                Home
                            </NavLink>
                            <img src={arrowright} alt="right arrow" />
                        </div>
                        <div className="list-items li-co" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="200">
                            <a href="/courses" onClick={() => { this.scrollToTop(); }}>
                                Courses
                            </a>
                            <img src={arrowright} alt="course right arrow" />
                        </div>
                        <div className="list-items" data-aos="fade-right" data-aos-duration="1500">
                            <NavLink to="/cert-iv-in-kitchen-managment">
                               Certificate IV in Kitchen Management
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default KitchenMngBanner;
