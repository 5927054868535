import React, { Component } from 'react';
import Footer from '../Footer';
import StudentSupportBanner from '../Student-support-components/student-support-banner';
import StudentSupportContent from '../Student-support-components/student-support-content';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class StudentSupportPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="student-support-wrapper">
                <SideButtons />
                <StudentSupportBanner />
                <StudentSupportContent />
                <Footer />
            </div>
        );
    }
}

export default StudentSupportPage;