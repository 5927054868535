import React from 'react'
import CoursesOverview from '../Courses-component/course-overview'
import CoursesBanner from '../Courses-component/courses-banner'
import Footer from '../Footer'
import SideButtons from '../SideButtons'

function Courses() {
  return (
    <div>
      <SideButtons />
      <CoursesBanner />
      <CoursesOverview />
      <Footer />
    </div>
  )
}

export default Courses