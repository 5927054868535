import React from "react";
import { Row, Col } from "react-bootstrap";
import teamImg from "../../assets/images/news.png";
import Arrow from "../../assets/images/icons/arrow.png";
import "aos/dist/aos.css";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

// TO Call IMAGE USE https://wattle-admin.herokuapp.com/uploads/images/ FIRST
function PostDetail({ postDetail }) {
    var div = document.createElement("div");
    div.innerHTML = postDetail.content;
    return (
        <div className="post_detail_wrapper">
            <div className="latest-cont container">
                {/* <div className="latest_news_title">
                    <div className="title">{item.title}</div>
                    <div className="sub_title">
                        Check out the latest News form Wattle College
                    </div>
                </div> */}
                <Row className="card-row">
                    <Col
                        className="card-col"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                    >
                        <img src={"https://wattle-admin.herokuapp.com/uploads/images/" + postDetail.image} alt="avatar" width="100%" />
                        <div className="description_wrapper">
                            <div className="card-content">
                                <div className="main_detail">
                                    {postDetail.title}
                                </div>
                                <div className="borderd"></div>
                                <div className="details_desc">
                                    {div.innerText}
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
                {postDetail && <NavLink to="/all-posts" onClick={scrollToTop} className="view-all-btn"><button>View All Posts</button></NavLink>
                }
            </div>
            <Footer />
        </div>
    );
}

export default PostDetail;
