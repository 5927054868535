import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import EducationAgentsBanner from '../EducationAgentsComponents/education-agent-banner';
import EducationAgentsContent from '../EducationAgentsComponents/education-agent-content';
import SideButtons from '../SideButtons';

class EducationAgentsPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="education-agents-wrapper">
                <SideButtons />
                <EducationAgentsBanner />
                <EducationAgentsContent />
                <Footer />
            </div>
        );
    }
}

export default EducationAgentsPage;