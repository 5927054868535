import React, { Component } from 'react';
import LatestNews from '../LatestNews';
import ChooseWallet from '../Wallet';
import Banner from '../Banner';
import Services from '../servicesinfo';
import AboutUs from '../aboutus';
import LearnMore from '../learnmore';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';
import SimpleSlider from '../slider';


class HomePage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        const { data, handlePostDetail } = this.props;
        // console.log(data["hydra:member"]);
        return (
            <div className="homepage-wrapper">
                <SideButtons />
                <Banner />
                <ChooseWallet />
                <LearnMore />
                <Services />
                <AboutUs postList={data} />
                {/* <SimpleSlider /> */}
                {/* <LatestNews postList={data} handlePostDetail={handlePostDetail} /> */}
                <Footer />
            </div>
        );
    }
}

export default HomePage;