import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

class KitchenMngHelp extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="kitchen-help-wrapper">
                <div className="help-section">
                    <div
                        className="help-heading"
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                    >
                        Helpful Information
                    </div>

                    <div className="container">
                        <Row className="section-f">
                            <Col
                                className="col-section-a"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                data-aos-delay="200"
                            >
                                <NavLink to="/why-wattle-college"  onClick={() => {this.scrollToTop();}}>
                                    <div className="img">
                                    <svg
                                        fill="white"
                                        height="512pt"
                                        viewBox="-59 0 512 512"
                                        width="512pt"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="m344.675781 39.398438h-48.671875v-19.398438c0-11.046875-8.953125-20-20-20h-157.339844c-11.046874 0-20 8.953125-20 20v19.398438h-48.664062c-27.570312 0-50 22.429687-50 50v372.601562c0 27.570312 22.429688 50 50 50h294.675781c27.570313 0 50-22.429688 50-50v-372.601562c0-27.570313-22.433593-50-50-50zm-88.671875.601562v38.800781h-117.34375c0-13.292969 0-24.273437 0-38.800781zm98.671875 422c0 5.515625-4.488281 10-10 10h-294.675781c-5.515625 0-10-4.484375-10-10v-372.601562c0-5.511719 4.484375-10 10-10h48.660156v19.402343c0 11.046875 8.957032 20 20 20h157.34375c11.042969 0 20-8.953125 20-20v-19.402343h48.671875c5.511719 0 10 4.488281 10 10zm-64.535156-228.480469c7.8125 7.8125 7.8125 20.476563 0 28.285157l-105.101563 105.101562c-7.808593 7.8125-20.472656 7.8125-28.285156 0l-52.230468-52.230469c-7.808594-7.808593-7.808594-20.472656 0-28.285156 7.8125-7.808594 20.472656-7.808594 28.285156 0l38.089844 38.089844 90.957031-90.957031c7.8125-7.8125 20.472656-7.8125 28.285156-.003907zm0 0" />
                                    </svg>
                                </div>
                                </NavLink>
                                <div className="heading">
                                    <NavLink to="/why-wattle-college"  onClick={() => {this.scrollToTop();}}>Why Wattle</NavLink>
                                </div>
                            </Col>
                            <Col
                                className="col-section-b"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                <a href="/courses">
                                <div className="img">
                                    <svg
                                        fill="white"
                                        id="_x31__x2C_5"
                                        enable-background="new 0 0 24 24"
                                        height="512"
                                        viewBox="0 0 24 24"
                                        width="512"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g>
                                            <path d="m23.25 22c-.414 0-.75-.336-.75-.75v-18.5c0-.689-.561-1.25-1.25-1.25h-18.5c-.689 0-1.25.561-1.25 1.25v12.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-12.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v18.5c0 .414-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m23.25 5h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m8.25 21.23c-.414 0-.75-.336-.75-.75v-10.23c0-1.241 1.01-2.25 2.25-2.25s2.25 1.009 2.25 2.25v6c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-6c0-.414-.337-.75-.75-.75s-.75.336-.75.75v10.23c0 .414-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m11.25 19.5c-.414 0-.75-.336-.75-.75v-2.5c0-1.241 1.01-2.25 2.25-2.25s2.25 1.009 2.25 2.25v1c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1c0-.414-.337-.75-.75-.75s-.75.336-.75.75v2.5c0 .414-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m14.25 20.5c-.414 0-.75-.336-.75-.75v-2.5c0-1.241 1.01-2.25 2.25-2.25s2.25 1.009 2.25 2.25v2c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-2c0-.414-.337-.75-.75-.75s-.75.336-.75.75v2.5c0 .414-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m20.25 24c-.414 0-.75-.336-.75-.75v-4c0-.414-.337-.75-.75-.75s-.75.336-.75.75v1.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-1.241 1.01-2.25 2.25-2.25s2.25 1.009 2.25 2.25v4c0 .414-.336.75-.75.75z" />
                                        </g>
                                        <g>
                                            <path d="m5.36 24c-.29 0-.566-.169-.688-.453-.709-1.645-1.348-2.238-1.814-2.671-.422-.392-.858-.796-.858-1.626 0-.292.133-1.75 2.75-1.75 1.838 0 3.245 1.377 4.103 2.533.247.333.177.802-.156 1.049-.331.247-.801.176-1.049-.156-.534-.719-1.62-1.926-2.898-1.926-1.045 0-1.257.288-1.258.291.008.111.008.134.386.485.532.494 1.337 1.241 2.171 3.177.164.38-.012.822-.392.986-.096.041-.197.061-.297.061z" />
                                        </g>
                                    </svg>
                                </div>                                
                                </a>
                                <div className="heading">
                                    <a href="/courses">Our Courses</a>
                                </div>
                            </Col>
                            <Col
                                className="col-section-c"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >  
                                <NavLink to="/how-to-apply"  onClick={() => {this.scrollToTop();}}>
                                    <div className="img">
                                    <svg
                                        fill="white"
                                        id="Capa_1"
                                        enable-background="new 0 0 512 512"
                                        height="512"
                                        viewBox="0 0 512 512"
                                        width="512"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g>
                                            <path d="m256 195c-24.813 0-45 20.187-45 45h30c0-8.271 6.729-15 15-15s15 6.729 15 15c0 5.34-2.878 10.323-7.511 13.002-13.872 8.025-22.489 22.958-22.489 38.971v23.027h30v-23.027c0-5.34 2.878-10.323 7.511-13.003 13.872-8.024 22.489-22.957 22.489-38.97 0-24.813-20.187-45-45-45z" />
                                            <path d="m241 345h30v30h-30z" />
                                            <path d="m481.643 212.459c-6.559-118.254-105.253-212.459-225.643-212.459s-219.084 94.205-225.643 212.459c-17.644 6.091-30.357 22.854-30.357 42.541v60c0 24.813 20.187 45 45 45h64.052c27.356 53.382 82.956 90 146.948 90s119.592-36.618 146.948-90h49.052v17c0 57.897-47.103 105-105 105h-106v30h106c74.439 0 135-60.561 135-135v-19.58c17.459-6.192 30-22.865 30-42.42v-60c0-19.687-12.713-36.45-30.357-42.541zm-225.643-182.459c103.003 0 187.702 79.458 195.426 180h-48.478c-27.356-53.382-82.956-90-146.948-90s-119.592 36.618-146.948 90h-48.478c7.724-100.542 92.423-180 195.426-180zm-165 300h-46c-8.271 0-15-6.729-15-15v-60c0-8.271 6.729-15 15-15h46zm165 90c-74.439 0-135-60.561-135-135s60.561-135 135-135 135 60.561 135 135-60.561 135-135 135zm226-105c0 8.271-6.729 15-15 15h-46v-90h46c8.271 0 15 6.729 15 15z" />
                                        </g>
                                    </svg>
                                </div>
                                </NavLink>
                                <div className="heading">
                                    <NavLink to="/how-to-apply"  onClick={() => {this.scrollToTop();}}>How to Apply</NavLink>
                                </div>
                            </Col>
                            <Col
                                className="col-section-d"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                                data-aos-delay="200"
                            >
                                <NavLink to="/student-support-services"  onClick={() => {this.scrollToTop();}}>
                                    <div className="img">
                                    <svg
                                        fill="white"
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                    >
                                        <g>
                                            <g>
                                                <path
                                                    d="M467,0H45C20.355,0,0.273,19.914,0.003,44.496C0.001,44.592,0,44.69,0,44.788V347c0,24.618,19.869,44.682,44.413,44.996
                                                c0.113,0.002,0.227,0.004,0.34,0.004h131.036l-22.5,90H105c-8.284,0-15,6.716-15,15s6.716,15,15,15c7.746,0,292.313,0,300,0
                                                c8.284,0,15-6.716,15-15s-6.716-15-15-15h-48.289l-22.5-90H467c24.754,0,44.904-20.089,45-44.821c0-0.035,0-0.069,0-0.103V45
                                                C512,20.187,491.813,0,467,0z M184.212,482l22.5-90h96.576l22.5,90H184.212z M482,347c0,8.271-6.729,15-15,15H315H195H45
                                                c-8.271,0-15-6.729-15-15v-15h452V347z M482,302H30V45c0-8.271,6.729-15,15-15h422c8.271,0,15,6.729,15,15V302z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M195,120h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S203.284,120,195,120z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M195,180h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S203.284,180,195,180z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M345,180h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S353.284,180,345,180z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M345,120h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S353.284,120,345,120z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M375,60h-90c-11.517,0-22.032,4.354-30,11.495C247.032,64.354,236.517,60,225,60h-90c-24.813,0-45,20.187-45,45v120
                                                c0,24.813,20.187,45,45,45c10.547,0,229.475,0,240,0c24.813,0,45-20.187,45-45V105C420,80.187,399.813,60,375,60z M240,240H135
                                                c-8.271,0-15-6.729-15-15V105c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15V240z M390,225c0,8.271-6.729,15-15,15H270V105
                                                c0-8.271,6.729-15,15-15h90c8.271,0,15,6.729,15,15V225z"
                                                />
                                            </g>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                    </svg>
                                </div>
                                </NavLink>
                                <div className="heading">
                                    <NavLink to="/student-support-services" >Our Support Services</NavLink>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default KitchenMngHelp;
