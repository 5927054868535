import React, { Component } from "react";
import arrowright from "../../../assets/images/course-deploma/course-icons/arrow-banner.png";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

class DiplomaBanner extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }
    render() {
        return (
            <div className="DiplomaBanner-wrapper">
                <div className="main container">
                    <div className="head-section">
                        <div
                            className="heading-top"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Diploma of Leadership
                        </div>
                        <div
                            className="heading-bottom"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="200"
                        >
                            and Management
                        </div>
                    </div>
                </div>
                <div className="page-list-wrapper">
                    <div className="lists container">
                        <div
                            className="list-items li-home"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="400"
                        >
                            <NavLink to="/">Home</NavLink>
                            <img src={arrowright} alt="right arrow home" />
                        </div>
                        <div
                            className="list-items li-co"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="200"
                        >
                            <a href="/courses">
                                Courses
                            </a>
                            <img src={arrowright} alt="right arrow course" />
                        </div>
                        <div
                            className="list-items"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            <NavLink to="/diploma-of-leadership-and-management">
                                Diploma of Leadership and Management
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DiplomaBanner;
