import React, { Component } from 'react';

class Map extends Component {
    render() {
        return (
            <div className="map-wrapper">
                <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.976213136014!2d144.9586077152075!3d-37.814026141831235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d948c84bd5b%3A0x1de06e58656c3d69!2sWattle%20College!5e0!3m2!1sen!2s!4v1625561603602!5m2!1sen!2s" width="100%" height="600px" border="0" style="border:0;" style={{ border: '0px' }} aria-hidden="false"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.976213136014!2d144.9586077152075!3d-37.814026141831235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d948c84bd5b%3A0x1de06e58656c3d69!2sWattle%20College!5e0!3m2!1sen!2s!4v1625561603602!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                </div>
            </div>
        );
    }
}

export default Map;