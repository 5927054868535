import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./components/Homepage";
import DiplomaPage from "./components/Diploma-of-Leadership-and-Management";
import CertIvPage from "./components/Cert-iv-page";
import WhyWattlePage from "./components/Why-Wattle-page";
import AboutUsPage from "./components/Aboutuspage";
import ContactPage from "./components/ContactPage";
import StudentLoginPage from "./components/student&staff-login-page";
import HowToApply from "./components/How-to-apply";
import PrivacyPolicy from "./components/Policy&proccedure";
import ApplyNowPage from "./components/Apply-now-page";
import LibraryPage from "./components/library-page";
import StudentSupportPage from "./components/Student-support-page";
import CollegeProfilePage from "./components/CollegeProfilePage";
import PrivacyPolicyPage from "./components/Privacy-policy page";
import LMSPage from "./components/LMS-page";
import EducationAgentsPage from "./components/EducationAgentsPage";
import AcademicProfilePage from "./components/Academic-profiles-page";
import PoliciesAndFormsPage from "./components/Policies-and-forms-page";
import BechalorOfBussinessPage from "./components/Bachelor-of-bussiness-page";
import GovernanceProfilePage from "./components/Governance-Prfile-page";
import AdmissionsHEPage from "./components/Admissions-HE-page";
import RPLAndCreditTransfer from "./components/RPLAndCreditTransfer";
import useFetch from "./UseFetch";
import AllPost from "./components/LatestNews/allPost";
import PostDetail from "./components/LatestNews/postDetail";
import AdvancedDiploma from "./components/Advanced-Diploma";
import CertthreePage from "./components/Certificate-three-page";
import HospitalityPage from "./components/Hospitality-managment-page";
import KitchenMngPage from "./components/Kitchen-managment-page";
import Courses from "./components/Coursespage";
import generalEng from "./components/general-english-page";

function App() {
  const { data, loading, error } = useFetch(
    "https://wattle-admin.herokuapp.com/api/posts"
  );
  const [detailId, setDetailId] = useState(null);

  const handlePostDetail = (item) => {
    setDetailId(item);
  };


  return (
    <Router>
      {/* {loading && <div className="loading">loading...</div>} */}
      <div className="main-wrapper-app">
        <Header />

        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <HomePage data={data} handlePostDetail={handlePostDetail} />
            )}
          />
          <Route
            exact
            path="/diploma-of-leadership-and-management"
            component={DiplomaPage}
          />
          <Route
            exact
            path="/advanced-diploma-of-leadership-and-management"
            component={AdvancedDiploma}
          />
          <Route exact path="/cert-iv-in-business" component={CertIvPage} />
          <Route
            exact
            path="/cert-iii-in-commercial-cookery"
            component={CertthreePage}
          />
          <Route
            exact
            path="/hospitality-managment"
            component={HospitalityPage}
          />
          <Route
            exact
            path="/cert-iv-in-kitchen-managment"
            component={KitchenMngPage}
          />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/general-english" component={generalEng} />
          <Route exact path="/why-wattle-college" component={WhyWattlePage} />
          {/* <Route exact path="/about-us" component={AboutUsPage} /> */}
          <Route exact path="/contact-us" component={ContactPage} />
          <Route
            exact
            path="/student-and-staff-login"
            component={StudentLoginPage}
          />
          <Route exact path="/how-to-apply" component={HowToApply} />
          <Route
            exact
            path="/policies-and-procedures"
            component={PrivacyPolicy}
          />
          <Route
            exact
            path="/rpl-and-credit-transfer"
            component={RPLAndCreditTransfer}
          />
          <Route exact path="/apply-now-page" component={ApplyNowPage} />
          <Route exact path="/library" component={LibraryPage} />
          <Route
            exact
            path="/student-support-services"
            component={StudentSupportPage}
          />
          <Route exact path="/college-profile" component={CollegeProfilePage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route exact path="/lms-elibrary" component={LMSPage} />
          <Route
            exact
            path="/education-agents"
            component={EducationAgentsPage}
          />
          <Route
            exact
            path="/academic-profile"
            component={AcademicProfilePage}
          />
          <Route
            exact
            path="/policies-and-forms"
            component={PoliciesAndFormsPage}
          />
          <Route
            exact
            path="/bachelor-of-business"
            component={BechalorOfBussinessPage}
          />
          <Route
            exact
            path="/governance-profile"
            component={GovernanceProfilePage}
          />
          <Route exact path="/admissions-he" component={AdmissionsHEPage} />
          <Route
            exact
            path="/all-posts"
            render={() => (
              <AllPost postList={data} handlePostDetail={handlePostDetail} />
            )}
          />
          {detailId && detailId["@id"] && (
            <Route
              exact
              path={"/post-detail" + detailId["@id"]}
              render={() => <PostDetail postDetail={detailId} />}
            />
          )}
          {/* <Route exact path="/courses" component={LearnMore} /> */}
          <Redirect to="/" />
        </Switch>

        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
