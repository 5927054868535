import React, { Component } from 'react';
import Map from '../map';
import WhyWattleBanner from '../Why-wattle-componenets/why-wattle-banner';
import WhyWattleOverview from '../Why-wattle-componenets/why-wattle-overview';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class WhyWattlePage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="why-wattle-page">
                <SideButtons />
                <WhyWattleBanner />
                <WhyWattleOverview />
                <Map />
                <Footer />
            </div>
        );
    }
}

export default WhyWattlePage;