import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/SVG/Logo-header.svg";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import pdf20 from "../../../assets/Policies/Wattle Compliance Report.pdf";
import pdf1 from "../../../assets/Policies/GovernanceStructure.pdf";
import pdf2 from "../../../assets/Policies/WattleOrgChart.pdf";

class CollegeProfileContent extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="college-profile-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div
                            className="para-full para-less"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            Wattle College, named after the Golden Wattle, known for its resilience and versatility, is established to provide high quality education to both local and international students.

                        </div>
                        <div
                            className="para-full"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            We are a comprehensive, learner-centred institution which strives to become the standard of excellence for fostering intellect, creativity, and character in an active, student-centred learning community.

                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Our Mission
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            To offer transformative education through engaged learning and participation.
                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Our Vision
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="100"
                        >
                            To provide premium education and training that produces capable, thoughtful and ethical citizens who contribute to community with their knowledge, engagement and skills in enriching and transforming lives.

                        </div>

                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Our Values
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Wattle College's values are Resilience, Versatility, and Reflection. Our values enable us to produce work ready graduates helping to change the world for the better.
                        </div>
                        <div
                            className="heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Compliance Report
                        </div>
                        <div
                            className="para-first"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            Wattle College is a Registered Training Organisation, RTO number 45676 and CRICOS 03905A. The national regulator for Australia's vocational education and training (VET) sector is ASQA (Australian Skills Quality Authority).
                            The most recent compliance report can be viewed  <a href={pdf20} target="_blank">here</a>.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CollegeProfileContent;
