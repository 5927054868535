import React, { Component } from 'react';
import Footer from '../Footer';
import LibraryBanner from '../librarycomponents/Library-banner';
import LibraryHelp from '../librarycomponents/Library-help';
import LibraryLoginSection from '../librarycomponents/Library-Login-section';
import LibrarySupport from '../librarycomponents/Library-support';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';


class LibraryPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="library-page">
                <SideButtons />
                <LibraryBanner />
                <LibraryLoginSection />
                <LibrarySupport />
                <LibraryHelp />
                <Footer />
            </div>
        );
    }
}

export default LibraryPage;