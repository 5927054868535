import React, { Component } from 'react';
import HowToApplyPageBanner from '../Apply-now-page-components/How-to-apply-Page-banner';
import HowToApplyPageContent from '../Apply-now-page-components/how-to-apply-content';
import Footer from '../Footer/index';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class ApplyNowPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="HowToApplyPage-wrapper">
                <SideButtons />
                <HowToApplyPageBanner />
                <HowToApplyPageContent />
                <Footer />
            </div>
        );
    }
}

export default ApplyNowPage;