import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import droparrowupb from "../../../assets/images/arrow-down.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class CertIvDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
    this.state = { addClass2: false };
    this.state = { addClass3: false };
    this.state = { addClass4: false };
    this.state = { addClass5: false };
    this.state = { addClass6: false };
    this.state = { addClass7: false };

    this.state = {
      open: true,
    };
    this.state = {
      tOpen: false,
    };
    this.state = {
      Openfour: false,
    };
    this.state = {
      Openfive: false,
    };
    this.state = {
      OpenSix: false,
    };
    this.state = {
      OpenSeven: false,
    };

    this.state = {
      setBlue: false,
    };
    this.state = {
      setBlue2: true,
    };
    this.state = {
      setBlue3: false,
    };
    this.state = {
      setBlue4: false,
    };
    this.state = {
      setBlue5: false,
    };
    this.state = {
      setBlue6: false,
    };
    this.state = {
      setBlue7: false,
    };

    this.state = {
      setOpen: true,
    };
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
    this.setState({ setOpen: !this.state.setOpen });
    this.setState({ setBlue2: !this.state.setBlue2 });
  }
  togglesHandle() {
    this.setState({ addClass2: !this.state.addClass2 });
    this.setState({ open: !this.state.open });
    this.setState({ setBlue3: !this.state.setBlue3 });
  }
  togglesHandle2() {
    this.setState({ addClass3: !this.state.addClass3 });
    this.setState({ tOpen: !this.state.tOpen });
    this.setState({ setBlue: !this.state.setBlue });
  }
  togglesHandle4() {
    this.setState({ addClass4: !this.state.addClass4 });
    this.setState({ Openfour: !this.state.Openfour });
    this.setState({ setBlue4: !this.state.setBlue4 });
  }
  togglesHandle5() {
    this.setState({ addClass5: !this.state.addClass5 });
    this.setState({ Openfive: !this.state.Openfive });
    this.setState({ setBlue5: !this.state.setBlue5 });
  }
  togglesHandle6() {
    this.setState({ addClass6: !this.state.addClass6 });
    this.setState({ OpenSix: !this.state.OpenSix });
    this.setState({ setBlue6: !this.state.setBlue6 });
  }
  togglesHandle7() {
    this.setState({ addClass7: !this.state.addClass7 });
    this.setState({ OpenSeven: !this.state.OpenSeven });
    this.setState({ setBlue7: !this.state.setBlue7 });
  }
  componentDidMount() {
    AOS.init({
      once: true,
      // disable: function() {
      //     var maxWidth = 800;
      //     return window.innerWidth < maxWidth;
      // }
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    let boxClass = ["box1"];
    let boxClass3 = ["box"];
    let boxClass2 = ["box"];
    let boxClass4 = ["box"];
    let boxClass5 = ["box"];
    let boxClass6 = ["box"];
    let boxClass7 = ["box"];

    let blueClass = ["blue"];
    let blueClass2 = ["blue"];
    let blueClass3 = ["blue"];
    let blueClass4 = ["blue"];
    let blueClass5 = ["blue"];
    let blueClass6 = ["blue"];
    let blueClass7 = ["blue"];

    if (this.state.addClass) {
      boxClass.push("back");
    } else if (this.state.addClass) {
      boxClass.push("up");
    } else {
      blueClass2.push("blues");
    }

    if (this.state.addClass2) {
      boxClass2.push("back");
      blueClass3.push("blues");
    } else if (this.state.addClass2) {
      boxClass2.push("up");
      blueClass3.push("white");
    }

    if (this.state.addClass3) {
      boxClass3.push("back");
      blueClass.push("blues");
    } else if (this.state.addClass3) {
      boxClass3.push("up");
      blueClass.push("white");
    }

    if (this.state.addClass4) {
      boxClass4.push("back");
      blueClass4.push("blues");
    } else if (this.state.addClass4) {
      boxClass4.push("up");
      blueClass4.push("white");
    }

    if (this.state.addClass5) {
      boxClass5.push("back");
      blueClass5.push("blues");
    } else if (this.state.addClass5) {
      boxClass5.push("up");
      blueClass5.push("white");
    }

    if (this.state.addClass6) {
      boxClass6.push("back");
      blueClass6.push("blues");
    } else if (this.state.addClass6) {
      boxClass6.push("up");
      blueClass6.push("white");
    }

    if (this.state.addClass7) {
      boxClass7.push("back");
      blueClass7.push("blues");
    } else if (this.state.addClass7) {
      boxClass7.push("up");
      blueClass7.push("white");
    }
    return (
      <div className="cert-iv-detail-wrapper">
        <div className="container">
          <div
            className="heading-detail"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Course Details
          </div>
          <div className="program-structure">
            <Accordion defaultActiveKey="0">
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  id="first-header"
                  as={Button}
                  // className={boxClass.join(" ")}
                  className={blueClass2.join(" ")}
                  onClick={this.toggle.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass ? "" : ""}
                  <div className="card-head">Careers</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.setOpen}>
                  <Card.Body>
                    <div className="para">
                      The following employment pathways are available to
                      students who finish this course.
                    </div>
                    <ul>
                      <li>administrator</li>
                      <li>project officer</li>
                      <li>accounts clerk</li>
                      <li>customer service advisor</li>
                      <li>e-business practitioner</li>
                      <li>legal receptionist</li>
                      <li>medical receptionist</li>
                      <li>office administration assistant</li>
                      <li>student services officer</li>
                      <li>word processing operator</li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  as={Button}
                  className={boxClass4.join(" ")}
                  className={blueClass4.join(" ")}
                  onClick={this.togglesHandle4.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass4 ? "" : ""}
                  <div className="card-head">Future Pathways</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass4.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass4 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.Openfour}>
                  <Card.Body>
                    <div className="para">
                      Students completing this course can go for future pathways
                      for further study
                    </div>
                    <ul>
                      <li>BSB50420 Diploma of Leadership and Management</li>
                      <li>BSB50120 Diploma of Business</li>
                      <li>Other higher education qualifications</li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  as={Button}
                  className={boxClass5.join(" ")}
                  className={blueClass5.join(" ")}
                  onClick={this.togglesHandle5.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass ? "" : ""}
                  <div className="card-head">Intake Dates</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass5.join(" ")}
                    onClick={this.togglesHandle5.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass5 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.Openfive}>
                  <Card.Body>
                  <div className="dates-new">
                      <div  className="old-date">
                        <h6>2022</h6>
                        <ul>
                          <li>17/01/22</li>
                          <li>14/02/22</li>
                          <li>07/03/22</li>
                          <li>11/04/22</li>
                          <li>09/05/22</li>
                          <li>30/05/22</li>
                          <li>04/07/22</li>
                          <li>01/08/22</li>
                          <li>26/09/22</li>
                          <li>24/10/22</li>
                        </ul>
                      </div>
                      <div className="latest-date">
                        <h6>2023</h6>
                        <ul>
                          <li>16/01/23</li>
                          <li>13/02/23</li>
                          <li>10/04/23</li>
                          <li>08/05/23</li>
                          <li>03/07/23</li>
                          <li>31/07/23</li>
                          <li>25/09/23</li>
                          <li>23/10/23</li>
                        </ul>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass2.join(" ")}
                  className={blueClass3.join(" ")}
                  onClick={this.togglesHandle.bind(this)}
                  variant="link"
                  eventKey="2"
                >
                  {this.state.addClass2 ? "" : ""}
                  <div className="card-head">Entry Requirements</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass2.join(" ")}
                    onClick={this.togglesHandle.bind(this)}
                    variant="link"
                    eventKey="2"
                  >
                    {this.state.addClass2 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="second drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.open} eventKey="2">
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Intended students must satisfy the following:
                    </div>
                    <ul>
                      <li>
                        Have completed at least the equivalent of Year 12.
                      </li>
                      <li>Minimum age of 18 years.</li>
                      <li>
                        For International students, English proficiency levels
                        of IELTS 5.5 or equivalent.
                      </li>
                    </ul>
                    <div className="entry-para-top">
                      In addition, all students will be required to undertake a
                      pre-training review including an LLN assessment, in order
                      to assess their existing skills, knowledge and ability to
                      undertake the course for pathway or their capability of a
                      business administrator across all business functions.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <div
                className="heading-detail second-heading"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Admission Pathways
              </div>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass7.join(" ")}
                  className={blueClass7.join(" ")}
                  onClick={this.togglesHandle7.bind(this)}
                  variant="link"
                >
                  {this.state.addClass7 ? "" : ""}
                  <div className="card-head">Direct Enrolment</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass7.join(" ")}
                    onClick={this.togglesHandle7.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass7 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="third drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.OpenSeven}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Please refer to our{" "}
                      <NavLink
                        to="/how-to-apply"
                        onClick={() => {
                          this.scrollToTop();
                        }}
                      >
                        how to apply
                      </NavLink>{" "}
                      page
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass3.join(" ")}
                  className={blueClass.join(" ")}
                  onClick={this.togglesHandle2.bind(this)}
                  variant="link"
                >
                  {this.state.addClass3 ? "" : ""}
                  <div className="card-head">Recognition of Prior Learning</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass3.join(" ")}
                    onClick={this.togglesHandle2.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass3 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="third drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Wattle College provides Recognition of Prior Learning
                      (RPL) where your work experience or prior qualifications
                      of an advanced and related nature have been undertaken.
                      For more information, please refer to the{" "}
                      <NavLink
                        to="/rpl-and-credit-transfer"
                        onClick={() => {
                          this.scrollToTop();
                        }}
                      >
                        RPL/Credit Transfer
                      </NavLink>{" "}
                      page.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass6.join(" ")}
                  className={blueClass6.join(" ")}
                  onClick={this.togglesHandle6.bind(this)}
                  variant="link"
                >
                  {this.state.addClass6 ? "" : ""}
                  <div className="card-head">Credit Transfer</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass6.join(" ")}
                    onClick={this.togglesHandle6.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass6 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="third drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.OpenSix}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      At Wattle College, you can demonstrate the study that you
                      have previously undertaken and gain credit in another
                      course. Please refer to{" "}
                      <NavLink
                        to="/rpl-and-credit-transfer"
                        onClick={() => {
                          this.scrollToTop();
                        }}
                      >
                        RPL/Credit Transfer
                      </NavLink>{" "}
                      page for more information.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="entry-requirements"></div>
          <div className="fee-payments"></div>
        </div>
      </div>
    );
  }
}

export default CertIvDetail;
