import React, { Component } from "react";
import { CardDeck, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "aos/dist/aos.css";

class Services extends Component {
    // componentDidMount(){
    //     AOS.init({
    //         duration : 800,
    //         once: true,
    //         // disable: function() {
    //         //     var maxWidth = 800;
    //         //     return window.innerWidth < maxWidth;
    //         // }
    //     })
    // }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    render() {
        return (
            <div className="services-wrapper">
                <div className="main container">
                    <CardDeck>
                        <Card data-aos="flip-left" data-aos-duration="2000">
                            <NavLink
                                to="/how-to-apply"
                                onClick={() => {
                                    this.scrollToTop();
                                }}
                            >
                                <Card.ImgOverlay>
                                    <Card.Text>How To <br /> Apply</Card.Text>
                                </Card.ImgOverlay>
                            </NavLink>
                        </Card>
                        <Card
                            className="card-special"
                            data-aos="flip-right"
                            data-aos-duration="2000"
                        >
                            <NavLink
                                to="/rpl-and-credit-transfer"
                                onClick={() => {
                                    this.scrollToTop();
                                }}
                            >
                                <Card.ImgOverlay>
                                    <Card.Text>RPL/Credit <br />Transfer</Card.Text>
                                </Card.ImgOverlay>
                            </NavLink>
                        </Card>
                        <Card
                            className="card-house"
                            data-aos="flip-left"
                            data-aos-duration="2000"
                        >
                            <NavLink
                                to="/student-support-services"
                                onClick={() => {
                                    this.scrollToTop();
                                }}
                            >
                                <Card.ImgOverlay>
                                    <Card.Text>Support <br />services</Card.Text>
                                </Card.ImgOverlay>
                            </NavLink>
                        </Card>
                        <Card
                            className="card-student"
                            data-aos="flip-right"
                            data-aos-duration="2000"
                        >
                            <NavLink
                                to="/college-profile"
                                onClick={() => {
                                    this.scrollToTop();
                                }}
                            >
                                <Card.ImgOverlay>
                                    <Card.Text>College <br /> Profile</Card.Text>
                                </Card.ImgOverlay>
                            </NavLink>
                        </Card>
                    </CardDeck>
                </div>
            </div>
        );
    }
}

export default Services;
