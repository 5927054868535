import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

class HospitalityCourseEnquiry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            checkbox: "",
            name: "",
            Phone: "",
            Email: "",
            errors: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = (event) => {
            this.setState({
                checkbox: event.target.checked,
            });
        };

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }

    hasError(key) {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleInputChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        var obj = {};
        obj[key] = value;
        this.setState(obj);
    }

    handleSubmit(event) {
        event.preventDefault();

        //VALIDATE
        var errors = [];

        //firstname
        if (this.state.firstname === "") {
            errors.push("firstname");
        }

        // lastname
        if (this.state.lastname === "") {
            errors.push("lastname");
        }

        // checkbox

        if (this.state.checkbox === "") {
            errors.push("checkbox");
        } else if (this.state.checkbox === false) {
            errors.push("checkbox");
        }
        // console.log(this.state.checkbox);

        // Name
        if (this.state.name === "") {
            errors.push("name");
        }

        // Phone
        var pho = /^([0-9])+$/;
        if (this.state.Phone === "") {
            errors.push("Phone");
        } else if (!this.state.Phone.match(pho)) {
            errors.push("Phone");
        }

        //email
        const expression = /\S+@\S+/;
        var validEmail = expression.test(String(this.state.Email).toLowerCase());

        if (!validEmail) {
            errors.push("Email");
        }

        this.setState({
            errors: errors,
        });

        if (errors.length > 1) {
            return false;
        } else {
            alert("everything good. submit form!");
            console.log(this.state);
        }
    }

    render() {
        const { checkbox } = this.state;
        return (
            <div className="hospitality-enquiry-wrapper">
                <div className="main container">
                    <div
                        className="heading"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                    >
                        Make a Course Enquiry
                    </div>
                    <div
                        className="heading-para"
                        data-aos="fade-right"
                        data-aos-duration="1800"
                    >
                        By checking this box, you agree to receive marketing and promotional materials from Wattle College.
                    </div>
                    <form className="form-section" onSubmit={this.handleSubmit}>
                        <Row className="input-rows">
                            <Col
                                md={6}
                                className="form_fields"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                <label>
                                    First Name<span>*</span>
                                </label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className={
                                        this.hasError("firstname")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="firstname"
                                    value={this.state.firstname}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("firstname") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a name
                                </div>
                            </Col>

                            <Col
                                md={6}
                                className="form_fields-second"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <label>
                                    Last Name<span>*</span>
                                </label>
                                <input
                                    autoComplete="off"
                                    className={
                                        this.hasError("lastname")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="lastname"
                                    value={this.state.lastname}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("lastname") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a name
                                </div>
                            </Col>

                            <Col
                                md={6}
                                className="form_fields"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                <label>
                                    Email<span>*</span>
                                </label>
                                <input
                                    autoComplete="off"
                                    className={
                                        this.hasError("Email")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="Email"
                                    value={this.state.Email}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("Email") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter your Email
                                </div>
                            </Col>

                            <Col
                                md={6}
                                className="form_fields-second"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <label>
                                    Phone<span>*</span>
                                </label>
                                <input
                                    autoComplete="off"
                                    className={
                                        this.hasError("Phone")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="Phone"
                                    value={this.state.Phone}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("Phone") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter your phone number
                                </div>
                            </Col>
                        </Row>

                        <div
                            className="message"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                        >
                            By checking this box, you agree to receive marketing and promotional materials from Wattle College.
                        </div>

                        <div className="message-mobile">
                            <div
                                className="tick-mobile"
                                data-aos="fade-right"
                                data-aos-duration="1800"
                            >
                                <input
                                    type="checkbox"
                                    autoComplete="off"
                                    className={
                                        this.hasError("checkbox")
                                            ? "form-check-input is-invalid"
                                            : "form-check-input"
                                    }
                                    name="checkbox"
                                    value={this.state.checkbox}
                                    onChange={this.handleCheckChange}
                                ></input>
                                <span>I agree to receive emails from the Wattle College</span>
                                <br />
                            </div>
                            <div
                                className={
                                    this.hasError("checkbox") ? "inline-errormsg" : "hidden"
                                }
                                id="tick"
                            >
                                Please fill this check
                            </div>
                        </div>

                        <button
                            type="submit"
                            value="Submit"
                            data-aos="fade-up-right"
                            data-aos-duration="1500"
                        >
                            Enquire Now
                        </button>
                    </form>
                </div>
                {/* <div className="heading-one" data-aos="fade-right" data-aos-duration="1500">Hear From our Students</div> */}
            </div>
        );
    }
}

export default HospitalityCourseEnquiry;
