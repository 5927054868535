import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import 'aos/dist/aos.css';

class SideButtons extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="buttons-web">
                <div id="butt-wrapper">
                    <div id="main-butt" data-aos="fade-up" data-aos-duration="2000">
                        <NavLink to="/apply-now-page" onClick={ () => { this.scrollToTop(); }}>
                            <button>Apply Now</button>
                        </NavLink>
                        <NavLink to="/contact-us" className="desktop-contact" onClick={ () => { this.scrollToTop(); }}>
                            <button>Enquire Now</button>
                        </NavLink>
                        <NavLink to="/contact-us" className="mobile-contact" onClick={ () => { this.scrollToTop(); }}>
                            <button>Contact Us</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default SideButtons;