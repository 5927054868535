import React, { Component } from 'react';
import arrowright from '../../../assets/images/course-deploma/course-icons/arrow-banner.png';
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

class RPLAndCreditTransferBanner extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        })
    }
    render() {
        return (
            <div className="admission-Banner-wrapper">
                <div className="main container">
                    <div className="head-section">
                        <div className="heading-top" data-aos="fade-right" data-aos-duration="1500">
                            RPL and Credit Transfer
                        </div>
                    </div>
                </div>
                <div className="page-list-wrapper">
                    <div className="lists container">
                        <div className="list-items li-home" data-aos="fade-right" data-aos-duration="1300" data-aos-delay="200">
                            <NavLink to="/">
                                Home
                            </NavLink>
                            <img src={arrowright} alt="right arrow" />
                        </div>
                        <div className="list-items" data-aos="fade-right" data-aos-duration="1500">
                            <NavLink to="/rpl-and-credit-transfer">
                                RPL and Credit Transfer
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RPLAndCreditTransferBanner;
