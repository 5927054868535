import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';
import AdmissionsHEBanner from '../Admissions-HE-component/Admissions-HE-banner';
import AdmissionsHEOverview from '../Admissions-HE-component/Admissions-HE-overview';
import AdmissionsHEHelp from '../Admissions-HE-component/Admissions-HE-help';

class AdmissionsHEPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="pathways–he-wrapper">
                <SideButtons />
                <AdmissionsHEBanner />
                <AdmissionsHEOverview />
                <AdmissionsHEHelp />
                <Footer />
            </div>
        );
    }
}

export default AdmissionsHEPage;