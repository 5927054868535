import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/images/SVG/Logo-header.svg";

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Header(props) {
  const [future, toggleFutureMenu] = useState(false);
  const [course, toggleCoursesMenu] = useState(false);
  const [course2, toggleCoursesMenu2] = useState(false);
  const [course3, toggleCoursesMenu3] = useState(false);
  const [current, toggleCurrentMenu] = useState(false);
  const [about, toggleAboutMenu] = useState(false);
  const location = useLocation();

  const onMouseEnterIt = () => {
    const mediaQuery = window.matchMedia("(min-width: 991px)");
    if (mediaQuery.matches) {
      toggleFutureMenu(true);
    }
  };

  const onMouseLeaveIt = () => {
    const mediaQuery = window.matchMedia("(min-width: 991px)");
    // setTimeout(()=>{
    if (mediaQuery.matches) {
      toggleFutureMenu(false);
    }
    // },[500])
  };

  const handleClick = (isOpen, e, metadata) => {
    const mediaQuery = window.matchMedia("(max-width: 991px)");
    if (mediaQuery.matches) {
      if (metadata.source != "select") {
        toggleFutureMenu(isOpen);
      }
    }
  };

  const handleBussiness = () => {
    const mediaQuery = window.matchMedia("(max-width: 991px)");
    if (mediaQuery.matches) {
      toggleCoursesMenu(!course);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);
  return (
    <div className="header_wrapper">
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="m-2 container">
        <Navbar.Brand href="/">
          <img src={Logo} alt="logo" width="150px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown
              title="Courses"
              id="diploma"
              className="desktop-course"
              renderMenuOnMount={true}
              onMouseEnter={onMouseEnterIt}
              onMouseLeave={onMouseLeaveIt}
              // onClick={handleClick}
              show={future}
              onToggle={(isOpen, e, metadata) => handleClick(isOpen, e, metadata)}
            >
              <NavDropdown.Item>
                <NavDropdown
                  title="ELICOS"
                  id="diploma"
                  className="desktop-course"
                  renderMenuOnMount={true}
                  onMouseEnter={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu3(true);
                    }
                  }}
                  onMouseLeave={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu3(false);
                    }
                  }}
                  onClick={() => {
                    const mediaQuery = window.matchMedia("(max-width: 990px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu3(!course3);
                    }
                  }}
                  alignRight
                  show={course3}
                >
                  <NavDropdown.Item
                    href="/general-english"
                    id="course3"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/general-english"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      General English
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavDropdown
                  title="Business"
                  id="diploma"
                  className="desktop-course"
                  renderMenuOnMount={true}
                  onMouseEnter={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu(true);
                    }
                  }}
                  onMouseLeave={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu(false);
                    }
                  }}
                  onClick={() => handleBussiness()}
                  alignRight
                  show={course}
                >
                  <NavDropdown.Item
                    href="/cert-iv-in-business"
                    id="course"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/cert-iv-in-business"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Certificate IV in Business
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="/diploma-of-leadership-and-management"
                    id="course"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/diploma-of-leadership-and-management"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Diploma of Leadership and Management
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/advanced-diploma-of-leadership-and-management"
                    id="course"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/advanced-diploma-of-leadership-and-management"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Advanced Diploma of Leadership and Management
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavDropdown
                  title="Hospitality"
                  id="diploma"
                  className="desktop-course"
                  renderMenuOnMount={true}
                  onMouseEnter={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu2(true);
                    }
                  }}
                  onMouseLeave={() => {
                    const mediaQuery = window.matchMedia("(min-width: 991px)");
                    if (mediaQuery.matches) {
                      toggleCoursesMenu2(false);
                    }
                  }}
                  onClick={() => {
                    const mediaQuery = window.matchMedia("(max-width: 990px)");

                    if (mediaQuery.matches) {
                      toggleCoursesMenu2(!course2);
                    }
                  }}
                  alignRight
                  show={course2}
                >
                  <NavDropdown.Item
                    href="/cert-iii-in-commercial-cookery"
                    id="course2"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/cert-iii-in-commercial-cookery"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Certificate III in Commercial Cookery
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="/cert-iv-in-kitchen-managment"
                    id="course2"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/cert-iv-in-kitchen-managment"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Certificate IV In Kitchen Management
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/hospitality-managment"
                    id="course2"
                    onClick={() => {
                      if (toggleFutureMenu() == false) {
                        toggleFutureMenu(true);
                      }
                    }}
                  >
                    <NavLink
                      to="/hospitality-managment"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      Diploma of Hospitality Management
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Students"
              id="collasible-nav-dropdown"
              renderMenuOnMount={true}
              onMouseEnter={() => {
                const mediaQuery = window.matchMedia("(min-width: 991px)");
                if (mediaQuery.matches) {
                  toggleCurrentMenu(true);
                }
              }}
              onMouseLeave={() => {
                const mediaQuery = window.matchMedia("(min-width: 991px)");
                if (mediaQuery.matches) {
                  toggleCurrentMenu(false);
                }
              }}
              onClick={() => {
                const mediaQuery = window.matchMedia("(max-width: 991px)");
                if (mediaQuery.matches && toggleCurrentMenu() == false) {
                  toggleCurrentMenu(true);
                }
              }}
              show={current}
            >
              <NavDropdown.Item
                href="/student-and-staff-login"
                onClick={() => {
                  if (toggleCurrentMenu() == false) {
                    toggleCurrentMenu(true);
                  }
                }}
              >
                <NavLink
                  to="/student-and-staff-login"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  Student / staff login
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/policies-and-procedures"
                onClick={() => {
                  if (toggleCurrentMenu() == false) {
                    toggleCurrentMenu(true);
                  }
                }}
              >
                <NavLink
                  to="/policies-and-procedures"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  Policies and Procedures
                </NavLink>
              </NavDropdown.Item>

              <NavDropdown.Item
                href="/student-support-services"
                onClick={() => {
                  if (toggleCurrentMenu() == false) {
                    toggleCurrentMenu(true);
                  }
                }}
              >
                <NavLink
                  to="/student-support-services"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  Student Support Services
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/rpl-and-credit-transfer"
                onClick={() => {
                  if (toggleFutureMenu() == false) {
                    toggleFutureMenu(true);
                  }
                }}
              >
                <NavLink
                  to="/rpl-and-credit-transfer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  RPL and Credit Transfer
                </NavLink>
              </NavDropdown.Item>

              <NavDropdown.Item
                href="/how-to-apply"
                onClick={() => {
                  if (toggleFutureMenu() == false) {
                    toggleFutureMenu(true);
                  }
                }}
              >
                <NavLink
                  to="/how-to-apply"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  How to Apply
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink
              to="/all-posts"
              className="news-link"
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="news_nav">
                <Nav.Link
                  href="/all-posts"
                  className="all-post-link"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  News and Events
                </Nav.Link>
              </div>
            </NavLink>

            <NavLink to="/about-us">
              <NavDropdown
                title="About us"
                renderMenuOnMount={true}
                onMouseEnter={() => {
                  const mediaQuery = window.matchMedia("(min-width: 991px)");
                  if (mediaQuery.matches) {
                    toggleAboutMenu(true);
                  }
                }}
                onMouseLeave={() => {
                  const mediaQuery = window.matchMedia("(min-width: 991px)");
                  if (mediaQuery.matches) {
                    toggleAboutMenu(false);
                  }
                }}
                onClick={() => {
                  const mediaQuery = window.matchMedia("(max-width: 991px)");
                  if (mediaQuery.matches && toggleAboutMenu() == false) {
                    toggleAboutMenu(true);
                  }
                }}
                AlignRight
                show={about}
              >
                <NavDropdown.Item
                  href="/why-wattle-college"
                  onClick={() => {
                    if (toggleAboutMenu() == false) {
                      toggleAboutMenu(true);
                    }
                  }}
                >
                  <NavLink
                    to="/why-wattle-college"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    Why Wattle College
                  </NavLink>
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="/college-profile"
                  onClick={() => {
                    if (toggleAboutMenu() == false) {
                      toggleAboutMenu(true);
                    }
                  }}
                >
                  <NavLink
                    to="/college-profile"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    College Profile
                  </NavLink>
                </NavDropdown.Item>

                <NavDropdown.Item
                  href="/education-agents"
                  onClick={() => {
                    if (toggleAboutMenu() == false) {
                      toggleAboutMenu(true);
                    }
                  }}
                >
                  <NavLink
                    to="/education-agents"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    Education Agents
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
            </NavLink>
          </Nav>
          <NavLink to="/contact-us">
            <div className="contact_nav">
              <Nav.Link
                href="/contact-us"
                onClick={() => {
                  scrollToTop();
                }}
                eventKey={2}
              >
                Contact Us
              </Nav.Link>
            </div>
          </NavLink>

          <NavLink to="/apply-now-page">
            <div className="contact_nav second">
              <Nav.Link
                href="/apply-now-page"
                onClick={() => {
                  scrollToTop();
                }}
                eventKey={3}
              >
                Apply Now
              </Nav.Link>
            </div>
          </NavLink>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
