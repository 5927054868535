import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import img1 from "../../../assets/images/governance-img/1.jpg";
import img2 from "../../../assets/images/governance-img/2.jpg";
import img3 from "../../../assets/images/governance-img/3.jpg";
import img4 from "../../../assets/images/governance-img/4.jpg";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class GovernanceProfileContent extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        return (
            <div className="governance-profile-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div
                            className="academic-heading"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            Governance profile
                        </div>
                        <Row className="wallet-section-f">
                            <Col md="6" xs="12"
                                className="col-sections-e"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img1} alt="person 1" />
                                </div>
                                <div className="heading">Amelia Beasley</div>
                                <div className="para">Amelia has worked in a number of HR Generalist and management roles across a number of different industries, finding her passion and niche within the Healthcare Industry. As an experienced professional Amelia has guided organisations HR functions through a succession of acquisition, growth, development, and agility-based change. Well-versed in employee relations, succession planning, management consulting, and strategy. Amelia also brings to the table the ability to develop innovative solutions to agile operating environments. Possessing a high level of skill in Stakeholder Management, Strategic Planning, HR Management, ISO Standards, Compliance Management, and Industrial Relations.
                                </div>
                            </Col>

                            <Col md="6" xs="12"
                                className="col-section-b"
                                data-aos="flip-right"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img2} alt="person 2" />
                                </div>
                                <div className="heading"> Rebecca Windsor
                                </div>
                                <div className="para">Rebecca has over 20 years’ experience spanning financial services, governance, and private health insurance sectors in a range of management, technical and regulatory roles. Her more recent experience as General Manager – Health Management for Australian Unity involves oversight of health funding and provider contracting, health management programs, claims management and policy & regulatory reform. Rebecca is passionate about working collaboratively with government and industry bodies to influence reform that improves affordability and health outcomes for everyday Australians and more importantly positively contributes to the sustainability of the health sector.
                                    Rebecca’s qualifications include a Bachelor of Commerce and Diploma of Financial Planning
                                </div>
                            </Col>

                            <Col md="6" xs="12"
                                className="col-sections-e"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img3} alt="person 3" />
                                </div>
                                <div className="heading">Anthony Fighera </div>
                                <div className="para">Anthony is a senior professional with over 30 years’ experience covering Finance, Business Development, IT project Management and Health. A Chartered Accountant with an Exec MBA, Anthony has worked with some of Australia’s biggest companies in the area of business process improvement and change management. Iconic companies including ANZ, Telstra, Toyota, Australia Post and General Motors Holden. Anthony began in Healthcare 10 years ago as his family built a significant Distribution business within the healthcare marketplace.  From there he started to specialise in Assistive Healthcare technologies such as telehealth, sensors and wearable technologies that promoted independent living.  He spent a significant amount of time with RDNS (Royal District Nursing Service) as Project Director for their Telehealth Deployment Programme.
                                </div>
                            </Col>

                            <Col md="6" xs="12"
                                className="col-section-b"
                                data-aos="fade-up-right"
                                data-aos-delay="500"
                                data-aos-duration="1500"
                            >
                                <div className="img">
                                    <img src={img4} alt="person 4" />
                                </div>
                                <div className="heading"> Dan Casey
                                </div>
                                <div className="para">Dan Casey is an experienced and qualified learning and organisation development professional with experience in all facets of learning and development. He has worked in public and private sectors, and with large and small organisations. He is particularly interested in organisational learning and systems theory and understanding the dynamics within organisations. He is also an accredited Hindsight Specialist which allows him to provide organisations with advice on what they should have done.
                                </div>
                            </Col>

                        </Row>

                    </div>
                </div>
            </div>
        );
    }
}

export default GovernanceProfileContent;
