import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import PrivacyPolicyBanner from '../Privacy-policy-components/privacy-policy-banner';
import PrivacyPolicyContent from '../Privacy-policy-components/privacy-policy-content';
import SideButtons from '../SideButtons';

class PrivacyPolicyPage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="privacy-policy-wrapper">
                <SideButtons />
                <PrivacyPolicyBanner />
                <PrivacyPolicyContent />
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicyPage;