import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import { Accordion, Card, Button } from "react-bootstrap";
import droparrowupb from "../../../assets/images/arrow-down.png";

import aboutback from "../../../assets/images/How-to-apply/apply-img.png";
import pdf13 from "../../../assets/Policies/Application Form 2022.pdf";
import handbook from '../../../assets/Policies/Student-Handbook-VET.pdf';
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

class AdmissionsHEOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass2: false };
    this.state = { addClass3: false };
    this.state = { addClass4: false };
    this.state = { addClass5: false };
    this.state = { addClass6: false };
    this.state = { addClass7: false };
    this.state = { addClass8: false };
    this.state = { addClass9: false };
    this.state = { addClass10: false };
    this.state = { addClass11: false };

    this.state = {
      tOpen: false,
      tOpen4: false,
      tOpen5: false,
      tOpen6: false,
      tOpen7: false,
      tOpen8: false,
      tOpen9: false,
      tOpen10: false,
      tOpen11: false,
    };
    this.state = {
      setBlue: false,
      setBlue4: false,
      setBlue5: false,
      setBlue6: false,
      setBlue7: false,
      setBlue8: false,
      setBlue9: false,
      setBlue10: false,
      setBlue11: false,
    };

    this.state = {
      setBlue3: true,
    };
    this.state = {
      open: true,
    };
  }
  togglesHandle() {
    this.setState({ addClass2: !this.state.addClass2 });
    this.setState({ open: !this.state.open });
    this.setState({ setBlue3: !this.state.setBlue3 });
  }
  togglesHandle2() {
    this.setState({ addClass3: !this.state.addClass3 });
    this.setState({ tOpen: !this.state.tOpen });
    this.setState({ setBlue: !this.state.setBlue });
  }
  togglesHandle3() {
    this.setState({ addClass4: !this.state.addClass4 });
    this.setState({ tOpen4: !this.state.tOpen4 });
    this.setState({ setBlue4: !this.state.setBlue4 });
  }
  togglesHandle4() {
    this.setState({ addClass5: !this.state.addClass5 });
    this.setState({ tOpen5: !this.state.tOpen5 });
    this.setState({ setBlue5: !this.state.setBlue5 });
  }
  togglesHandle5() {
    this.setState({ addClass6: !this.state.addClass6 });
    this.setState({ tOpen6: !this.state.tOpen6 });
    this.setState({ setBlue6: !this.state.setBlue6 });
  }
  togglesHandle6() {
    this.setState({ addClass7: !this.state.addClass7 });
    this.setState({ tOpen7: !this.state.tOpen7 });
    this.setState({ setBlue7: !this.state.setBlue7 });
  }
  togglesHandle7() {
    this.setState({ addClass8: !this.state.addClass8 });
    this.setState({ tOpen8: !this.state.tOpen8 });
    this.setState({ setBlue8: !this.state.setBlue8 });
  }
  togglesHandle8() {
    this.setState({ addClass9: !this.state.addClass9 });
    this.setState({ tOpen9: !this.state.tOpen9 });
    this.setState({ setBlue9: !this.state.setBlue9 });
  }
  togglesHandle9() {
    this.setState({ addClass10: !this.state.addClass10 });
    this.setState({ tOpen10: !this.state.tOpen10 });
    this.setState({ setBlue10: !this.state.setBlue10 });
  }
  togglesHandle10() {
    this.setState({ addClass11: !this.state.addClass11 });
    this.setState({ tOpen11: !this.state.tOpen11 });
    this.setState({ setBlue11: !this.state.setBlue11 });
  }
  componentDidMount() {
    AOS.init({
      once: true,
      // disable: function() {
      //     var maxWidth = 800;
      //     return window.innerWidth < maxWidth;
      // }
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    let boxClass3 = ["box"];
    let boxClass2 = ["box1"];
    let boxClass4 = ["box"];
    let boxClass5 = ["box"];
    let boxClass6 = ["box"];
    let boxClass7 = ["box"];
    let boxClass8 = ["box"];
    let boxClass9 = ["box"];
    let boxClass10 = ["box"];
    let boxClass11 = ["box"];

    let blueClass = ["blue"];
    let blueClass3 = ["blue"];
    let blueClass4 = ["blue"];
    let blueClass5 = ["blue"];
    let blueClass6 = ["blue"];
    let blueClass7 = ["blue"];
    let blueClass8 = ["blue"];
    let blueClass9 = ["blue"];
    let blueClass10 = ["blue"];
    let blueClass11 = ["blue"];

    if (this.state.addClass2) {
      boxClass2.push("back");
    } else if (this.state.addClass2) {
      boxClass2.push("up");
    } else {
      blueClass3.push("blues");
    }
    if (this.state.addClass3) {
      boxClass3.push("back");
      blueClass.push("blues");
    } else if (this.state.addClass3) {
      boxClass3.push("up");
      blueClass3.push("white");
    }
    if (this.state.addClass4) {
      boxClass4.push("back");
      blueClass4.push("blues");
    } else if (this.state.addClass4) {
      boxClass4.push("up");
      blueClass4.push("white");
    }

    if (this.state.addClass5) {
      boxClass5.push("back");
      blueClass5.push("blues");
    } else if (this.state.addClass5) {
      boxClass5.push("up");
      blueClass5.push("white");
    }

    if (this.state.addClass6) {
      boxClass6.push("back");
      blueClass6.push("blues");
    } else if (this.state.addClass6) {
      boxClass6.push("up");
      blueClass6.push("white");
    }

    if (this.state.addClass7) {
      boxClass7.push("back");
      blueClass7.push("blues");
    } else if (this.state.addClass7) {
      boxClass7.push("up");
      blueClass7.push("white");
    }
    if (this.state.addClass8) {
      boxClass8.push("back");
      blueClass8.push("blues");
    } else if (this.state.addClass8) {
      boxClass8.push("up");
      blueClass8.push("white");
    }
    if (this.state.addClass9) {
      boxClass9.push("back");
      blueClass9.push("blues");
    } else if (this.state.addClass9) {
      boxClass9.push("up");
      blueClass9.push("white");
    }
    if (this.state.addClass10) {
      boxClass10.push("back");
      blueClass10.push("blues");
    } else if (this.state.addClass10) {
      boxClass10.push("up");
      blueClass10.push("white");
    }
    if (this.state.addClass11) {
      boxClass11.push("back");
      blueClass11.push("blues");
    } else if (this.state.addClass11) {
      boxClass11.push("up");
      blueClass11.push("white");
    }

    return (
      <div className="pathways–he-overview-wrapper">
        <div className="container">
          <div className="para">
            Wattle College offers a range of pathways to get admission into its
            higher education course and experience life at Wattle.
          </div>
          <div className="para-last">
            Applicants wanting to study at Wattle College must satisfy both
            academic requirements and English language requirements. In addition
            to meeting English Language Requirements, students must be eligible
            from one of the following:
          </div>
          <div className="program-structure">
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass2.join(" ")}
                  className={blueClass3.join(" ")}
                  onClick={this.togglesHandle.bind(this)}
                  variant="link"
                  eventKey="2"
                >
                  {this.state.addClass2 ? "" : ""}
                  <div className="card-head">Recent Secondary Education (within the past two years)</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass2.join(" ")}
                    onClick={this.togglesHandle.bind(this)}
                    variant="link"
                    eventKey="2"
                  >
                    {this.state.addClass2 ? "" : ""}
                    <img src={droparrowupb} className="dropsb3" alt="arrows" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.open} eventKey="2">
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Applicants may be eligible for Bachelor of Business
                      (Health Administration) if they have successfully
                      completed Year 12 (or equivalent) with required minimum
                      ATAR (or equivalent) of 60 or if they have successfully
                      completed International Baccalaureate (IB) Diploma with a
                      minimum score of 24 points.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass3.join(" ")}
                  className={blueClass.join(" ")}
                  onClick={this.togglesHandle2.bind(this)}
                  variant="link"
                >
                  {this.state.addClass3 ? "" : ""}
                  <div className="card-head">
                    Recent Vocation Education and Training Sector (VET)
                    Completion
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass3.join(" ")}
                    onClick={this.togglesHandle2.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass3 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Applicants may be eligible for admission into our course
                      if they have successfully completed a Vocational Education
                      and Training (VET) award at Certificate IV or higher at an
                      Australian Registered Training Organisation (or
                      equivalent) or higher education provider.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass4.join(" ")}
                  className={blueClass4.join(" ")}
                  onClick={this.togglesHandle3.bind(this)}
                  variant="link"
                >
                  {this.state.addClass4 ? "" : ""}
                  <div className="card-head">
                    Recent Higher Education Completion
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass4.join(" ")}
                    onClick={this.togglesHandle3.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass4 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen4}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Applicants may be eligible for admissions into our course
                      if they have successfully completed at least one year of a
                      higher education course at an Australian higher education
                      provider (at AQF level 7) within the last 10 years.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass5.join(" ")}
                  className={blueClass5.join(" ")}
                  onClick={this.togglesHandle4.bind(this)}
                  variant="link"
                >
                  {this.state.addClass5 ? "" : ""}
                  <div className="card-head">
                    Applicants with work and life experience
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass5.join(" ")}
                    onClick={this.togglesHandle4.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass5 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen5}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Applicants may be eligible for admissions into our courses
                      if they have successfully completed Year 12 (or
                      equivalent) more than 2 years ago and can show work life
                      experience in the field of the course applicable.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <div className="sub-heading m-top">Please note:</div>
              <div className="para">
                Meeting the minimum entry requirements does not guarantee entry
                into Wattle College’s Higher Education course. All applications
                will be assessed on a case-by-case basis.
              </div>
              <div className="para">
                For more information on the admissions and our course, please refer to our handbook{' '}
                {/* <a href={handbook} target="_blank"> */}
                here
                {/* </a> */}
              </div>
              <div className="para paras-last">
                For further information, students can view the Admissions Policy on our policies and procedures page. More information on the courses offered can be viewed under our <a href="/courses">courses page</a>.
              </div>
              <div className="sub-heading main-head">Admissions Process</div>

              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass6.join(" ")}
                  className={blueClass6.join(" ")}
                  onClick={this.togglesHandle5.bind(this)}
                  variant="link"
                >
                  {this.state.addClass6 ? "" : ""}
                  <div className="card-head">
                    Application Process
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass6.join(" ")}
                    onClick={this.togglesHandle5.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass6 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen6}>
                  <Card.Body className="entry-body">
                    <div className="sub-heading">Application Process</div>
                    <div className="entry-para-top paras">
                      A prospective student will find all the information either through the website, this guide or by contacting the admission team. If the prospective student wishes to proceed, they must complete an application form, provide the required documentary evidence, and submit them to Wattle College.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass7.join(" ")}
                  className={blueClass7.join(" ")}
                  onClick={this.togglesHandle6.bind(this)}
                  variant="link"
                >
                  {this.state.addClass7 ? "" : ""}
                  <div className="card-head">
                    Application Assessment
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass7.join(" ")}
                    onClick={this.togglesHandle6.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass7 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen7}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top paras">
                      The admissions team at Wattle College will assess the application submitted by the applicant. The admissions team will do a completeness check on the submitted application. If the applicant has not completed all the fields or if the applicant has not provided all the relevant documents, then the application will be deemed incomplete, and the admissions team will request the applicant to resubmit the complete application.

                      In the assessment phase, the admission team will check if the applicant meets the minimum course requirements for the course.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass8.join(" ")}
                  className={blueClass8.join(" ")}
                  onClick={this.togglesHandle7.bind(this)}
                  variant="link"
                >
                  {this.state.addClass8 ? "" : ""}
                  <div className="card-head">
                    Offer Issuance
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass8.join(" ")}
                    onClick={this.togglesHandle7.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass8 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen8}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      If the applicant has passed the minimum requirements, the applicant will be issued a letter of offer and student agreement as mentioned below.

                    </div>
                    <div className="entry-para-top">
                      If the applicant does not meet the minimum requirements, the admission team will consider if the applicant is eligible for special consideration. If the application does not meet the
                      requirements for special consideration, the admissions team will reject the application and inform the applicant in writing about the decision outcome. The applicant will be provided
                      complaints and appeals policy if they wish to appeal the decision.
                    </div>
                    <div className="entry-para-top paras">
                      If the applicant has met all the requirements, then an unconditional offer would be issued to the applicant
                      (No further conditions to be met for admission). If the applicant has to meet certain condition, then a unconditional offer would be issued to the applicant (conditions have to be issued to the applicant that
                      have to be met before getting enrolled in the course).
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass9.join(" ")}
                  className={blueClass9.join(" ")}
                  onClick={this.togglesHandle8.bind(this)}
                  variant="link"
                >
                  {this.state.addClass9 ? "" : ""}
                  <div className="card-head">
                    Acceptance
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass9.join(" ")}
                    onClick={this.togglesHandle8.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass9 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen9}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top paras">
                      The applicant accepts the offer by signing the letter of offer and the student agreement and sending it back
                      to the admissions team via email or post. The applicant also needs to make the initial deposit as set out on the letter of offer.

                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  as={Button}
                  className={boxClass10.join(" ")}
                  className={blueClass10.join(" ")}
                  onClick={this.togglesHandle9.bind(this)}
                  variant="link"
                >
                  {this.state.addClass10 ? "" : ""}
                  <div className="card-head">
                    Confirmation of Admission
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass10.join(" ")}
                    onClick={this.togglesHandle9.bind(this)}
                    variant="link"
                  >
                    {this.state.addClass10 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="arrows second"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.tOpen10}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Once the signed letter of offer and the initial deposit has been received, the applicant would receive a confirmation email on their acceptance at Wattle College.
                    </div>
                    <div className="entry-para-top paras">
                      For international students, they would receive a confirmation of enrolment, that would assist them in applying for a student visa.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>

          </div>
        </div>
      </div>
    );
  }
}

export default AdmissionsHEOverview;
