import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import location from "../../../assets/images/contact-page/location-outline.png";
import mail from "../../../assets/images/contact-page/mail-outline.png";
import call from "../../../assets/images/contact-page/call-outline.png";
import AOS from "aos";
import "aos/dist/aos.css";

class ContactPageEnquiry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: "",
            lastname: "",
            enquireAbout: "",
            // checkbox: "",
            name: "",
            Phone: "",
            Email: "",
            Success: "",
            errors: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = (event) => {
            this.setState({
                checkbox: event.target.checked,
            });
        };

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }

    hasError(key) {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleInputChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        var obj = {};
        obj[key] = value;
        this.setState(obj);
        // this.setState({
        //   checkbox: event.target.checked
        // })
    }

    handleSubmit(event) {
        event.preventDefault();

        //VALIDATE
        var errors = [];

        //firstname
        if (this.state.firstname === "") {
            errors.push("firstname");
        }

        // lastname
        if (this.state.lastname === "") {
            errors.push("lastname");
        }

        // enquireAbout
        if (this.state.enquireAbout === "") {
            errors.push("enquireAbout");
        }

        // checkbox

        // if (this.state.checkbox === "") {
        //     errors.push("checkbox");
        // } else if (this.state.checkbox === false) {
        //     errors.push("checkbox");
        // }
        // console.log(this.state.checkbox);

        // Name
        if (this.state.name === "") {
            errors.push("name");
        }

        // Phone
        var pho = /^([0-9])+$/;
        if (this.state.Phone === "") {
            errors.push("Phone");
        } else if (!this.state.Phone.match(pho)) {
            errors.push("Phone");
        }

        //email
        const expression = /\S+@\S+/;
        var validEmail = expression.test(String(this.state.Email).toLowerCase());

        if (!validEmail) {
            errors.push("Email");
        }

        this.setState({
            errors: errors,
        });

        // this.send();

        // if (this.state == true){
        //   console.log('ok');
        // }
        if (errors.length > 1) {
            return false;
        } else {
            this.send();
            this.setState({
                Success: true,
                firstname: "",
                lastname: "",
                enquireAbout: "",
                name: "",
                Phone: "",
                Email: ""
            });
        }
    }
    // handleUnCheck = () => {
    //     if (this.state.checkbox == true) {
    //         this.setState({
    //             checkbox: false
    //         })
    //     }
    // };

    send() {

        const smtpjs = window.Email;
        smtpjs.send({
            SecureToken: "b2ffcd04-47e0-4a96-855f-b6c9f91fec11",
            To: "info@wattle.edu.au",
            From: this.state.firstname + " <admin@wattlecollege.com>",
            Subject: "Contact Form",
            Body: "First Name: " + this.state.firstname + "<br/>" +
                "Last Name: " + this.state.lastname + "<br/>" +
                "Email: " + this.state.Email + "<br/>" +
                "Phone: " + this.state.Phone + "<br/>" +
                "Enquiry: " + this.state.enquireAbout,

            // SecureToken: "b2ffcd04-47e0-4a96-855f-b6c9f91fec11",
            // To: "sikimdsol@gmail.com",
            // From: this.state.Email,
            // Subject: "This is the subject",
            // Body: "here will be body",
        })
        // .then((message) => alert(message));
    };

    render() {
        const { checkbox } = this.state;


        return (
            <div className="Contact-page-enquiry-wrapper">
                <div className="main container">
                    <div
                        className="heading"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                    >
                        Make an Enquiry
                    </div>
                    <div
                        className="heading-para"
                        data-aos="fade-right"
                        data-aos-duration="1400"
                        data-aos-delay="100"
                    >
                        Complete the form below and we will get back to you shortly.
                    </div>
                    <form className="form-section" method="post" onSubmit={this.handleSubmit}>
                        <Row
                            className="input-rows"
                            data-aos="fade-up"
                            data-aos-duration="1500"
                        >
                            <Col md={6} className="form_fields">
                                <label>
                                    First Name<span>*</span>
                                </label>
                                <input
                                    id="main-form-input"
                                    type="text"
                                    autoComplete="off"
                                    className={
                                        this.hasError("firstname")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="firstname"
                                    value={this.state.firstname}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("firstname") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a name
                                </div>
                            </Col>

                            <Col md={6} className="form_fields-second">
                                <label>
                                    Last Name<span>*</span>
                                </label>
                                <input
                                    id="main-form-input"
                                    autoComplete="off"
                                    className={
                                        this.hasError("lastname")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="lastname"
                                    value={this.state.lastname}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("lastname") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a name
                                </div>
                            </Col>

                            <Col md={6} className="form_fields">
                                <label>
                                    Email<span>*</span>
                                </label>
                                <input
                                    id="main-form-input"
                                    autoComplete="off"
                                    className={
                                        this.hasError("Email")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="Email"
                                    value={this.state.Email}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("Email") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter your Email
                                </div>
                            </Col>

                            <Col md={6} className="form_fields-second">
                                <label>
                                    Phone<span>*</span>
                                </label>
                                <input
                                    id="main-form-input"
                                    autoComplete="off"
                                    className={
                                        this.hasError("Phone")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="Phone"
                                    value={this.state.Phone}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("Phone") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter your phone number
                                </div>
                            </Col>

                            <Col md={12} className="form_fields message">
                                <label>
                                    What are you enquiring about?<span>*</span>
                                </label>
                                <textarea
                                    type="message"
                                    autoComplete="off"
                                    className={
                                        this.hasError("enquireAbout")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="enquireAbout"
                                    value={this.state.enquireAbout}
                                    onChange={this.handleInputChange}
                                />
                                <div
                                    className={
                                        this.hasError("enquireAbout") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter what are you enquiring
                                </div>
                            </Col>
                        </Row>

                        {/* <div className="message">
                            By checking this box, you agree to receive marketing emails from Wattle College.
                        </div> */}

                        {/* <div className="message-mobile">
                            <div
                                className="tick-mobile"
                                data-aos="fade-right"
                                data-aos-duration="1800"
                            >
                                <input
                                    id="main-form-input"
                                    type="checkbox"
                                    autoComplete="off"
                                    className={
                                        this.hasError("checkbox")
                                            ? "form-check-input is-invalid"
                                            : "form-check-input"
                                    }
                                    name="checkbox"
                                    value={this.state.checkbox}
                                    onChange={this.handleCheckChange}
                                ></input>
                                <span>I agree to receive emails from the Wattle College</span>
                                <br />
                            </div>
                            <div
                                className={
                                    this.hasError("checkbox") ? "inline-errormsg" : "hidden"
                                }
                                id="tick"
                            >
                                Please fill this check
                            </div> */}
                        {/* </div> */}

                        <button type="submit">Contact now</button>
                        {this.state.Success &&
                            <div data-aos="zoom-up" data-aos-duration="2000" className="success">Thank you for contacting Wattle College. One of our friendly staff members will get in touch shortly.</div>
                        }
                        <div className="heading visits">Visit Us</div>
                    </form>
                    <Row className="Contact-row">
                        <Col lg="7" md="12" className="col">
                            <div className="map-wrapper-2">
                                <div className="map-responsive">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.976213136014!2d144.9586077152075!3d-37.814026141831235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d948c84bd5b%3A0x1de06e58656c3d69!2sWattle%20College!5e0!3m2!1sen!2s!4v1625561603602!5m2!1sen!2s"
                                        width="100%"
                                        height="600px"
                                        border="0"
                                        style="border:0;"
                                        style={{ border: "0px" }}
                                        aria-hidden="false"
                                    ></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col lg="5" className="col col-white">
                            {/* <div className="heading">Other ways to contact us</div> */}
                            <div className="main-div">
                                <div className="three">
                                    <div className="img">
                                        <img src={call} alt="Phone" />
                                    </div>
                                    <div className="content">
                                        <div className="head">Phone</div>
                                        <div className="para">
                                            <a href="tel: 1300 118 085">1300 118 085</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="three">
                                    <div className="img">
                                        <img src={mail} alt="e-mail" />
                                    </div>
                                    <div className="content">
                                        <div className="head">Email</div>
                                        <div className="para">
                                            <a href="mailto:info@wattle.edu.au">
                                                info@wattle.edu.au
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="three last">
                                    <div className="img">
                                        <img src={location} alt="google Location" />
                                    </div>
                                    <div className="content">
                                        <div className="head">Address</div>
                                        <div className="para">Level 6/ 190 Queen Street</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }
}

export default ContactPageEnquiry;
