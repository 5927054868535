import React, { Component } from "react";
// import CertIvCourseEnquiry from "../Cert-iv-componenet/Cert-iv-course-enquiry";
// import SimpleSlider from "../slider";
import Footer from "../Footer";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";
import HospitalityHelp from "../Hospitality-managment-components/Hospitality-help";
import HospitalityDetail from "../Hospitality-managment-components/Hospitality-details";
import HospitalityCourseEnquiry from "../Hospitality-managment-components/Hospitality-course-enquiry";
import HospitalityBanner from "../Hospitality-managment-components/Hospitality-banner";
import HospitalityOverview from "../Hospitality-managment-components/Hospitality-overview";

class HospitalityPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="hospitality-page-wrapper">
                <SideButtons />
                <HospitalityBanner />
                <HospitalityOverview />
                <HospitalityDetail />
                {/* <CertIvCourseEnquiry /> */}
                {/* <SimpleSlider /> */}
                <HospitalityHelp />
                <Footer />
            </div>
        );
    }
}

export default HospitalityPage;
