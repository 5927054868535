import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import aboutback from "../../../assets/images/why-wattle-images/about-Why-Wattle-react_04.jpg";
import aboutimg from "../../../assets/images/why-wattle-images/two-happy-partners-shaking-hands-cafe.jpg";

import campusimg from "../../../assets/images/why-wattle-images/campusimg.jpg";
import aboutback2 from "../../../assets/images/why-wattle-images/Rectangle-34-copy-5-fill_02.jpg";

import arrowright from "../../../assets/images/course-deploma/course-icons/arrow-banner.png";
import handbooks from '../../../assets/Policies/Student-Handbook-VET.pdf';
import AOS from "aos";
import "aos/dist/aos.css";

class WhyWattleOverview extends Component {
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }
    render() {
        return (
            <div className="why-wattle-overview-wrapper">
                <div className="course-section">
                    <div
                        className="course-div"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                    >
                        <div className="heading-course">Introduction</div>
                        <div className="d-para">
                            Wattle is committed to helping you achieve your goals. With our carefully designed courses, you will gain skills to be job ready. We are proud to offer a technologically enriched environment, flexible timetable and a comprehensive student support to make your educational experience a rewarding one. With students from all over the globe, you will build countless memories for the rest of your life. Come study with us!
                        </div>
                    </div>
                    <div className="back-rect"></div>
                </div>

                <div className="glance-section">
                    <div className="container">
                        <Row className="wallet-section-f">
                            <Col lg="6" className="col-sections-a">
                                <div
                                    className="heading"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    Study on-Campus
                                </div>
                                <div
                                    className="para"
                                    data-aos="fade-right"
                                    data-aos-duration="1400"
                                    data-aos-delay="100"
                                >
                                    Our campus is based in the heart of Melbourne CBD and is easily accessible by public transport. Our campus is in the free tram zone.
                                </div>
                                <div
                                    className="para para1"
                                    data-aos="fade-right"
                                    data-aos-duration="1300"
                                    data-aos-delay="200"
                                >
                                    Our campus offers:
                                </div>
                                <div
                                    className="para para1"
                                    data-aos="fade-right"
                                    data-aos-duration="1300"
                                    data-aos-delay="200"
                                >
                                    <ul>
                                        <li>
                                            Spacious classrooms with state-of-the-art technology
                                        </li>
                                        <li>
                                            Student breakout rooms equipped with amenities
                                        </li>
                                        <li>
                                            FREE high-speed Wi-Fi across the campus
                                        </li>
                                        <li>
                                            Extensive library and student resource centre
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col lg="6" className="col-section-b">
                                <div
                                    className="img"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <img src={aboutimg} />
                                    <img src={aboutback} className="back-rect" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="campus-section">
                    <div className="container">
                        <Row className="campus-section-f">
                            <Col lg="6" className="col-sections-a">
                                <div
                                    className="heading"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    Melbourne Campus
                                </div>
                                <div
                                    className="para"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    With population of nearly 5 million residents, Melbourne is the second largest city in Australia. A home of about 140 cultures, the city is diverse and multi-cultural.
                                </div>
                                <div
                                    className="para"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    Melbourne has a thriving food culture. It is home to amazing cafes, restaurants and bars. You will be amazed to explore small hidden bars in and around the Melbourne city centre.
                                </div>

                                <div
                                    className="para"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    Apart from its obsession with food, Melbourne is a self-proclaimed sports capital of the world. With some of the major sporting events hosted throughout the year, you will never be in lack of experiences.

                                </div>
                                <div
                                    className="para"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    Melbourne has something for everyone. Come and experience it yourself!

                                </div>
                                <div
                                    className="para para1"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <div className="first-br">
                                        For more information about living in Melbourne can be found here:
                                    </div>
                                    <img src={arrowright} alt="right arrow" /><a href="https://www.melbourne.vic.gov.au" target="blank">City of Melbourne </a><br />
                                    <img src={arrowright} alt="right arrow" /><a href="https://www.studymelbourne.vic.gov.au" target="blank">Study Melbourne </a>
                                    <br />
                                    <img src={arrowright} alt="right arrow" /><a href="https://www.visitvictoria.com" target="blank">Visit Victoria </a> <br />
                                    <img src={arrowright} alt="right arrow" /><a href="https://www.onlymelbourne.com.au" target="blank">What’s on Melbourne </a>
                                </div>
                            </Col>

                            <Col lg="6" className="col-section-b">
                                <div
                                    className="img"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                >
                                    <img src={campusimg} />
                                    <img src={aboutback2} className="back-rect" />
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>

                <div className="accommodation-sections container">
                    <div
                        className="heading"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                    >
                        Accomodation
                    </div>
                    <div
                        className="para"
                        data-aos="fade-left"
                        data-aos-duration="1400"
                        data-aos-delay="100"
                    >
                        There are many accommodation choices such as rental accommodation or a hostel room. If you plan to rent in Melbourne, you will need to allow for a rental bond equivalent to 4 to 6 weeks rent. Weekly rental costs vary from suburb to suburb and according to the size and quality of accommodation. Rental rates may range from A$100 to A$250 per week. Costs for a hostel room in Melbourne ranges from A$100 to A$200 per week. Please contact Student Support Services at <a href="mailto:info@wattle.edu.au">info@wattle.edu.au</a> for assisting in Accommodation Arrangements.
                    </div>
                    <div
                        className="para para1"
                        data-aos="fade-left"
                        data-aos-duration="1300"
                        data-aos-delay="200"
                    >
                        You can find further information here <a href="https://www.studymelbourne.vic.gov.au/living-and-accommodation" target="blank"> https://www.studymelbourne.vic.gov.au/living-and-accommodation</a>
                    </div>
                    <div
                        className="para para1"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                    >
                        For more information regarding other services offered by Wattle College, you can see <a href={handbooks} target="_blank">our handbook here</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhyWattleOverview;
