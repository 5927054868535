import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import arrowright from "../../../assets/images/blue-right-arrow.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import pdf13 from "../../../assets/Policies/Application Form 2022.pdf";
class HowToApplyPageContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inspection: "",
            firstname: "",
            familyname: "",
            date: "",
            gender: "",
            nationality: "",
            City: "",
            Address: "",
            PostalCode: "",
            Region: "",
            Country: "",
            PassportNumber: "",
            StudyPlan: "",
            speciallearning: "",
            OSHC: "",
            proficiencytest: "",

            applyingFor: "",
            applyingFor1: "",
            applyingFor2: "",
            applyingFor3: "",

            selectCourse: "",
            select2ndCourse: "",
            select3rdCourse: "",
            select4thCourse: "",

            passportFile: "",
            SSVF: "",
            document3: "",
            document4: "",

            KnowledgeCheckbox: "",
            AgeCheckbox: "",
            CommunicationsCheckbox: "",
            PersonalCheckbox: "",

            checkbox: "",
            name: "",
            Phone: "",
            Email: "",
            errors: [],
            isApplyCheck: "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = (event) => {
            this.setState({
                checkbox: event.target.checked,
            });
        };
        this.handleKnowledgeCheckChange = (event) => {
            this.setState({
                KnowledgeCheckbox: event.target.checked,
            });
        };
        this.handleAgeCheckChange = (event) => {
            this.setState({
                AgeCheckbox: event.target.checked,
            });
        };
        this.handleCommunicationsCheckChange = (event) => {
            this.setState({
                CommunicationsCheckbox: event.target.checked,
            });
        };
        this.handlePersonalCheckChange = (event) => {
            this.setState({
                PersonalCheckbox: event.target.checked,
            });
        };

        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleKnowledgeCheckChange = this.handleKnowledgeCheckChange.bind(
            this
        );
        this.handleAgeCheckChange = this.handleAgeCheckChange.bind(this);
        this.handleCommunicationsCheckChange = this.handleCommunicationsCheckChange.bind(
            this
        );
        this.handlePersonalCheckChange = this.handlePersonalCheckChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputRadioChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isApplyCheck: event.target.value,
            applyingFor1: event.target.value,
            applyingFor2: event.target.value,
            applyingFor3: event.target.value,
        });
        // console.log(event.target.name, event.target.value)
    };
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }

    hasError(key) {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleInputChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        var obj = {};
        obj[key] = value;
        this.setState(obj);
    }

    handleSubmit(event) {
        event.preventDefault();

        //VALIDATE
        var errors = [];

        //inspection
        if (this.state.inspection === "") {
            errors.push("inspection");
        }

        //firstname
        if (this.state.firstname === "") {
            errors.push("firstname");
        }

        //familyname
        if (this.state.familyname === "") {
            errors.push("familyname");
        }

        // date
        if (this.state.date === "") {
            errors.push("date");
        }

        //gender
        if (this.state.gender === "") {
            errors.push("gender");
        }

        //nationality
        if (this.state.nationality === "") {
            errors.push("nationality");
        }

        //City
        if (this.state.City === "") {
            errors.push("City");
        }

        //Address
        if (this.state.Address === "") {
            errors.push("Address");
        }

        // PostalCode
        var post = /^([0-9])+$/;
        if (this.state.PostalCode === "") {
            errors.push("PostalCode");
        } else if (!this.state.PostalCode.match(post)) {
            errors.push("PostalCode");
        }

        //Region
        if (this.state.Region === "") {
            errors.push("Region");
        }

        //Country
        if (this.state.Country === "") {
            errors.push("Country");
        }

        // PassportNumber
        var pass = /^([0-9])+$/;
        if (this.state.PassportNumber === "") {
            errors.push("PassportNumber");
        } else if (!this.state.PassportNumber.match(pass)) {
            errors.push("PassportNumber");
        }

        //StudyPlan
        if (this.state.StudyPlan === "") {
            errors.push("StudyPlan");
        }

        //speciallearning
        if (this.state.speciallearning === "") {
            errors.push("speciallearning");
        }

        //OSHC
        if (this.state.OSHC === "") {
            errors.push("OSHC");
        }

        //proficiencytest
        if (this.state.proficiencytest === "") {
            errors.push("proficiencytest");
        }

        //applyingFor
        if (
            this.state.isApplyCheck === "" &&
            this.state.applyingFor1 === "" &&
            this.state.applyingFor2 === "" &&
            this.state.applyingFor3 === ""
        ) {
            errors.push("applyingFor");
        }

        //selectCourse
        if (this.state.selectCourse === "") {
            errors.push("selectCourse");
        }

        // passportFile
        if (this.state.passportFile === "") {
            errors.push("passportFile");
        }

        // SSVF
        if (this.state.SSVF === "") {
            errors.push("SSVF");
        }

        // KnowledgeCheckbox

        if (this.state.KnowledgeCheckbox === "") {
            errors.push("KnowledgeCheckbox");
        } else if (this.state.KnowledgeCheckbox === false) {
            errors.push("KnowledgeCheckbox");
        }

        // AgeCheckbox

        if (this.state.AgeCheckbox === "") {
            errors.push("AgeCheckbox");
        } else if (this.state.AgeCheckbox === false) {
            errors.push("AgeCheckbox");
        }

        // CommunicationsCheckbox

        if (this.state.CommunicationsCheckbox === "") {
            errors.push("CommunicationsCheckbox");
        } else if (this.state.CommunicationsCheckbox === false) {
            errors.push("CommunicationsCheckbox");
        }

        // PersonalCheckbox

        if (this.state.PersonalCheckbox === "") {
            errors.push("PersonalCheckbox");
        } else if (this.state.PersonalCheckbox === false) {
            errors.push("PersonalCheckbox");
        }

        // checkbox

        if (this.state.checkbox === "") {
            errors.push("checkbox");
        } else if (this.state.checkbox === false) {
            errors.push("checkbox");
        }
        console.log(this.state.checkbox);

        // Name
        if (this.state.name === "") {
            errors.push("name");
        }

        // Phone
        var pho = /^([0-9])+$/;
        if (this.state.Phone === "") {
            errors.push("Phone");
        } else if (!this.state.Phone.match(pho)) {
            errors.push("Phone");
        }

        //email
        const expression = /\S+@\S+/;
        var validEmail = expression.test(String(this.state.Email).toLowerCase());

        if (!validEmail) {
            errors.push("Email");
        }

        this.setState({
            errors: errors,
        });

        if (errors.length > 3) {
            return false;
        } else {
            alert("everything good. submit form!");
            console.log(this.state);
        }
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        const {
            isApplyCheck,
            applyingFor1,
            applyingFor2,
            applyingFor3,
            checkbox,
            AgeCheckbox,
            CommunicationsCheckbox,
            KnowledgeCheckbox,
            PersonalCheckbox,
        } = this.state;
        return (
            <div className="HowToApplyPageContent-content-wrapper">
                <div className="help-section">
                    <div className="container">
                        <div className="content-heading-section">
                            <div
                                className="help-heading"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                            >
                                We are very excited to have you study with us!
                            </div>
                            <div
                                className="para-full"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                data-aos-delay="100"
                            >
                                Please ensure you have read the entry requirements, including the English Language requirements within the specific course page and also read the instructions under <NavLink to="/how-to-apply" onClick={() => { this.scrollToTop(); }}>how to apply</NavLink> page.
                            </div>
                            <div
                                className="para-full"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                data-aos-delay="100"
                            >
                                You can download and fill up the application form <a href={pdf13} target="_blank">here</a>, and post it to our head office at Suite 2, Level 6/ 190 Queen Street, Melbourne VIC – 3000.
                            </div>
                            <div
                                className="para-full"
                                data-aos="fade-right"
                                data-aos-duration="1500"
                                data-aos-delay="100"
                            >
                                For any support services, you can <a href="/contact-us">contact us here</a>.
                            </div>
                        </div>



                        {/*<div className="Application-form">
                            <div
                                className="heading"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                Online Aplication Form
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="who-is-making">
                                    Who is making this application?*
                                </div>
                                <label>
                                    Please let us know whether you are student or an agent
                                </label>
                                <select
                                    className={
                                        this.hasError("inspection")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    name="inspection"
                                    value={this.state.inspection}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="" disabled selected hidden>
                                        -Please select-
                                    </option>
                                    <option value="student">I am Student</option>
                                    <option value="agent">I am an Agent</option>
                                </select>
                                <div
                                    className={
                                        this.hasError("inspection") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please select a option
                                </div>

                                <div className="student-detail">
                                    <div className="student-heading">Student Details</div>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label>
                                                First Name<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("firstname")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="firstname"
                                                value={this.state.firstname}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("firstname")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter a name
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label>
                                                Surname/Family Name<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("familyname")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="familyname"
                                                value={this.state.familyname}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("familyname")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter a name
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="full-col">
                                            <label className="top-label">
                                                Date of Birth <span>*</span>
                                            </label>
                                            <div className="instruction">
                                                Please be careful and provide the full date e.g. 15
                                                JANUARY 1990
                                            </div>
                                            <input
                                                type="date"
                                                className={
                                                    this.hasError("date")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="date"
                                                value={this.state.date}
                                                onChange={this.handleInputChange}
                                                className="date-input"
                                            />
                                            <div
                                                className={
                                                    this.hasError("date") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please select your date of birth
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label className="top-label">
                                                Gender<span>*</span>
                                            </label>
                                            <select
                                                className={
                                                    this.hasError("gender")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="gender"
                                                value={this.state.gender}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("gender") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please select a option
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label className="top-label">
                                                Nationality<span>*</span>
                                            </label>
                                            <select
                                                className={
                                                    this.hasError("nationality")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="nationality"
                                                value={this.state.nationality}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="pakistani">Pakistani</option>
                                                <option value="australian">Australian</option>
                                                <option value="indian">Indian</option>
                                                <option value="americans">Americans</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("nationality")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please select your nationality
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="full-col">
                                            <div className="student-heading">Student Email</div>
                                            <label className="semi-label">
                                                This MUST be the email of the student enrolling, and NOT
                                                the email of agent or guardian<span>*</span>
                                            </label>
                                            <input
                                                placeholder="Student email address only"
                                                className="date-input"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("Email")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="Email"
                                                value={this.state.Email}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("Email") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please enter your Email
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="full-col">
                                            <label className="top-label">
                                                Student Phone Number/Mobile Number<span>*</span>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                className={
                                                    this.hasError("Phone")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="Phone"
                                                value={this.state.Phone}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("Phone") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please enter your phone number
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="full-col">
                                            <label className="top-label">
                                                Address<span>*</span>
                                            </label>
                                            <input
                                                type="address"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("Address")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="Address"
                                                value={this.state.Address}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("Address")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter your address
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label className="top-label">
                                                City<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("City")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="City"
                                                value={this.state.City}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("City") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please enter your City
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label className="top-label">
                                                State/Region<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("Region")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="Region"
                                                value={this.state.Region}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("Region") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please enter your region
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label className="top-label">
                                                Postal Code<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("PostalCode")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="PostalCode"
                                                value={this.state.PostalCode}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("PostalCode")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter postal code
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label className="top-label">
                                                Country<span>*</span>
                                            </label>
                                            <select
                                                className={
                                                    this.hasError("Country")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="Country"
                                                value={this.state.Country}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="australia">Australia</option>
                                                <option value="pakistan">Pakistan</option>
                                                <option value="india">India</option>
                                                <option value="america">America</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("Country")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter your Country
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <div className="student-heading heading-b">Visa Detail</div>
                                        <Col className="left-col flex-col" lg="6">
                                            <label>
                                                Passport Number<span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    this.hasError("PassportNumber")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="PassportNumber"
                                                value={this.state.PassportNumber}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("PassportNumber")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please enter your Passport number
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label>
                                                Which visa type do you plan to study under at greenwich
                                                <span>*</span>
                                            </label>
                                            <select
                                                autoComplete="off"
                                                className={
                                                    this.hasError("StudyPlan")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="StudyPlan"
                                                value={this.state.StudyPlan}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="australia">Australia</option>
                                                <option value="pakistan">Pakistan</option>
                                                <option value="india">India</option>
                                                <option value="america">America</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("StudyPlan")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please select the visa type
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <div className="student-heading heading-b">
                                            Special Needs
                                        </div>
                                        <Col className="full-col">
                                            <label>
                                                Do you have any special learning or physical needs?
                                                (e.g. hearing or sight impaired?)<span>*</span>
                                            </label>
                                            <select
                                                name="speciallearning"
                                                value={this.state.speciallearning}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="hearing">Hearing</option>
                                                <option value="sightimpaired">Sight impaired</option>
                                                <option value="no">No</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("speciallearning")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please select a option
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <div className="student-heading heading-b">
                                            Oversea Student Health Cover(OSHC)
                                        </div>
                                        <div className="os-para">
                                            The Australian Government requires all overseas students
                                            studying on a student visa to have Overseas Student Health
                                            Cover (OSHC) for the total duration of your stay in
                                            Australia.
                                        </div>
                                        <Col className="full-col">
                                            <label>
                                                Do you want Greenwich College to arrange your OSHC?
                                                <span>*</span>
                                            </label>
                                            <select
                                                className={
                                                    this.hasError("OSHC")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="OSHC"
                                                value={this.state.OSHC}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="australia">Australia</option>
                                                <option value="pakistan">Pakistan</option>
                                                <option value="india">India</option>
                                                <option value="america">America</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("OSHC") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please select a option
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <div className="student-heading heading-b">
                                            ENGLISH LANGUAGE PROFICIENCY
                                        </div>
                                        <div className="os-para">
                                            Depending on the course of study, an English placement
                                            test may be required before we can offer a placement for
                                            that course. Studying General English will not require an
                                            English placement test.
                                        </div>
                                        <div className="test-link">
                                            <a href="#">Book a Placement Test with Greenwich.</a>
                                        </div>
                                        <Col className="full-col">
                                            <label>
                                                Has an English language proficiency test been completed
                                                <span>*</span>
                                            </label>
                                            <select
                                                className={
                                                    this.hasError("proficiencytest")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="proficiencytest"
                                                value={this.state.proficiencytest}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="australia">Australia</option>
                                                <option value="pakistan">Pakistan</option>
                                                <option value="india">India</option>
                                                <option value="america">America</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("proficiencytest")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please select a option
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="student-heading">Application Type</div>
                                    <div className="type-para">What are you appling for?*</div>
                                    <input
                                        onChange={(event) => this.handleInputRadioChange(event)}
                                        className="input-radio"
                                        type="radio"
                                        id="english-course"
                                        name="english"
                                        value="English Course Only"
                                        checked={
                                            applyingFor1 === "English Course Only" ? true : false
                                        }
                                    />
                                    <label className="label-radio" for="English-Course-Only">
                                        English Course Only
                                    </label>
                                    <br />
                                    <input
                                        onChange={(event) => this.handleInputRadioChange(event)}
                                        className="input-radio"
                                        type="radio"
                                        value="English + VET Courses"
                                        id="English + VET Courses"
                                        name="vet"
                                        checked={
                                            applyingFor2 === "English + VET Courses" ? true : false
                                        }
                                    />
                                    <label className="label-radio" for="English + VET Courses">
                                        English + VET Courses
                                    </label>
                                    <br />
                                    <input
                                        onChange={(event) => this.handleInputRadioChange(event)}
                                        className="input-radio"
                                        type="radio"
                                        id="VET Courses Only"
                                        name="coursevet"
                                        value="VET Courses Only"
                                        checked={applyingFor3 === "VET Courses Only" ? true : false}
                                    />
                                    <label className="label-radio" for="VET Courses Only">
                                        VET Courses Only
                                    </label>
                                    <br />
                                    <input
                                        onChange={(event) => this.handleInputRadioChange(event)}
                                        className="input-radio"
                                        type="radio"
                                        id="Online Academy"
                                        name="academy"
                                        value="Online Academy"
                                        checked={isApplyCheck === "Online Academy" ? true : false}
                                    />
                                    <label className="label-radio" for="other">
                                        Online Academy
                                    </label>
                                    <div
                                        className={
                                            this.hasError("applyingFor")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                    >
                                        Please select a option
                                    </div>

                                    <div className="student-heading">Course details</div>
                                    <div className="type-para">
                                        View <a href="#">Study Planner</a> and check course start
                                        dates.
                                    </div>

                                    <div className="para-heading">
                                        VET COURSE TIMETABLES - GREENWICH MANAGEMENT COLLEGE{" "}
                                        <span>*</span>
                                    </div>
                                    <div className="timetable-para">
                                        Day Timetables: 2 morning sessions – either Mon & Thurs or
                                        Tue & Wed (8:30–13:30) + Skill classes
                                    </div>
                                    <div className="timetable-para">
                                        Evening timetables: 2 evening sessions – either Mon & Thurs
                                        or Tue & Wed (16:00–21:00) + Skill classes
                                    </div>
                                    <div className="timetable-para">
                                        Full Day timetables: 1 full day session – either Friday all
                                        day or Saturday (08:30–19:30) + Skill classes
                                    </div>
                                    <div className="timetable-para">
                                        A final timetable will be allocated to the student in their
                                        orientation pack prior to their commencement
                                        <br /> (at least 2 weeks before the Orientation Day).
                                    </div>

                                    <div className="para-heading">ONLINE ACADEMY</div>
                                    <div className="timetable-para">
                                        Australian Survival English
                                    </div>
                                    <div className="timetable-para">
                                        Next intake: 22nd March 2021
                                    </div>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label className="top-label">
                                                SELECT COURSE 1<span>*</span>
                                            </label>
                                            <div className="para-course">
                                                What course are you interested in studying?
                                                <span>*</span>
                                            </div>
                                            <select
                                                className={
                                                    this.hasError("selectCourse")
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                                name="selectCourse"
                                                value={this.state.selectCourse}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    -Please select-
                                                </option>
                                                <option value="english">English</option>
                                                <option value="Urdu">Urdu</option>
                                                <option value="chemistry">Chemistry</option>
                                            </select>
                                            <div
                                                className={
                                                    this.hasError("selectCourse")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please select a option
                                            </div>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label className="top-label">SELECT COURSE 2</label>
                                            <div className="para-course">
                                                Do you want to add another course?
                                            </div>
                                            <select
                                                name="select2ndCourse"
                                                value={this.state.select2ndCourse}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    ***NO FURTHER COURSE or Select 2nd Course***
                                                </option>
                                                <option value="english">English</option>
                                                <option value="Urdu">Urdu</option>
                                                <option value="chemistry">Chemistry</option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className="rows">
                                        <Col className="left-col" lg="6">
                                            <label className="top-label">SELECT COURSE 3</label>
                                            <div className="para-course">
                                                Do you want to add another course?
                                            </div>
                                            <select
                                                name="select3rdCourse"
                                                value={this.state.select2rdCourse}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    ***NO FURTHER COURSE or Select 3rd Course***
                                                </option>
                                                <option value="english">English</option>
                                                <option value="Urdu">Urdu</option>
                                                <option value="chemistry">Chemistry</option>
                                            </select>
                                        </Col>
                                        <Col className="right-col" lg="6">
                                            <label className="top-label">SELECT COURSE 4</label>
                                            <div className="para-course">
                                                Do you want to add another course?
                                            </div>
                                            <select
                                                name="select4thCourse"
                                                value={this.state.select4thCourse}
                                                onChange={this.handleInputChange}
                                            >
                                                <option value="" disabled selected hidden>
                                                    ***NO FURTHER COURSE or Select 4th Course***
                                                </option>
                                                <option value="english">English</option>
                                                <option value="Urdu">Urdu</option>
                                                <option value="chemistry">Chemistry</option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <div className="para-heading">
                                        ACCOMMODATION & AIRPORT TRANSFER
                                    </div>
                                    <div className="timetable-para">
                                        <span className="highlight">HOMESTAY</span> Accommodation
                                        (Placement Fee $290)
                                    </div>
                                    <div className="timetable-para">Single $325pw</div>
                                    <div className="shared-para">
                                        <span className="highlight">SHARED</span> Accommodation
                                            (Placement Fee $250. Minimum of 4 weeks booking applies.
                                            Airport transfer is compulsory Sydney Fee $78.50 / Melbourne
                                            Fee $78.50. Compulsory check-out cleaning fee $50)
                                            <br />
                                            Key deposit on arrival: Student Resident Premium/Budget $100
                                            <br />
                                            Please note that the key deposit must be paid on arrival.
                                            Weekly price based on 2 week bookings.
                                        </div>
                                    <div className="shared-para">
                                        <span className="highlight">COZZYSTAY</span> Accommodation -
                                        Premium <br />
                                        Sydney / Melbourne
                                        <br />
                                        Single $385pw
                                        <br />
                                        Double/Twin $235pw
                                    </div>

                                    <label className="top-label dim">
                                        Do you want Greenwich College to arrange your Accommodation
                                        & Airport
                                    </label>
                                    <select
                                        className={
                                            this.hasError("accomodation")
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        name="accomodation"
                                        value={this.state.accomodation}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value="" disabled selected hidden>
                                            -Please select-
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No thanks">No thanks</option>
                                    </select>

                                    <label className="top-label">COMMENTS</label>
                                    <div className="timetable-para">
                                        Tell us any additional information you would like to have
                                        included in this application such as a specific timetable
                                        request, holiday requests, and any other special
                                        considerations.
                                    </div>
                                    <input
                                        type="text"
                                        className="date-input"
                                        placeholder="Example - instalment plan, timetable request, holiday requests, or any other special considerations."
                                        name="comments"
                                        value={this.state.comments}
                                        onChange={this.handleInputChange}
                                    />

                                    <label className="top-label">SUPPORTING DOCUMENTS</label>
                                    <div className="supporting-para">
                                        Please upload your supporting documents here.
                                        <br />
                                        Where possible, please ensure that you upload all supporting
                                        documents with your application.
                                        <br />
                                        Completed applications with all supporting documents will be
                                        processed as a priority.
                                        <br />
                                        If you have taken an English test or have an English
                                        certificate please upload this too..
                                        <br />
                                        If you have done or are planning to do another course in
                                        Australia, you can upload the relevant Letter of Offer or
                                        Letter of Release here too
                                        <br />
                                    </div>

                                    <label className="top-label">
                                        Download SSVF Guide - GTE Supporting Document here.
                                    </label>

                                    <Row className="rows choose-rows">
                                        <Col lg="3" md="6">
                                            <label>Passport(required)*</label>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                name="passportFile"
                                                value={this.state.passportFile}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("passportFile")
                                                        ? "inline-errormsg"
                                                        : "hidden"
                                                }
                                            >
                                                Please choose a file
                                            </div>
                                        </Col>
                                        <Col lg="3" md="6">
                                            <label>SSVF*</label>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                name="SSVF"
                                                value={this.state.SSVF}
                                                onChange={this.handleInputChange}
                                            />
                                            <div
                                                className={
                                                    this.hasError("SSVF") ? "inline-errormsg" : "hidden"
                                                }
                                            >
                                                Please choose a file
                                            </div>
                                        </Col>
                                        <Col lg="3" md="6">
                                            <label>Attach document 3</label>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                name="document3"
                                                value={this.state.document3}
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                        <Col lg="3" md="6">
                                            <label>Attach document 4</label>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                name="document4"
                                                value={this.state.document4}
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </Row>

                                    <label className="top-label">CONFIRMATION</label>
                                    <div className="timetable-para">
                                        I declare that all information provided in this application
                                        form is correct and that I have read and understood, and
                                        agree to be bound by the Terms and Conditions (click to read
                                        the Terms and Conditions). I understand that acceptance into
                                        any course(s) at Greenwich College is subject to meeting the
                                        course entry requirements and submitting full payment of
                                        fees a minimum of 14 days prior to the commencement date of
                                        the course, and that the issuance of any offer of admission
                                        from Greenwich College constitutes a written agreement
                                        between me, the applicant, and Greenwich College in
                                        accordance with the Terms and Conditions.
                                    </div>

                                    <label className="top-label">Declaration*</label>
                                    <div className="tick-mobile">
                                        <input
                                            type="checkbox"
                                            autoComplete="off"
                                            className={
                                                this.hasError("KnowledgeCheckbox")
                                                    ? "form-check-input is-invalid"
                                                    : "form-check-input"
                                            }
                                            name="KnowledgeCheckbox"
                                            value={this.state.KnowledgeCheckbox}
                                            onChange={this.handleKnowledgeCheckChange}
                                        ></input>
                                        <span>
                                            I confirm that I have read and understood the Terms and
                                            Conditions. I also confirm that the information I have
                                            provided is accurate to the best of my knowledge at the
                                            time of submission.
                                        </span>
                                        <br />
                                    </div>
                                    <div
                                        className={
                                            this.hasError("KnowledgeCheckbox")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        id="tick"
                                    >
                                        Please fill this check
                                    </div>

                                    <div className="tick-mobile">
                                        <input
                                            type="checkbox"
                                            autoComplete="off"
                                            className={
                                                this.hasError("AgeCheckbox")
                                                    ? "form-check-input is-invalid"
                                                    : "form-check-input"
                                            }
                                            name="AgeCheckbox"
                                            value={this.state.AgeCheckbox}
                                            onChange={this.handleAgeCheckChange}
                                        ></input>
                                        <span>
                                            I confirm that I am 18 years older or that I am the parent
                                            or legal guardian of the afore mentioned student.
                                        </span>
                                        <br />
                                    </div>
                                    <div
                                        className={
                                            this.hasError("AgeCheckbox")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        id="tick"
                                    >
                                        Please fill this check
                                    </div>

                                    <label className="greenwich">
                                        Greenwich is committed to protecting and respecting your
                                        privacy, and we’ll only use your personal information to
                                        administer your account and to provide the products and
                                        services you requested from us. From time to time, we would
                                        like to contact you about our products and services, as well
                                        as other content that may be of interest to you. If you
                                        consent to us contacting you for this purpose, please tick
                                        below to say how you would like us to contact you:
                                    </label>

                                    <div className="tick-mobile high">
                                        <input
                                            type="checkbox"
                                            autoComplete="off"
                                            className={
                                                this.hasError("CommunicationsCheckbox")
                                                    ? "form-check-input is-invalid"
                                                    : "form-check-input"
                                            }
                                            name="CommunicationsCheckbox"
                                            value={this.state.CommunicationsCheckbox}
                                            onChange={this.handleCommunicationsCheckChange}
                                        ></input>
                                        <span>
                                            I agree to receive other communications from Greenwich.
                                        </span>
                                        <br />
                                    </div>
                                    <div
                                        className={
                                            this.hasError("CommunicationsCheckbox")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        id="tick"
                                    >
                                        Please fill this check
                                    </div>

                                    <div className="para-tick">
                                        You may unsubscribe from these communications at any time.
                                        For more information on how to unsubscribe, our privacy
                                        practices, and how we are committed to protecting and
                                        respecting your privacy, please review our Privacy Policy.
                                    </div>

                                    <div className="tick-mobile high">
                                        <input
                                            type="checkbox"
                                            autoComplete="off"
                                            className={
                                                this.hasError("PersonalCheckbox")
                                                    ? "form-check-input is-invalid"
                                                    : "form-check-input"
                                            }
                                            name="PersonalCheckbox"
                                            value={this.state.PersonalCheckbox}
                                            onChange={this.handlePersonalCheckChange}
                                        ></input>
                                        <span>
                                            I agree to allow Greenwich to store and process my
                                            personal data.*
                                        </span>
                                        <br />
                                    </div>
                                    <div
                                        className={
                                            this.hasError("PersonalCheckbox")
                                                ? "inline-errormsg"
                                                : "hidden"
                                        }
                                        id="tick"
                                    >
                                        Please fill this check
                                    </div>
                                    <div className="para-tick">
                                        You may unsubscribe from these communications at any time.
                                        For more information on how to unsubscribe, our privacy
                                        practices, and how we are committed to protecting and
                                        respecting your privacy, please review our Privacy Policy.
                                    </div>
                                    <button type="submit">Click To Submit</button>
                                </div>
                            </form>
                        </div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default HowToApplyPageContent;
