import React from "react";
import { Row, Col } from "react-bootstrap";
import teamImg from "../../assets/images/news.png";
import Arrow from "../../assets/images/icons/arrow.png";
import "aos/dist/aos.css";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";


// TO Call IMAGE USE https://wattle-admin.herokuapp.com/uploads/images/ FIRST

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}
function AllPost({ postList, handlePostDetail }) {
    return (
        <div className="all_posts_wrapper">
            <div className="latest-cont container">
                <div className="latest_news_title">
                    <div className="title">News and Events</div>
                    <div className="sub_title">
                        Check out the news and events from wattle college
                    </div>
                </div>
                <Row className="card-row">
                    {
                        postList && postList["hydra:member"] && postList["hydra:member"].slice().reverse().map((item) => {
                            var div = document.createElement("div");
                            div.innerHTML = item.content;
                            return (
                                <Col
                                    className="card-col"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                    md={4}
                                >
                                    <img src={"https://wattle-admin.herokuapp.com/uploads/images/" + item.image} alt="avatar" width="100%" />
                                    <div className="description_wrapper">
                                        <div className="card-content">
                                            <div className="main_detail">
                                                {item.title}
                                            </div>
                                            <div className="borderd"></div>
                                            <div className="details_desc">
                                                {div.innerText.slice(0, 140) + "..."}
                                            </div>
                                        </div>
                                        {/* <a href="#"> */}
                                        <NavLink to={"/post-detail" + item["@id"]} onClick={scrollToTop}>
                                            <div className="more_reading" onClick={() => handlePostDetail(item)}>
                                                Keep Reading
                                                <span>
                                                    <img src={Arrow} alt="icon" height="15px" />
                                                </span>
                                            </div>
                                        </NavLink>
                                        {/* </a> */}
                                    </div>
                                </Col>

                            )
                        })
                    }
                    {/* <button>View All</button> */}
                </Row>
            </div>
            <Footer />
        </div>
    );
}

export default AllPost;
