import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../Footer'
import GeneralEngBanner from '../general-english/general-banner'
import GeneralEngHelp from '../general-english/general-eng-help'
import GeneralEngDetail from '../general-english/general-englisg-detail'
import GeneralEngOverview from '../general-english/general-overview'
import SideButtons from '../SideButtons'

function generalEng() {

  return (
    <div>
        <SideButtons />
        <GeneralEngBanner />
        <GeneralEngOverview />
        <GeneralEngDetail />
        <GeneralEngHelp />
        <Footer />
    </div>
  )
}

export default generalEng