import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import missionimg from "../../../assets/images/policies-img/Policies-about.jpg";
import { Accordion, Card, Button } from "react-bootstrap";
import droparrowupb from "../../../assets/images/arrow-down.png";
import pdf from "../../../assets/images/policies-img/pdf-icon.png";
import pdf1 from "../../../assets/Policies/Access-and-Equity-Policy.pdf";
import pdf2 from "../../../assets/Policies/Fees Charges and Refund Policy and Procedure.pdf";
import pdf3 from "../../../assets/Policies/Complaints and Appeals Policy.pdf";
import pdf4 from "../../../assets/Policies/Course Progress Monitoring Policy.pdf";
import pdf5 from "../../../assets/Policies/Security and Student Safety Policy and Procedure.pdf";
import pdf6 from "../../../assets/Policies/Student and Support Policy and Procedure.pdf";
import pdf7 from "../../../assets/Policies/Pre- Training Review and LLN Test Policy and Procedure.pdf";
import pdf8 from "../../../assets/Policies/Credit of Prior Study Policy.pdf";
import pdf9 from "../../../assets/Policies/Recognition of Prior Learning Policy.pdf";
import pdf10 from "../../../assets/Policies/Code of Behaviour and Discipline Policy.pdf";
import pdf11 from "../../../assets/Policies/BYOD Policy and Procedure.pdf";
import pdf12 from "../../../assets/Policies/Code of Behaviour and Discipline Policy.pdf";
import pdf30 from "../../../assets/Policies/Deferment Request Form.pdf";

import pdf13 from "../../../assets/Policies/Application Form 2022.pdf";
import pdf14 from "../../../assets/Policies/Complaint and Appeal Form.pdf";
import pdf15 from "../../../assets/Policies/Course Credit Application Form.pdf";
import pdf16 from "../../../assets/Policies/Course Cancellation Withdrawal Form.pdf";
import pdf17 from "../../../assets/Policies/Course Extension Form.pdf";
import pdf18 from "../../../assets/Policies/Student Change of Details Form.pdf";
import pdf19 from "../../../assets/Policies/Student Support Services Request Form.pdf";
import AOS from "aos";
import "aos/dist/aos.css";

class PrivacyOverview extends Component {
    constructor(props) {
        super(props);
        this.state = { addClass: false };
        this.state = { addClass2: false };
        this.state = { addClass3: false };
        this.state = {
            open: true,
        };
        this.state = {
            tOpen: false,
        };
        this.state = {
            setBlue: false,
        };
        this.state = {
            setBlue2: true,
        };
        this.state = {
            setBlue3: false,
        };

        this.state = {
            setOpen: true,
        };
    }
    toggle() {
        this.setState({ addClass: !this.state.addClass });
        this.setState({ setOpen: !this.state.setOpen });
        this.setState({ setBlue2: !this.state.setBlue2 });
    }
    togglesHandle() {
        this.setState({ addClass2: !this.state.addClass2 });
        this.setState({ open: !this.state.open });
        this.setState({ setBlue3: !this.state.setBlue3 });
    }
    togglesHandle2() {
        this.setState({ addClass3: !this.state.addClass3 });
        this.setState({ tOpen: !this.state.tOpen });
        this.setState({ setBlue: !this.state.setBlue });
    }
    componentDidMount() {
        AOS.init({
            once: true,
            // disable: function() {
            //     var maxWidth = 800;
            //     return window.innerWidth < maxWidth;
            // }
        });
    }

    render() {
        let boxClass = ["box1"];
        let boxClass3 = ["box"];
        let boxClass2 = ["box"];
        let blueClass = ["blue"];
        let blueClass2 = ["blue"];
        let blueClass3 = ["blue"];

        if (this.state.addClass) {
            boxClass.push("back");
        } else if (this.state.addClass) {
            boxClass.push("up");
        } else {
            blueClass2.push("blues");
        }

        if (this.state.addClass2) {
            boxClass2.push("back");
            blueClass3.push("blues");
        } else if (this.state.addClass2) {
            boxClass2.push("up");
            blueClass3.push("white");
        }

        if (this.state.addClass3) {
            boxClass3.push("back");
            blueClass.push("blues");
        } else if (this.state.addClass3) {
            boxClass3.push("up");
            blueClass.push("white");
        }

        return (
            <div className="privacy-overview-wrapper">
                <div className="course-section">
                    <div
                        className="course-div"
                        data-aos="fade-left"
                        data-aos-duration="1500"
                    >
                        <Row className="mission-row">
                            <Col
                                lg="6"
                                className="d-para"
                                data-aos="fade-left"
                                data-aos-duration="1500"
                            >
                                <div className="heading-course">Our Policies</div>
                                The relevant policies related to the VET courses have been listed below. For any further  questions, you can contact the Student Support Team or email us at <a href="mailto:info@wattle.edu.au">info@wattle.edu.au</a>.
                            </Col>
                            <Col lg="6" className="d-para next-para">
                                <div
                                    className="img"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                >
                                    <img src={missionimg} alt="image mission" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="back-rect"></div>
                </div>

                <div className="glance-section">
                    <div className="container">

                        <div className="program-structure">
                            <Accordion defaultActiveKey="0">
                                <Card className="program-card">
                                    <Card.Header
                                        className="panel-title"
                                        id="first-header"
                                        as={Button}
                                        className={boxClass.join(" ")}
                                        className={blueClass2.join(" ")}
                                        onClick={this.toggle.bind(this)}
                                        variant="link"
                                        eventKey="0"
                                    >
                                        {this.state.addClass ? "" : ""}
                                        <div className="card-head">Policies</div>
                                        <Accordion.Toggle
                                            as={Button}
                                            className={boxClass.join(" ")}
                                            onClick={this.toggle.bind(this)}
                                            variant="link"
                                            eventKey="0"
                                            aria-expanded="true"
                                        >
                                            {this.state.addClass ? "" : ""}
                                            <img
                                                src={droparrowupb}
                                                className="dropsb3"
                                                alt="arrows"
                                            />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse in={this.state.setOpen}>
                                        <Card.Body>
                                            <div className="para">
                                                <a href={pdf1} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Access and Equity Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf2} target="_blank">
                                                    <img src={pdf} alt="Pdf icon1" />
                                                    Fee Charges and Refund Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf3} target="_blank">
                                                    <img src={pdf} alt="Pdf icon2" />
                                                    Student Complaints and Appeals Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf4} target="_blank">
                                                    <img src={pdf} alt="Pdf icon3" />
                                                    Course Progress Monitoring Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf5} target="_blank">
                                                    <img src={pdf} alt="Pdf icon4" />
                                                    Security and Safety Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf6} target="_blank">
                                                    <img src={pdf} alt="Pdf icon5" />
                                                    Student and Support Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf7} target="_blank">
                                                    <img src={pdf} alt="Pdf icon6" />
                                                    Pre-Training Review and LLN Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf8} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Credit for Prior Study Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf9} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Recognition of Prior Learning Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf10} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Code of Behavior and Discipline Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf11} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    BYOD Policy and Procedure
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href="#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Privacy Policy
                                                </a>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                {/*  <Card>
                                    <Card.Header
                                        as={Button}
                                        className={boxClass2.join(" ")}
                                        className={blueClass3.join(" ")}
                                        onClick={this.togglesHandle.bind(this)}
                                        variant="link"
                                        eventKey="2"
                                    >
                                        {this.state.addClass2 ? "" : ""}
                                        <div className="card-head">Policies Higher Education</div>
                                        <Accordion.Toggle
                                            as={Button}
                                            className={boxClass2.join(" ")}
                                            onClick={this.togglesHandle.bind(this)}
                                            variant="link"
                                            eventKey="2"
                                        >
                                            {this.state.addClass2 ? "" : ""}
                                            <img
                                                src={droparrowupb}
                                                className="dropsb3"
                                                alt="arrows"
                                            />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse in={this.state.open} eventKey="2">
                                        <Card.Body>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Admissions Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon1" />
                                                    Academic Integrity Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon2" />
                                                    Academic Language and Learning Support Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon3" />
                                                    Academic Misconduct Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon4" />
                                                    Anti-discrimination Policy 
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon5" />
                                                    Academic Governance Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon6" />
                                                    Academic Governance Framework
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Assessment Policy 
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Benchmarking Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Cultural Diversity Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Copyright Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Degree Issuance and Replacement Policy 
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Disability Support Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Education Agents Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Language Learning Support Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    English Language Entry Requirements Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Examinations Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    External Grievance Resolution Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Falsification of Documentation Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Information to Students Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Library and Information Resources Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Moderation Policy 
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = "#" target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Orientation Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf9} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Recognition of Prior Learning Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Student Complaints and Appeals Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Student Record-Keeping Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Student Welfare Policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Students at risk and unsatisfactory progress policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Workplace Anti-Bullying policy
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href = {pdf12} target="_blank">
                                                    <img src={pdf} alt="Pdf icon7" />
                                                    Workplace Health and Safety Policy
                                                </a>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card> */}
                                <Card>
                                    <Card.Header
                                        as={Button}
                                        className={boxClass3.join(" ")}
                                        className={blueClass.join(" ")}
                                        onClick={this.togglesHandle2.bind(this)}
                                        variant="link"
                                    >
                                        {this.state.addClass3 ? "" : ""}
                                        <div className="card-head">Forms</div>
                                        <Accordion.Toggle
                                            as={Button}
                                            className={boxClass3.join(" ")}
                                            onClick={this.togglesHandle2.bind(this)}
                                            variant="link"
                                        >
                                            {this.state.addClass3 ? "" : ""}
                                            <img
                                                src={droparrowupb}
                                                className="dropsb3"
                                                alt="arrows"
                                            />
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse in={this.state.tOpen}>
                                        <Card.Body>
                                            <div className="para">
                                                <a href={pdf13} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Application form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf14} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Complaints and Appeals Form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf15} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Course Credit Application Form
                                                </a>
                                            </div>
                                            {/* <div className="para">
                                                <a href={pdf16} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Course Application and Withdrawal Form
                                                </a>
                                            </div> */}
                                            <div className="para">
                                                <a href={pdf30} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                      Deferment Request Form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf17} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Course Extension Form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf16} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Course Withdrawal Form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf18} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Student Change of Details Form
                                                </a>
                                            </div>
                                            <div className="para">
                                                <a href={pdf19} target="_blank">
                                                    <img src={pdf} alt="Pdf icon" />
                                                    Student Support Services Request Form
                                                </a>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyOverview;
