import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import refund from "../../../assets/Policies/Fees Charges and Refund Policy and Procedure.pdf";
import { NavLink } from "react-router-dom";
import handbook from '../../../assets/Policies/Student-Handbook-VET.pdf';
import AOS from "aos";
import "aos/dist/aos.css";

class BechalorOfBussinessOverview extends Component {
  componentDidMount() {
    AOS.init({
      once: true,
      // disable: function() {
      //     var maxWidth = 800;
      //     return window.innerWidth < maxWidth;
      // }
    });
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div className="Bechalor-of-business-overview-wrapper">
        <div className="course-section">
          <div
            className="course-div"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div className="heading-course">Course Overview</div>
            <Row className="main-row">
              <Col className="cols-main" sm="4">
                <div className="code-head">
                  {" "}
                  Bachelor of Business (Health Administrator)
                </div>
                <Row className="second-row">
                  <Col className="code-col-p" xs="5">
                    <div className="para-head">Course Code:</div>
                    <div className="para-head">Duration:</div>
                    <div className="para-head">Next Intake:</div>
                    <div className="para-head">Application Deadline:</div>
                    {/* <div className="para-head">Fees:</div>
                    <div className="para-head">Location:</div> */}
                  </Col>
                  <Col className="code-col-p" xs="7">
                    <div className="para-a"> BSB-HLTH</div>
                    <div className="para-a">36 months full-time </div>
                    <div className="para-a">07 Feb 2022</div>
                    <div className="para-a">15 December 2021</div>
                    {/* <div className="para-a">See below</div> */}
                    {/* <div className="para-a">Melbourne CBD Region</div> */}
                  </Col>
                </Row>
              </Col>
              <Col className="cols-main des-section" sm="8">
                <div className="d-para">
                  The Bachelor of Business (Health Administration)
                  degree offers a concrete foundation in health services
                  administration and provides students with managerial skills
                  and sufficient knowledge to lead and organise health services
                  institutions. Students will have extensive opportunity to
                  explore diverse leadership concepts and theories, their
                  effective implementations and how they affect management
                  functions in health care settings.
                </div>
                <div className="d-para next-para">
                  The curriculum aims to give students fundamental managerial
                  skills to organise and evaluate health services and critically
                  understand health requirements. The course teaches students
                  how to manage people, systems, health processes, and people
                  within health services to meet changing environments by
                  considering public needs and cost effectiveness.
                </div>
                <div className="d-para next-para">
                  The qualification awarded on graduation is recognised in the
                  Australian Qualifications Framework (AQF) as Level 7.
                </div>
              </Col>
            </Row>
          </div>
          <div className="back-rect"></div>
        </div>
        <div className="glance-section-first">
          <div
            className="glance-heading"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            At a Glance
          </div>

          <div className="container">
            <Row className="wallet-section-f">
              <Col
                className="col-sections-e"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <div className="img">
                  <svg
                    fill="white"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 209.28 209.28"
                  >
                    <g>
                      <path
                        d="M104.641,0C46.943,0,0.002,46.94,0.002,104.637c0,57.701,46.941,104.643,104.639,104.643
                                            c57.697,0,104.637-46.943,104.637-104.643C209.278,46.94,162.338,0,104.641,0z M104.641,194.28
                                            c-49.427,0-89.639-40.214-89.639-89.643C15.002,55.211,55.214,15,104.641,15c49.426,0,89.637,40.211,89.637,89.637
                                            C194.278,154.066,154.067,194.28,104.641,194.28z"
                      />
                      <path
                        d="M158.445,102.886h-49.174V49.134c0-4.142-3.357-7.5-7.5-7.5c-4.142,0-7.5,3.358-7.5,7.5v61.252c0,4.142,3.358,7.5,7.5,7.5
                                            h56.674c4.143,0,7.5-3.358,7.5-7.5C165.945,106.244,162.587,102.886,158.445,102.886z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
                <div className="heading">Duration</div>
                <div className="para">36 months (Full-time)</div>
              </Col>

              <Col
                className="col-section-a"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div className="img">
                  <svg
                    fill="white"
                    id="Capa_1"
                    enable-background="new 0 0 511.989 511.989"
                    height="512"
                    viewBox="0 0 511.989 511.989"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m470.994 330c0-24.146-17.205-44.348-40-48.994v-54.006c0-4.92-2.412-9.526-6.456-12.329s-9.206-3.445-13.811-1.716l-28.629 10.736c-18.8-19.545-41.652-34.266-66.694-43.303 24.608-18.234 40.59-47.478 40.59-80.388 0-55.141-44.859-100-100-100s-100 44.859-100 100c0 32.91 15.982 62.154 40.59 80.388-25.043 9.038-47.894 23.759-66.694 43.303l-28.629-10.736c-4.606-1.729-9.768-1.087-13.811 1.716-4.044 2.803-6.456 7.409-6.456 12.329v54.006c-22.795 4.646-40 24.847-40 48.994s17.205 44.348 40 48.994v58.006c0 6.253 3.879 11.85 9.733 14.045l160 60c3.374 1.258 7.159 1.258 10.533 0l160-60c5.854-2.195 9.733-7.792 9.733-14.045v-58.006c22.796-4.646 40.001-24.848 40.001-48.994zm-285-230c0-38.598 31.402-70 70-70s70 31.402 70 70-31.402 70-70 70-70-31.402-70-70zm70 100c35.143 0 68.709 12.701 94.899 35.393l-94.899 35.587-94.899-35.587c26.191-22.692 59.757-35.393 94.899-35.393zm-185 130c0-11.028 8.972-20 20-20h10v40h-10c-11.028 0-20-8.972-20-20zm40 49.497c11.397-2.323 20-12.424 20-24.497v-50c0-12.073-8.603-22.174-20-24.497v-31.858l130 48.75v177.961l-130-48.75zm160 95.858v-177.96l130-48.75v31.858c-11.397 2.323-20 12.424-20 24.497v50c0 12.073 8.603 22.174 20 24.497v47.108zm150-125.355h-10v-40h10c11.028 0 20 8.972 20 20s-8.971 20-20 20z" />
                    </g>
                  </svg>
                </div>
                <div className="heading">Delivery Mode</div>
                <div className="para">Face-Face</div>
              </Col>
              <Col
                className="col-section"
                data-aos="flip-right"
                data-aos-duration="1500"
              >
                <div className="img">
                  <svg
                    fill="white"
                    height="512pt"
                    viewBox="-2 0 512 512.00005"
                    width="512pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m314.652344 294.195312c13.75-17.015624 12.722656-42.089843-3.085938-57.902343-16.910156-16.910157-44.421875-16.914063-61.332031-.007813l-14.496094 14.496094c-5.320312 5.324219-13.984375 5.320312-19.3125-.003906-2.582031-2.582032-4-6.011719-4.003906-9.660156 0-3.644532 1.421875-7.074219 3.996094-9.652344l24.527343-24.527344c5.804688-5.800781 5.804688-15.207031 0-21.007812-5.800781-5.804688-15.207031-5.804688-21.007812 0l-6.773438 6.773437-6.464843-6.464844c-5.800781-5.800781-15.207031-5.800781-21.011719 0-5.800781 5.804688-5.800781 15.207031 0 21.011719l6.640625 6.636719c-6.230469 7.691406-9.617187 17.203125-9.617187 27.238281 0 11.582031 4.515624 22.472656 12.707031 30.664062 8.453125 8.457032 19.5625 12.683594 30.667969 12.683594 11.105468 0 22.210937-4.226562 30.660156-12.675781l14.496094-14.496094c5.324218-5.324219 13.988281-5.320312 19.316406.003907 5.324218 5.328124 5.328125 13.992187.003906 19.3125l-28.445312 28.445312c-5.804688 5.800781-5.804688 15.207031 0 21.007812 2.898437 2.902344 6.699218 4.355469 10.503906 4.351563 3.800781 0 7.605468-1.449219 10.503906-4.351563l10.695312-10.691406 6.464844 6.460938c2.902344 2.902344 6.703125 4.351562 10.503906 4.351562 3.804688 0 7.605469-1.449218 10.507813-4.351562 5.800781-5.800782 5.800781-15.207032 0-21.007813zm0 0" />
                    <path d="m350.742188 94.394531c-8.417969 8.417969-13.054688 19.609375-13.054688 31.511719 0 11.90625 4.636719 23.097656 13.054688 31.515625 8.6875 8.6875 20.101562 13.03125 31.515624 13.03125 11.414063 0 22.824219-4.34375 31.511719-13.03125 17.378907-17.378906 17.378907-45.652344 0-63.03125-17.375-17.375-45.652343-17.371094-63.027343.003906zm42.019531 42.019531c-5.792969 5.792969-15.21875 5.789063-21.011719 0-5.789062-5.792968-5.789062-15.21875 0-21.011718 5.792969-5.789063 15.21875-5.789063 21.011719 0 5.792969 5.792968 5.792969 15.21875 0 21.011718zm0 0" />
                    <path d="m500.917969 20.582031c-.234375-7.855469-6.542969-14.167969-14.394531-14.40625l-202.539063-6.1679685c-4.132813-.1171875-8.109375 1.4648435-11.007813 4.3945315l-250 252.574218c-30.636718 30.953126-30.636718 81.316407 0 112.265626l118.214844 119.429687c14.886719 15.042969 34.695313 23.328125 55.773438 23.328125 21.074218 0 40.882812-8.285156 55.773437-23.328125l249.996094-252.574219c2.863281-2.890625 4.414063-6.828125 4.289063-10.894531zm-269.296875 447.1875c-9.265625 9.363281-21.574219 14.519531-34.65625 14.519531-13.085938 0-25.394532-5.15625-34.660156-14.519531l-118.210938-119.429687c-19.230469-19.425782-19.230469-51.035156 0-70.460938l245.449219-247.976562 182.09375 5.546875 5.5 184.277343zm0 0" />
                  </svg>
                </div>
                <div className="heading">Fees</div>
                <div className="para">See below</div>
              </Col>
              <Col
                className="col-section-b"
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div className="img">
                  <svg
                    fill="white"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                  >
                    <g>
                      <g>
                        <path
                          d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
                                            c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
                                            c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
                                            c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
                                            c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
                                            c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
                                            c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
                                            C428.708,155.993,436.185,214.909,416.451,267.093z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
                                            C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
                                            c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
                <div className="heading">Location</div>
                <div className="para">Melbourne CBD Region</div>
              </Col>
              <Col
                className="col-section-c"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div className="img">
                  <svg
                    fill="white"
                    height="512pt"
                    viewBox="-6 0 512 512"
                    width="512pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m227.65625 357.507812c2.835938 2.832032 6.679688 4.425782 10.691406 4.425782 4.007813 0 7.851563-1.589844 10.6875-4.425782l53.972656-53.972656c5.90625-5.90625 5.90625-15.476562 0-21.382812-5.902343-5.902344-15.476562-5.902344-21.378906 0l-43.28125 43.28125-19.734375-19.734375c-5.90625-5.902344-15.476562-5.902344-21.382812 0-5.902344 5.902343-5.902344 15.476562 0 21.382812zm0 0" />
                    <path d="m250.121094 439.84375c66.175781 0 120.011718-53.839844 120.011718-120.015625s-53.835937-120.011719-120.011718-120.011719c-66.179688 0-120.015625 53.835938-120.015625 120.011719s53.839843 120.015625 120.015625 120.015625zm0-209.792969c49.503906 0 89.777344 40.273438 89.777344 89.777344s-40.273438 89.78125-89.777344 89.78125-89.78125-40.277344-89.78125-89.78125 40.277344-89.777344 89.78125-89.777344zm0 0" />
                    <path d="m451.34375 32.191406h-27.574219v-17.074218c0-8.347657-6.769531-15.117188-15.117187-15.117188s-15.117188 6.769531-15.117188 15.117188v17.074218h-29.707031v-17.074218c0-8.347657-6.769531-15.117188-15.121094-15.117188-8.347656 0-15.117187 6.769531-15.117187 15.117188v17.074218h-166.9375v-17.074218c0-8.347657-6.769532-15.117188-15.121094-15.117188-8.347656 0-15.117188 6.769531-15.117188 15.117188v17.074218h-29.707031v-17.074218c0-8.347657-6.769531-15.117188-15.121093-15.117188-8.347657 0-15.117188 6.769531-15.117188 15.117188v17.074218h-27.578125c-26.957031 0-48.890625 21.933594-48.890625 48.894532v382.023437c0 26.957031 21.933594 48.890625 48.890625 48.890625h402.453125c26.960938 0 48.890625-21.933594 48.890625-48.890625v-382.023437c0-26.960938-21.933594-48.894532-48.890625-48.894532zm18.652344 430.917969c0 10.285156-8.367188 18.65625-18.652344 18.65625h-402.453125c-10.285156 0-18.65625-8.371094-18.65625-18.65625v-305.210937h439.761719zm-439.761719-382.023437c0-10.289063 8.371094-18.65625 18.65625-18.65625h27.578125v17.074218c0 8.347656 6.769531 15.117188 15.121094 15.117188 8.347656 0 15.117187-6.769532 15.117187-15.117188v-17.074218h29.707031v17.074218c0 8.347656 6.769532 15.117188 15.121094 15.117188 8.347656 0 15.117188-6.769532 15.117188-15.117188v-17.074218h166.9375v17.074218c0 8.347656 6.769531 15.117188 15.121094 15.117188 8.347656 0 15.117187-6.769532 15.117187-15.117188v-17.074218h29.707031v17.074218c0 8.347656 6.769532 15.117188 15.117188 15.117188 8.351562 0 15.121094-6.769532 15.121094-15.117188v-17.074218h27.570312c10.285156 0 18.65625 8.367187 18.65625 18.65625v46.574218h-439.765625zm0 0" />
                  </svg>
                </div>
                <div className="heading">Next available Intake</div>
                <div className="para">Feb 2022</div>
              </Col>

            </Row>
          </div>
        </div>

        <div className="glance-section container">
          <div className="main-content">
            <div
              className="glance-heading first-head"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Careers
            </div>
            <div className="para-first">
              The students completing this course can are eligible to work in
              management roles within the private and public health care sector
              including hospitals, research centres and government
              organisations.
            </div>

            <div
              className="glance-heading"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Accreditation
            </div>
            <div className="para-first">
              The course is accredited by the regulatory body TEQSA (Tertiary Education Quality and Standards Agency).
            </div>

            <div
              className="glance-heading"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Course Structure
            </div>
            <div className="para-first">
              To complete the Bachelor of Business (Health Management), each
              student needs to complete 150 credit points. The sample course
              outline can be viewed below.
            </div>
            <div className="responsive-table">
              {" "}
              <table>
                <tr>
                  <th colSpan="7" className="table-heading">
                    Wattle College | Bachelor of Business (Health
                    Administration)
                  </th>
                </tr>
                <tr>
                  <th>Year</th>
                  <th>Sem</th>
                  <th colSpan="4">
                    Subjects: Core Education Studies | Learning Areas |
                    Professional Experience | Electives
                  </th>
                  <th>Sem Total</th>
                </tr>
                <tr>
                  <td rowSpan="2">1</td>
                  <td>S1</td>
                  <td>
                    WITB Introduction to Business{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WAFB Accounting for Business{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WMIE Microeconomics <div className="points">6 points</div>
                  </td>
                  <td>
                    WMAE Macroeconomics <div className="points">6 points</div>
                  </td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>S2</td>
                  <td>
                    {" "}
                    WBI Business Innovation{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WBL Business Law <div className="points">6 points</div>
                  </td>
                  <td>
                    WMF Marketing Fundamentals{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WIHR Introduction to Human Resources{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>24</td>
                </tr>
                <tr>
                  <td rowSpan="2">2</td>
                  <td>S3</td>
                  <td>
                    WHMS Health Management System{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WSFB Statistics for Business{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WHRM Health Resource Management{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WCS Client Services <div className="points">6 points</div>
                  </td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>S4</td>
                  <td>
                    WLEIHC Legal and Ethical Issues in Health Care{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WQS Quality Systems <div className="points">6 points</div>
                  </td>
                  <td>
                    WIMT Information Management and Telehealth{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WPM Project Management{" "}
                    <div className="points">6 points</div>
                  </td>
                  <td>24</td>
                </tr>
                <tr>
                  <td rowSpan="2">3</td>
                  <td>S5</td>
                  <td>
                    WOHS Occupational Health and Safety
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WRIB Research in Business
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WIPBC Intellectual Property and Business Commercialisation
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WDDDM Database Design and Data Managemen
                    <div className="points">6 points</div>
                  </td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>S6</td>
                  <td>
                    WCF Cybersecurity Fundamentals
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WIP Interprofessional Practice
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WHCI Health Care Innovation
                    <div className="points">6 points</div>
                  </td>
                  <td>
                    WCP Capstone Project
                    <div className="points">12 points</div>
                  </td>
                  <td>30</td>
                </tr>
                <tr>
                  <td colSpan="6" className="r-text">
                    Total Points
                  </td>
                  <td>150</td>
                </tr>
              </table>
            </div>
            <div className="note-list">
              <div className="head">Notes:</div>
              <ul>
                <li>
                  WAI - Academic Integrity (0 credit points) must be completed
                  by all enrolled students in the first study period
                </li>
                <li>
                  According to the academic credit system in Australia,
                  three-year bachelor degrees require a completion of 144 credit
                  points. A unit is normally worth six points (with some
                  exceptions), representing approximately 150 hours of student
                  workload that includes contact hours, assessments, personal
                  study, and examinations). All units must be completed for
                  successful completion of the program
                </li>
                <li>
                  WMIE and WMAE must be completed in the same study period
                </li>
                <li>
                  WITB, WAFB, WMF and WIHR must be completed in the first study
                  period
                </li>
                <li>WSB must be completed prior to WPM</li>
                <li>WLEIHC and WPM must be completed prior to WRB</li>
                <li>WSFB and WIMT must be completed prior to WDDDM</li>
                <li>WDDDM must be completed prior to WCF and WHCI</li>
                <li>
                  Full time study is typically three to four units (or 24 credit
                  points) each study period
                </li>
                <li>
                  If you have applied for or received credit for units as
                  recognition of prior learning (RPL), it may alter the units
                  you need to study.
                </li>
              </ul>
            </div>
            <div className="entry-req">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Entry Requirements
              </div>
              <ul className="para-first">
                <li className="b-heading">
                  Domestic Students
                  <ul>
                    <li className="para-line">
                      English Requirements: Not applicable
                    </li>
                    <li className="para-line">
                      Academic Requirements: Successful completion on of Year 12
                      in Australia or equivalent.
                    </li>
                  </ul>
                </li>
                <li className="para-first b-heading">
                  International Students
                  <ul>
                    <li className="para-line">
                      English Requirements: IELTS (Academic) Overall score of
                      6.5 (no band score less than 6.0) or equivalent.
                    </li>
                    <li className="para-line">
                      Successful completion of Year 12 in Australia or
                      equivalent. Student completing a Certificate IV may also
                      satisfy the entry requirements.
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="para-middle">
                For meeting the English language requirements, International Students can view the English Language Requirements Policy here. Student must be at least eighteen years old before commencing the course at Wattle College.
              </div>
            </div>
            <div className="Admission Pathways">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Credit Transfers and RPL
              </div>
              <ul className="para-first">
                <li className="b-heading">
                  Recognition of Prior Learning
                  <div className="para-first">
                    Wattle College provides Recognition of Prior Learning (RPL)
                    where your work experience or prior qualifications of an
                    advanced and related nature have been undertaken. For more
                    information, please refer to{" "}
                    <NavLink
                      to="/rpl-and-credit-transfer"
                      onClick={() => {
                        this.scrollToTop();
                      }}
                    >
                      Credit Transfers and RPL page
                    </NavLink>
                    .
                  </div>
                </li>
                <li className="para-first b-heading">
                  Credit Transfer
                  <div className="para-first">
                    At Wattle College, you can demonstrate the study that you
                    have previously undertaken and gain credit in another
                    course. Please refer to{" "}
                    <span>
                      <NavLink
                        to="/rpl-and-credit-transfer"
                        onClick={() => {
                          this.scrollToTop();
                        }}
                      >
                        Credit Transfers and RPL
                      </NavLink>
                    </span>{" "}
                    page for more information.
                  </div>
                </li>
              </ul>
            </div>
            <div className="Admission Pathways">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Admission Pathways
              </div>

              <div className="para-first">
                Students may be able to meet the course requirements through our various admission pathways. Please visit the <NavLink
                  to="/admissions-he"
                  onClick={() => {
                    this.scrollToTop();
                  }}
                >
                  admissions page
                </NavLink> to see how you can satisfy the admissions criteria. Students can also read the handbook{' '}
                {/* <a href={handbook} target="_blank"> */}
                here
                {/* </a> */}{' '}
                for more information.
              </div>
            </div>
            <div className="Admission Pathways">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Admissions Process
              </div>

              <div className="para-first">
                Students may can view the admissions process on our <NavLink
                  to="/admissions-he"
                  onClick={() => {
                    this.scrollToTop();
                  }}
                >
                  admissions page
                </NavLink> Students can also read the handbook{' '}
                {/* <a href={handbook} target="_blank"> */}
                here
                {/* </a>{' '} */}{' '}
                for more information.
              </div>
            </div>


            <div className="Intake Dates">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Intake Dates
              </div>
              <ul className="para-first">
                <li className="para-line">07 Feb 2022 (Semester 1)</li>
                <li className="para-line">01 Aug 2022 (Semester 2)</li>
                <li className="para-line">06 Feb 2023 (Semester 1)</li>
                <li className="para-line">07 Aug 2023 (Semester 2))</li>
                <li className="para-line">05 Feb 2024 (Semester 1)</li>
                <li className="para-line">05 Aug 2024 (Semester 2)</li>
              </ul>
            </div>
            <div className="Orientation-program">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Orientation Program
              </div>
              <div className="para-first">
                Wattle College organises orientation one week prior to the
                commencement of the course. The information session is packed
                with information around Wattle College and life in Melbourne. It
                is also an opportunity for you to meet other students.
              </div>
              <div className="para-middle">
                You are required to bring with you the following:
              </div>
              <ul>
                <li className="para-line">Passport</li>
                <li className="para-line">
                  Details of Overseas Health Cover (OSHC)
                </li>
                <li className="para-line">Visa Copy</li>
                <li className="para-line">
                  Your contact details including emergency contact details
                </li>
              </ul>
              <div className="para-first">
                For more information on what will be covered in the orientation
                can be viewed in the{" "}
                <NavLink
                  to="/student-support-services"
                  onClick={() => {
                    this.scrollToTop();
                  }}
                >
                  Student Services
                </NavLink>{" "}
                section.
              </div>
            </div>
            <div className="Fees-structure">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Fees Structure
              </div>
              <ul className="para-first">
                <li className="para-line">
                  Domestic Students
                  <div className="responsive-table">
                    <table className="para-first table-l">
                      <tr>
                        <th>Course </th>
                        <th>Subject Fee</th>
                        <th>Annual Tuition Fee</th>
                        <th>Total Tuition Fee</th>
                      </tr>
                      <tr>
                        <td>Bachelor of Business (Health Administration)</td>
                        <td>$700</td>
                        <td>$5,600 </td>
                        <td>$16,800</td>
                      </tr>
                    </table>
                  </div>
                </li>

                <li className="para-line">
                  International Students
                  <div className="responsive-table">
                    <table className="para-first table-l">
                      <tr>
                        <th>Course </th>
                        <th>Subject Fee</th>
                        <th>Annual Tuition Fee</th>
                        <th>Total Tuition Fee</th>
                      </tr>
                      <tr>
                        <td>Bachelor of Business (Health Administration)</td>
                        <td>$1,825</td>
                        <td>$14,600 </td>
                        <td>$43,800</td>
                      </tr>
                    </table>
                  </div>
                </li>
              </ul>
              <div className="para-first">
                <span className="head">Please note:</span> Fees are subject to change without notice for new
                applicants.
                {/* The refund policy can be viewed{" "}
                <a href={refund} target="_blank">
                  {" "}
                  here
                </a> */}
                .
              </div>
            </div>
            <div className="Other-fees">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Other Fees
              </div>
              <div className="responsive-table">
                <table className="table-p">
                  <tr>
                    <th>Type </th>
                    <th>Cost - Domestic Students</th>
                    <th>Cost - International Students</th>
                  </tr>
                  <tr>
                    <td>Application Fee</td>
                    <td>$200 </td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>Overseas Student Health Cover (OSHC)</td>
                    <td>See Bupa Website</td>
                    <td>Not Applicable</td>
                  </tr>
                  <tr>
                    <td>Resources (excluding stationary)</td>
                    <td>$1000</td>
                    <td>$1000</td>
                  </tr>
                  <tr>
                    <td>RPL Assessment</td>
                    <td>$300 per subject</td>
                    <td>$300 per subject</td>
                  </tr>
                  <tr>
                    <td>Credit Transfer</td>
                    <td>None</td>
                    <td>None</td>
                  </tr>
                  <tr>
                    <td>Replacement transcript </td>
                    <td>$50 per copy </td>
                    <td>$50 per copy </td>
                  </tr>
                  <tr>
                    <td>Replacement award/certificate</td>
                    <td>$50 per copy </td>
                    <td>$50 per copy</td>
                  </tr>
                  <tr>
                    <td>Replacement Student ID card</td>
                    <td>$20 per copy </td>
                    <td>$20 per copy </td>
                  </tr>
                  <tr>
                    <td>Airport Pickup (optional)</td>
                    <td>$200</td>
                    <td>Not Applicable</td>
                  </tr>
                  <tr>
                    <td>Accommodation Placement (optional)</td>
                    <td>$300 </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Interim Transcripts</td>
                    <td>$30 per copy </td>
                    <td>$30 per copy </td>
                  </tr>
                  <tr>
                    <td>Supplementary Exams</td>
                    <td>None</td>
                    <td>None</td>
                  </tr>
                  <tr>
                    <td>External Appeals Fee</td>
                    <td>None</td>
                    <td>None</td>
                  </tr>
                  <tr>
                    <td>Credit Charge Surcharge (Visa/MasterCard)</td>
                    <td>3% per transaction</td>
                    <td>3% per transaction</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="Other-fees">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Open Days
              </div>
              <div className="para-first">
                Wattle College holds open days twice a year for the students to come and visit the campus and ask the academic staff information relating to the course. The open day is held from 9 am to 2 pm. The current schedule is below:

              </div>
              <div className="responsive-table">
                <table className="para-first table-l">
                  <tr>
                    <th>Open Day</th>
                    <th>Date</th>
                  </tr>
                  <tr>
                    <td>Term 1</td>
                    <td>05/12/21</td>
                  </tr>
                  <tr>
                    <td>Term 2</td>
                    <td>01/06/22</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="Other-fees">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                Important Dates
              </div>
              <div className="para-first">
                Below are the indicative dates for 2022.
              </div>
              <div className="flex-tables">
                <div className="responsive-table table-width">
                  <table className="para-first table-l table-auto">
                    <tr>
                      <th colSpan="2" className="semester-head">Semester 2, 2022</th>
                    </tr>
                    <tr>
                      <td>Orientation</td>
                      <td>25/07/2022</td>
                    </tr>
                    <tr>
                      <td>Semester 2 Commences</td>
                      <td>01/08/2022</td>
                    </tr>
                    <tr>
                      <td>Census Date</td>
                      <td>14/08/2022</td>
                    </tr>
                    <tr>
                      <td>Exam Week</td>
                      <td>07/11/2022</td>
                    </tr>
                    <tr>
                      <td>Semester 2 end</td>
                      <td>20/11/2022</td>
                    </tr>
                  </table>
                </div>
                <div className="responsive-table">
                  <table className="para-first table-l table-auto">
                    <tr>
                      <th colSpan="2" className="semester-head">Semester 1, 2022</th>
                    </tr>
                    <tr>
                      <td>Orientation</td>
                      <td>31/01/2022</td>
                    </tr>
                    <tr>
                      <td>Semester 1 Commences</td>
                      <td>07/02/2022</td>
                    </tr>
                    <tr>
                      <td>Census Date</td>
                      <td>20/02/2022</td>
                    </tr>
                    <tr>
                      <td>Exam Week</td>
                      <td>16/05/2022</td>
                    </tr>
                    <tr>
                      <td>Semester 1 end</td>
                      <td>03/06/2022</td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>
            <div className="how-to-apply">
              <div
                className="glance-heading"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                How to apply
              </div>
              <div className="para-first">
                Please refer to our how to{" "}
                <NavLink
                  to="/how-to-apply"
                  onClick={() => {
                    this.scrollToTop();
                  }}
                >
                  apply page
                </NavLink>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BechalorOfBussinessOverview;
