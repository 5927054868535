import React, { Component } from "react";
import Footer from "../Footer";
import BechalorOfBussinessBanner from "../Bachelor-of-bussiness-componenet/Bechalor-of-bussiness-banner";
import BechalorOfBussinessOverview from "../Bachelor-of-bussiness-componenet/Bechalor-of-bussiness-overview";
import "aos/dist/aos.css";
import SideButtons from "../SideButtons";

class BechalorOfBussinessPage extends Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    render() {
        return (
            <div className="cert-iv-page-wrapper">
                <SideButtons />
                <BechalorOfBussinessBanner />
                <BechalorOfBussinessOverview />
                <Footer />
            </div>
        );
    }
}

export default BechalorOfBussinessPage;
