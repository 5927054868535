import React, { Component } from 'react';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import CollegeProfileBanner from '../CollegeProfileComponents/college-profile-banner';
import CollegeProfileContent from '../CollegeProfileComponents/college-profile-content';
import CollegeProfileHelp from '../CollegeProfileComponents/college-profile-help';
import SideButtons from '../SideButtons';

class CollegeProfilePage extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="college-profile-wrapper">
                <SideButtons />
                <CollegeProfileBanner />
                <CollegeProfileContent />
                <CollegeProfileHelp />
                <Footer />
            </div>
        );
    }
}

export default CollegeProfilePage;