import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import droparrowupb from "../../../assets/images/arrow-down.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink, Link } from "react-router-dom";
import pdf110 from "../../../assets/Policies/Student Handbook - ELICOS.pdf"

class GeneralEngDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
    this.state = { addClass2: false };
    this.state = { addClass3: false };
    this.state = { addClass4: false };
    this.state = { addClass5: false };
    this.state = { addClass6: false };
    this.state = { addClass7: false };

    this.state = {
      open: true,
    };
    this.state = {
      tOpen: false,
    };
    this.state = {
      Openfour: false,
    };
    this.state = {
      Openfive: false,
    };
    this.state = {
      OpenSix: false,
    };
    this.state = {
      OpenSeven: false,
    };

    this.state = {
      setBlue: false,
    };
    this.state = {
      setBlue2: true,
    };
    this.state = {
      setBlue3: false,
    };
    this.state = {
      setBlue4: false,
    };
    this.state = {
      setBlue5: false,
    };
    this.state = {
      setBlue6: false,
    };
    this.state = {
      setBlue7: false,
    };

    this.state = {
      setOpen: true,
    };
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
    this.setState({ setOpen: !this.state.setOpen });
    this.setState({ setBlue2: !this.state.setBlue2 });
  }
  togglesHandle() {
    this.setState({ addClass2: !this.state.addClass2 });
    this.setState({ open: !this.state.open });
    this.setState({ setBlue3: !this.state.setBlue3 });
  }
  togglesHandle2() {
    this.setState({ addClass3: !this.state.addClass3 });
    this.setState({ tOpen: !this.state.tOpen });
    this.setState({ setBlue: !this.state.setBlue });
  }
  togglesHandle4() {
    this.setState({ addClass4: !this.state.addClass4 });
    this.setState({ Openfour: !this.state.Openfour });
    this.setState({ setBlue4: !this.state.setBlue4 });
  }
  togglesHandle5() {
    this.setState({ addClass5: !this.state.addClass5 });
    this.setState({ Openfive: !this.state.Openfive });
    this.setState({ setBlue5: !this.state.setBlue5 });
  }
  togglesHandle6() {
    this.setState({ addClass6: !this.state.addClass6 });
    this.setState({ OpenSix: !this.state.OpenSix });
    this.setState({ setBlue6: !this.state.setBlue6 });
  }
  togglesHandle7() {
    this.setState({ addClass7: !this.state.addClass7 });
    this.setState({ OpenSeven: !this.state.OpenSeven });
    this.setState({ setBlue7: !this.state.setBlue7 });
  }
  componentDidMount() {
    AOS.init({
      once: true,
      // disable: function() {
      //     var maxWidth = 800;
      //     return window.innerWidth < maxWidth;
      // }
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    let boxClass = ["box1"];
    let boxClass3 = ["box"];
    let boxClass2 = ["box"];
    let boxClass4 = ["box"];
    let boxClass5 = ["box"];
    let boxClass6 = ["box"];
    let boxClass7 = ["box"];

    let blueClass = ["blue"];
    let blueClass2 = ["blue"];
    let blueClass3 = ["blue"];
    let blueClass4 = ["blue"];
    let blueClass5 = ["blue"];
    let blueClass6 = ["blue"];
    let blueClass7 = ["blue"];

    if (this.state.addClass) {
      boxClass.push("back");
    } else if (this.state.addClass) {
      boxClass.push("up");
    } else {
      blueClass2.push("blues");
    }

    if (this.state.addClass2) {
      boxClass2.push("back");
      blueClass3.push("blues");
    } else if (this.state.addClass2) {
      boxClass2.push("up");
      blueClass3.push("white");
    }

    if (this.state.addClass3) {
      boxClass3.push("back");
      blueClass.push("blues");
    } else if (this.state.addClass3) {
      boxClass3.push("up");
      blueClass.push("white");
    }

    if (this.state.addClass4) {
      boxClass4.push("back");
      blueClass4.push("blues");
    } else if (this.state.addClass4) {
      boxClass4.push("up");
      blueClass4.push("white");
    }

    if (this.state.addClass5) {
      boxClass5.push("back");
      blueClass5.push("blues");
    } else if (this.state.addClass5) {
      boxClass5.push("up");
      blueClass5.push("white");
    }

    if (this.state.addClass6) {
      boxClass6.push("back");
      blueClass6.push("blues");
    } else if (this.state.addClass6) {
      boxClass6.push("up");
      blueClass6.push("white");
    }

    if (this.state.addClass7) {
      boxClass7.push("back");
      blueClass7.push("blues");
    } else if (this.state.addClass7) {
      boxClass7.push("up");
      blueClass7.push("white");
    }
    return (
      <div className="general-eng-detail">
        <div className="container">
          <div
            className="heading-detail"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Course Details
          </div>
          <div className="program-structure">
            <Accordion defaultActiveKey="0">
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  id="first-header"
                  as={Button}
                  // className={boxClass.join(" ")}
                  className={blueClass2.join(" ")}
                  onClick={this.toggle.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass ? "" : ""}
                  <div className="card-head">What you will learn</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.setOpen}>
                  <Card.Body>
                    <div className="entry-para-top">
                      Wattle College’s General English program has been designed to help students improve their everyday communicative skills. Students are taught a balance of all language skills integrating weekly themes and targeted language structures. 
                    </div>
                    <div className="entry-para-top">
                      Students will have sufficient skills to progress to more specialised courses such as English for Academic Purposes and IELTS preparation program. Students can join the class on any Monday. Students can get more information by referring to our <a href={pdf110} target="_blank">handbook</a>.
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  as={Button}
                  className={blueClass4.join(" ")}
                  onClick={this.togglesHandle4.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass4 ? "" : ""}
                  <div className="card-head">Intake Dates</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass4.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass4 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.Openfour}>
                  <Card.Body className="entry-body">
                    <ul>
                      <li>Weekly – Every Monday</li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  as={Button}
                  className={blueClass5.join(" ")}
                  onClick={this.togglesHandle5.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass5 ? "" : ""}
                  <div className="card-head">Entry Requirements</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass5.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass5 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.Openfive}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                      Intended students must satisfy the following:
                    </div>
                    <ul>
                      <li>Minimum age of 18 years.</li>
                    </ul>
                    <div className="entry-para-top">
                      In addition, the students will be required to undertake a
                      language assessment test prior to course commencement to
                      ascertain the level their level of English
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="program-card">
                <Card.Header
                  className="panel-title"
                  as={Button}
                  className={blueClass7.join(" ")}
                  onClick={this.togglesHandle7.bind(this)}
                  variant="link"
                  eventKey="0"
                >
                  {this.state.addClass7 ? "" : ""}
                  <div className="card-head">Sample Timetable (Subject to Change)</div>
                  <Accordion.Toggle
                    as={Button}
                    className={boxClass7.join(" ")}
                    onClick={this.toggle.bind(this)}
                    variant="link"
                    eventKey="0"
                    aria-expanded="true"
                  >
                    {this.state.addClass7 ? "" : ""}
                    <img
                      src={droparrowupb}
                      className="dropsb3"
                      alt="fourth drop arrows"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse in={this.state.OpenSeven}>
                  <Card.Body className="entry-body">
                    <div className="entry-para-top">
                        Day Classes:
                    </div>
                    <ul>
                      <li>Monday – Thursday </li>
                    </ul>
                    <div className="entry-para-top">
                       8:30am – 2:30pm (including breaks)
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <div
                className="heading-detail second-heading"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Apply Now
              </div>
              <div className="entry-para-top">
                Please refer to our <Link to="/how-to-apply">how to apply</Link>{" "}
                page
              </div>
            </Accordion>
          </div>
          <div className="entry-requirements"></div>
          <div className="fee-payments"></div>
        </div>
      </div>
    );
  }
}

export default GeneralEngDetail;
