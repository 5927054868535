import React, { Component } from 'react';
import PrivacyBanner from '../Policy&proccedurecomponenet/privacy-banner';
import PrivacyHelp from '../Policy&proccedurecomponenet/privacy-help';
import PrivacyOverview from '../Policy&proccedurecomponenet/privacy-overview';
import Footer from '../Footer';
import 'aos/dist/aos.css';
import SideButtons from '../SideButtons';

class PrivacyPolicy extends Component {
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    render() {
        return (
            <div className="privacy-page-wrapper">
                <SideButtons />
                <PrivacyBanner />
                <PrivacyOverview />
                <PrivacyHelp />
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicy;